<script setup lang="ts">
import { date } from "quasar";
import { computed, inject, nextTick, ref, watch, watchEffect } from "vue";
import { useInputDisable } from "../../../../composables/disable";
import { GroupValidationItem, useInputRules } from "../../../../composables/horizontalLabel";
import { formatDate } from "../../../../utils/date";
import { getMaxDate, getMinDate } from "../../../../utils/helpers";
import MInput from "../MInput";
import MSimpleSelect from "../MSimpleSelect";
import MInputGroup from "./../../MInputGroup";
import MValidationComponent from "./../MValidationComponent";
// import css
import "../MDate";

const props = withDefaults(
  defineProps<{
    modelValue?: string;
    name?: string;

    type?: "year" | "month" | "date";

    label?: string;
    helper?: string;
    instructions?: string;

    rules?: Array<any>;
    required?: boolean;
    requiredText?: string;
    showAllErrors?: boolean;
    min?: string | number;
    max?: string | number;

    maxDate?: string | Date;
    maxDateText?: string;

    minDate?: string | Date;
    minDateText?: string;
    dropdownMonth?: boolean;

    partial?: boolean;
  }>(),
  {
    type: "date",
  },
);

const monthOptions = [
  { label: "January", value: "01" },
  { label: "February", value: "02" },
  { label: "March", value: "03" },
  { label: "April", value: "04" },
  { label: "May", value: "05" },
  { label: "June", value: "06" },
  { label: "July", value: "07" },
  { label: "August", value: "08" },
  { label: "September", value: "09" },
  { label: "October", value: "10" },
  { label: "November", value: "11" },
  { label: "December", value: "12" },
];

const {
  value,
  errors,
  displayErrors: _displayErrors,
  touched,
} = useInputRules<string>(props, onFocus, [
  // (v: string) => {
  //   if (isValid.value.day && isValid.value.month && isValid.value.year) {
  //     return true;
  //   }
  //   return 'Please enter a valid date in the format "01/01/2022"';
  // },

  (v: string) => {
    return props.required && !v ? props.requiredText || `Enter a date for '${props.label}'` : true;
  },

  // min-date
  (v: string) => {
    if (!props.minDate || !v) return true;
    const [year, month, day] = v.split("-");
    const minDate = getMinDate(props.minDate);

    const d = new Date(+year, month ? +month - 1 : 0, day ? +day : 1);
    return d >= minDate || props.minDateText! || `Must be on or after ${formatDate(minDate)}`;
  },

  // max-date
  (v: string) => {
    if (!props.maxDate || !v) return true;
    const [year, month, day] = v.split("-");
    const maxDate = getMaxDate(props.maxDate);

    const d = new Date(+year, month ? +month - 1 : 0, day ? +day : 1);

    return d <= maxDate || props.maxDateText! || `Must be on or before ${formatDate(maxDate)}`;
  },
]);

// // used to store the temp value, it will be assigned to value.value onBlur
// const tempValue = ref(value.value);
// watch(value, (v) => (value.value = v));

const parentValidationGroup = inject<GroupValidationItem[] | undefined>(
  "groupValidationController",
  undefined,
);

const displayErrors = computed(() => {
  if (!touched.value) return [];
  if (props.partial) {
    if (year.value) {
      if (!month.value && day.value) {
        return ["Month is required for full dates"];
      }
    } else {
      if (!month.value) {
        if (day.value) {
          // month + year missing
          return ["Month and year are required for full dates"];
        }
      } else if (day.value) {
        // year missing
        return ["Year is required for full dates"];
      } else {
        // year + day missing
        return ["Year is required for partial dates"];
      }
    }
  }
  if (!props.required) {
    if (!partialErrors.value.day && partialErrors.value.month && partialErrors.value.year) {
      return ["Year and month are required"];
    } else if (!month.value && partialErrors.value.month) {
      return ["Month is required"];
    } else if (!year.value && partialErrors.value.year) {
      return ["Year is required"];
    }
  }

  return _displayErrors.value;
});

if (parentValidationGroup) {
  parentValidationGroup.push({
    name: props.name,
    errors: displayErrors,
    scrollIntoView() {
      // console.log("scroll");
    },
  });
}

const partialErrors = computed(() => {
  if (!touched.value) return {};

  if (props.partial) {
    if (year.value) {
      if (!month.value && day.value) {
        // month missing
        return {
          month: true,
        };
      }
    } else {
      if (!month.value) {
        if (day.value) {
          // month + year missing
          return {
            month: true,
            year: true,
          };
        }
      } else if (day.value) {
        // year missing
        return {
          year: true,
        };
      } else {
        // year + day missing
        return {
          year: true,
        };
      }
    }

    // fallback for partial dates
    // if it reaches here is because the partial date is correct
    return {
      day: false,
      month: false,
      year: false,
    };
  }
  return {
    year: !isValid.value.year,
    month: !isValid.value.month,
    day: !isValid.value.day,
  };
});

function onFocus() {
  [dayEl.value, monthEl.value, yearEl.value].find(Boolean)?.focus();
}

const day = ref<string>();
const month = ref<string>();
const year = ref<string>();

watchEffect(() => {
  // expected format '2022-01-01'
  const [y, m, d] = ((value.value as string) || "").split("-");

  day.value = d;
  month.value = m;
  year.value = y;
});

const visibility = computed(() => {
  const month = props.type !== "year";
  const day = props.type !== "month" && month;
  return {
    day,
    month,
    year: true,
  };
});

const dayEl = ref<typeof MInput>();
const monthEl = ref<typeof MSimpleSelect | typeof MInput>();
const yearEl = ref<typeof MInput>();

// when focusing on keyup it moves to the next element too fast, this prevents that double
// focusing elements
let focusingOnElement = false;
function onKeyup(e: KeyboardEvent) {
  e.preventDefault();
  e.stopPropagation();
  if (focusingOnElement) return (focusingOnElement = false);

  // console.log("onkeyup", e, extra);
  if (Number.isNaN(+e.key)) {
    return;
  }

  // // @ts-expect-error
  // const v = `${+div.value}`;
  // if (v.length > 1) {
  //   focusingOnElement = true;
  //   nextTick().then(() => {
  //     nextEl?.focus();
  //   });
  // }
}
function onKeydown(e: KeyboardEvent) {
  if (Number.isNaN(+e.key) && e.code.startsWith("Key")) {
    e.preventDefault();
    e.stopPropagation();
    return;
  }

  // @ts-expect-error
  const v = `${+e.target.value}`;
  if (v.length > 1 && !Number.isNaN(+e.key)) {
    // @ts-expect-error not sure
    e.target.value = "";
  }
}

const isValid = ref({
  day: true,
  month: true,
  year: true,
});

function isValidYear(y: number) {
  // TODO double check this year
  // return y > 1000 && y <= new Date().getFullYear();
  return y > 1000 && y <= 9999;
}

function isValidMonth(m: number) {
  return m > 0 && m < 13;
}

watch([day, month, year, () => props.type], ([d, m, y, t], [pd, pm, py]) => {
  switch (t) {
    default:
    case "date": {
      if (!d) {
        if (pd) {
          isValid.value.day = false;
        }
        if (props.partial) {
          isValid.value.day = true;
        }
      } else {
        if (day.value) {
          // Prefix single digit with 0
          if (d.length === 1 && day.value !== "0") {
            day.value = `0${day.value}`;
          }

          // Remove extra leading 0s
          if (d.length > 2) {
            day.value = day.value.replace(/^0+/, "");
          }
        }

        if (+d > 31) {
          isValid.value.day = false;
        } else if (m && y) {
          isValid.value.day = new Date(+y!, +m! - 1, +d!).getDate() == +d;
        }
      }
    }
    // eslint-disable-next-line no-fallthrough
    case "month": {
      if (!m) {
        if (pm) {
          isValid.value.month = false;
        }
        if (props.partial) {
          isValid.value.month = true;
        }
      } else {
        isValid.value.month = isValidMonth(+m);
      }
    }
    // eslint-disable-next-line no-fallthrough
    case "year": {
      if (!y) {
        if (!py) {
          if (!d && !m && !y && !props.required) {
            return (isValid.value.day = isValid.value.month = isValid.value.year = true);
          } else {
            if (!props.partial) {
              isValid.value.day = !!d;
              isValid.value.month = !!m;
            }
            isValid.value.year = false;
          }
          return;
        }
        isValid.value.year = false;
      } else {
        isValid.value.year = isValidYear(+y);
      }
    }
  }

  const v = {
    date: isValid.value.day && isValid.value.month && isValid.value.year,
    month: isValid.value.month && isValid.value.year,
    year: isValid.value.year,
  };

  if (v[props.type]) {
    // console.log("format", isValid.value.day, isValid.value.month, isValid.value.year);
    const formats = {
      year: "YYYY",
      month: "YYYY-MM",
      date: "YYYY-MM-DD",
    };
    if (t === "year") {
      value.value = y!;
    } else if (props.partial) {
      value.value = `${y}${m || d ? "-" + (m || "") : ""}${d ? "-" + d : ""}`;
    } else {
      const formatted = date.formatDate(new Date(+y!, +m! - 1, +d! || 1), formats[t || "date"]);

      value.value = formatted;
    }
  } else {
    const v = [y || "", m || "", d].join("-");

    if (v === "--" && !props.required) {
      isValid.value.day = isValid.value.month = isValid.value.year = true;
      value.value = "";
    } else if (props.partial) {
      value.value = `${y}${m || d ? "-" + (m || "") : ""}${d ? "-" + d : ""}`;
    }
  }
});

watch(errors, (e) => {
  if (!e?.length) return;
  if (e.length > 0) {
    isValid.value.day = props.type && props.type !== "date" ? true : false;

    isValid.value.month = props.type === "year" || props.type === "month" ? true : false;
    isValid.value.year = false;
  }
});

function onBlur(e: FocusEvent) {
  // @ts-expect-error
  if ((e.currentTarget as HTMLElement)?.contains(e.relatedTarget!)) return;
  // @ts-expect-error
  if ((e.relatedTarget as HTMLElement)?.attributes?.role?.value === "option") return;

  touched.value = true;

  // eslint-disable-next-line no-self-assign
  value.value = value.value;
}

const isDisable = useInputDisable();

function formatMonth(m?: string) {
  if (!m) return m;
  const month = +m - 1;
  if (month >= 0 && month < 12) {
    return date.formatDate(new Date(1, month, 1), "MMM");
  }
  return m;
}
const monthText = ref(formatMonth(month.value));

const isFocusedMonth = ref(false);

function onMonthBlur() {
  month.value = monthText.value;

  nextTick().then(() => {
    isFocusedMonth.value = false;

    monthText.value = formatMonth(month.value);
  });
}
function onMonthFocus() {
  monthText.value = month.value;

  nextTick().then(() => {
    isFocusedMonth.value = true;
  });
}

watch(month, (m) => {
  monthText.value = formatMonth(m);
});
</script>

<template>
  <m-validation-component
    :errors="parentValidationGroup ? undefined : displayErrors"
    :class="$attrs.class"
  >
    <m-input-group
      class="date-input"
      :label="label"
      :required="required"
      :helper="helper"
      :instructions="instructions"
      horizontal
      left
      @blur.capture="onBlur"
    >
      <template v-if="$slots.helper" #helper> <slot name="helper" /> </template>

      <m-input
        v-if="visibility.day"
        ref="dayEl"
        v-model="day"
        data-testid="date-input-day"
        :class="[
          'date-input--day',
          touched && (!isValid.day || partialErrors.day) && 'input-error',
        ]"
        label="Day"
        :max="31"
        :min="1"
        max-text="Day must be less or equal to 31"
        min-text="Day must be greater than 0"
        placeholder="dd"
        pattern="[0-9]{2}"
        :disabled="isDisable"
        :disable="isDisable"
        @keyup="onKeyup"
        @keydown="onKeydown"
      />
      <template v-if="visibility.month">
        <m-simple-select
          v-if="dropdownMonth"
          ref="monthEl"
          v-model="month"
          data-testid="date-input-month"
          label="Month"
          :options="monthOptions"
          :class="[touched && (!isValid.month || partialErrors.month) && 'input-error']"
          placeholder="Month"
          :disable="isDisable"
          @keyup="onKeyup"
        />
        <m-input
          v-else
          ref="monthEl"
          v-model="monthText"
          data-testid="date-input-month"
          label="Month"
          :max="12"
          :min="1"
          max-text="Month must be less or equal to 12"
          min-text="Month must be greater than 0"
          :class="[
            'date-input--month',
            touched && (!isValid.month || partialErrors.month) && 'input-error',
          ]"
          placeholder="mm"
          :pattern="isFocusedMonth ? '[0-9]{2}' : undefined"
          :disabled="isDisable"
          :disable="$attrs.disable"
          @keyup="onKeyup"
          @keydown="onKeydown"
          @blur="onMonthBlur"
          @focus="onMonthFocus"
        />
      </template>

      <m-input
        v-if="visibility.year"
        ref="yearEl"
        v-model="year"
        data-testid="date-input-year"
        :class="[
          'date-input--year',
          touched && (!isValid.year || partialErrors.year) && 'input-error',
        ]"
        label="Year"
        :min="1000"
        :max="3000"
        max-text="Year must be less than 3000"
        min-text="Year must be greater than 999"
        placeholder="yyyy"
        pattern="[0-9]{4}"
        mask="####"
        :disabled="isDisable"
        :disable="isDisable"
      />
    </m-input-group>
  </m-validation-component>
</template>
<style lang="scss">
.date-input {
  .date-input--day {
    width: 50px;
  }
  .date-input--month {
    width: 64px;
  }

  .date-input--year {
    width: 64px;
  }

  .input-error {
    .m-input--input-box:after {
      border-color: var(--status-red);
    }
  }
}
</style>
