<template>
  <div
    :class="[
      {
        clickable: !readOnly,
        'marked-incorrect': item.isMarkedIncorrect,
      },
    ]"
  >
    <m-layout-stack horizontal>
      <div class="flex-auto">
        <span v-if="item.documentDateIsNotEncounterDate && item.documentDate"
          >Document ({{ item.documentDate ?? "" }}): {{ item.documentTypeLabel }}</span
        >
        <span v-else>Document: {{ item.documentTypeLabel }} </span>
        <span v-if="getDocumentSubText"> • {{ getDocumentSubText }}</span>
        <span v-if="getConfidentialityText"> • {{ getConfidentialityText }} </span>
        <span v-if="item.additionalInformation"> • {{ item.additionalInformation }} </span>
      </div>
      <m-badge v-if="item.hasPlaceholderFile" text="GP2GP Placeholder File" gray />
      <i v-if="item.isRetrospectivelyAmended" style="white-space: nowrap">{{
        formatRetrospectivelyAmendedLabel
      }}</i>
    </m-layout-stack>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

export type DocumentJournalItem = {
  entryType: string;
  id: string;
  documentTypeLabel?: string;
  recordedBy: string;
  recordedByOrganisation: string;
  isMarkedIncorrect: boolean;
  hiddenFromPatientFacingServices: boolean;
  confidentialFromThirdParties: boolean;
  isRetrospectivelyAmended: boolean;
  hasPlaceholderFile: boolean;
  isLocalOrganisation: boolean;
  organisationName?: string;
  title?: string;
  clinicalSpeciality?: string;
  documentDate?: string;
  documentDateIsNotEncounterDate?: boolean;
  additionalInformation?: string;
  linkedProblems: { problemCodeDescription: string }[];
  sortOrder?: unknown[];
  createdDateTime?: string;
};

const props = defineProps<{
  item: DocumentJournalItem;
  readOnly: boolean;
  contextHiddenFromPatientFacingServices?: boolean;
  contextConfidentialFromThirdPartyServices?: boolean;
}>();

const formatRetrospectivelyAmendedLabel = computed(() => {
  let label = [];
  if (props.item.isRetrospectivelyAmended) {
    label.push("Edited");
  }

  return label.join(" • ");
});

const getConfidentialityText = computed(() => {
  const textArray = [];

  if (props.item.hiddenFromPatientFacingServices && !props.contextHiddenFromPatientFacingServices) {
    textArray.push("Redacted from Patient Online Access");
  }
  if (props.item.confidentialFromThirdParties && !props.contextConfidentialFromThirdPartyServices) {
    textArray.push("Confidential from Third Parties");
  }
  return textArray.length ? textArray.join(" • ") : "";
});

const getDocumentSubText = computed(() => {
  const textAsArray = [
    props.item.title,
    props.item.clinicalSpeciality,
    props.item.organisationName,
  ];
  const text = textAsArray.filter((x) => x).join(" • ");
  return text === "" ? false : text;
});
</script>
