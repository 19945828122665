<script lang="ts" setup>
import {
  Component,
  computed,
  getCurrentInstance,
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  provide,
  ref,
  watch,
} from "vue";
import { useDebounce, useEvent } from "vue-composable";
import { dispatch } from "../../../utils/bus";

const props = defineProps<{
  modelValue?: boolean;

  noAutoOpen?: boolean;

  target?: Record<string, any> | HTMLElement | string | Component | null;

  renderTarget?: string | HTMLElement | Component | null;

  offset?: [left: number, top: number];

  maxHeight?: number;

  id?: string;
}>();

const emit = defineEmits({
  "update:modelValue": (_value: boolean) => true,
  blur: (_e: FocusEvent) => true,
  focus: (_e: FocusEvent) => true,
});

provide("closeMenu", () => {
  show.value = false;
});

const menu = ref<HTMLElement | null>(null);
const content = ref<HTMLElement | null>(null);

const intervalId = ref<number | null>(null);

const updatePosition = useDebounce(function () {
  if (!content.value) return;
  try {
    const rect = target.value?.getBoundingClientRect();
    let top = 0;
    let left = 0;
    let width = 0;
    let height: string | number = 0;

    if (rect) {
      top = rect.top + rect.height + (props.offset?.[1] ?? 0);
      left = rect.left + (props.offset?.[0] ?? 0);
      width = rect.width;
      height = rect.height;
    }

    height = window.innerHeight - top - 30;

    if (left + width > window.innerWidth) {
      width = window.innerWidth - left;
    }

    if (height < 100) {
      const contentHeight = props.maxHeight ?? content.value.scrollHeight;
      height = contentHeight > rect.y ? rect.y - 10 : contentHeight;
      top = top - height - rect.height - 5;
    }

    position.value = {
      top: `${top}px`,
      left: `${left}px`,
      width: `${width}px`,
      height: height ? `${height}px` : "auto",
    };
  } catch {
    // ignore
  }
}, 1);

const show = ref(props.modelValue ?? false);
watch(show, (s) => {
  emit("update:modelValue", s);
});
watch(
  () => props.modelValue,
  (v) => {
    show.value = v;

    if (v) {
      intervalId.value = window.setInterval(updatePosition, 200);
    } else {
      if (intervalId.value) {
        window.clearInterval(intervalId.value);
      }
    }
  },
);

const { proxy, parent } = getCurrentInstance()!;

const __count = ref(0);

const target = computed(() => {
  // just having something to trigger the computed
  __count.value;
  const target = props.target
    ? typeof props.target === "string"
      ? (document.querySelector(props.target) as HTMLElement)
      : (((props.target as any).$el || props.target) as HTMLElement)
    : (parent?.proxy?.$el as HTMLElement);

  if (target === proxy.$el) {
    return parent.proxy.$parent.$el;
  }
  return target;
});

const position = ref({
  top: `0px`,
  left: `0px`,
  width: `0px`,
  height: `0px`,
});

onBeforeUnmount(() => {
  if (intervalId.value) {
    window.clearInterval(intervalId.value);
  }
});

useEvent(target, "focusin", () => {
  if (props.noAutoOpen) return;
  show.value = true;
});

watch(
  menu,
  (v) => {
    if (v) {
      updatePosition();
    }
  },
  { flush: "sync" },
);

const positionTop = computed(() => position.value.top);
const positionLeft = computed(() => position.value.left);
const positionWidth = computed(() => position.value.width);
const positionHeight = computed(() =>
  props.maxHeight ? `${props.maxHeight}px` : position.value.height,
);

function handleMenuFocus() {
  if (
    document.activeElement === target.value ||
    document.activeElement === proxy?.$el ||
    (proxy?.$el as HTMLElement).contains(document.activeElement)
  ) {
    return;
  }
  dispatch("close-menus", { target: props.id });
  return (show.value = false);
}

onMounted(() => {
  // trigger target
  __count.value++;
  window.addEventListener("focus", handleMenuFocus, true);
});

onUnmounted(() => {
  window.removeEventListener("focus", handleMenuFocus, true);
});

defineExpose({
  hide() {
    show.value = false;
  },
  show() {
    show.value = true;
  },
});
</script>
<template>
  <teleport v-if="renderTarget" :to="renderTarget">
    <div
      v-if="show"
      ref="menu"
      class="m-menu"
      tabindex="-1"
      @focus="$emit('focus', $event)"
      @blur="$emit('blur', $event)"
    >
      <div ref="content" class="m-menu--content">
        <slot />
      </div>
    </div>
  </teleport>

  <div
    v-else-if="show"
    ref="menu"
    class="m-menu"
    tabindex="-1"
    @focus="$emit('focus', $event)"
    @blur="$emit('blur', $event)"
  >
    <div ref="content" class="m-menu--content">
      <slot />
    </div>
  </div>
</template>

<style lang="scss">
.m-menu {
  // position: absolute;
  position: fixed;

  z-index: 9002;

  top: v-bind(positionTop);
  left: v-bind(positionLeft);
  width: v-bind(positionWidth);
  max-height: v-bind(positionHeight);

  will-change: auto;
  transition: display 300ms ease-in-out;
  box-shadow:
    0px 4px 6px rgba(0, 0, 0, 0.06),
    0px 2px 4px rgba(0, 0, 0, 0.06);

  background-color: white;
  // background-color: red;
  border-radius: 4px;
  overflow: hidden;

  display: flex;

  .m-menu--content {
    flex: 1 1 auto;
    overflow: auto;
  }
}
</style>
