<template>
  <m-card
    :class="{ 'cursor-pointer': readOnly }"
    sr-label="Preview fit note"
    @click="previewFitNoteClick"
  >
    <div
      :class="[
        {
          clickable: !readOnly,
          'marked-incorrect': entry.data.isMarkedIncorrect,
        },
      ]"
    >
      <m-layout-stack horizontal>
        <div class="flex-auto">
          <span><b>Fit Note:</b> {{ props.entry.data.decision }}</span>
          <span v-if="getCaseAndCondition()"> • {{ getCaseAndCondition() }}</span>
        </div>
        <i v-if="entry.data.isRetrospectivelyAmended" style="white-space: nowrap">{{
          formatRetrospectivelyAmendedLabel(entry.data)
        }}</i>
      </m-layout-stack>
      <div
        v-if="
          entry.data.hiddenFromPatientFacingServices ||
          entry.data.confidentialFromThirdParties ||
          entry.data.linkedProblems.length
        "
      >
        <span class="secondary-text">
          {{ getConfidentialityTextAndLinkedProblems() }}
        </span>
      </div>
    </div>
  </m-card>
</template>

<script setup lang="ts">
import { PatientJournalRecord } from "../types";
import { openModal } from "../../../../composables/dialog/drawer";

const props = defineProps<{
  entry: PatientJournalRecord;
  readOnly: boolean;
  clickable?: boolean;
}>();

function previewFitNoteClick() {
  return props.readOnly
    ? openModal(`/clinical/gb/fit-note/modals/preview-fit-note/${props.entry.id}`)
    : undefined;
}

function getConfidentialityTextAndLinkedProblems() {
  const textArray = [];

  if (props.entry.data.hiddenFromPatientFacingServices) {
    textArray.push("Redacted from Patient Online Access");
  }
  if (props.entry.data.confidentialFromThirdParties) {
    textArray.push("Confidential from Third Parties");
  }

  const text = textArray.join(" • ");

  const problemsArray = props.entry.data.linkedProblems || [];
  const problemDescriptions = problemsArray
    .filter((x) => x.problemCodeDescription)
    .map((x) => x.problemCodeDescription);

  const problemText = problemDescriptions.length
    ? `Linked Problems: ${problemDescriptions.join(", ")}`
    : "";

  const finalTextArray = [text, problemText].filter(Boolean);

  return finalTextArray.length ? finalTextArray.join(" • ") : false;
}

function getCaseAndCondition() {
  const textAsArray = [props.entry.data.validPeriod, props.entry.data.diagnosis];

  const text = textAsArray.filter((x) => x).join(" • ");

  return text === "" ? false : text;
}
</script>
