<template>
  <m-card>
    <m-layout-stack gap="1">
      <m-layout-stack
        flex
        horizontal
        :class="[
          {
            'marked-incorrect': entry.isMarkedIncorrect,
          },
        ]"
        data-testid="encounter-details-section"
      >
        <div class="flex-auto">
          <m-layout-stack horizontal>
            <div class="flex-auto">
              <b>{{ getDeliveryMode }}</b>
              <span v-if="entry.data.consultationTopics?.length === 1">
                {{ getFirstTopicTitle ? " • " + getFirstTopicTitle : "" }}</span
              >
              {{ getTimes }}
              {{ getPractitioner === false ? "• Unknown Practitioner" : getPractitioner }}
            </div>
            <i v-if="entry.data.isRetrospectivelyAmended" style="white-space: nowrap">{{
              formatRetrospectivelyAmendedLabel(entry.data)
            }}</i>
            <m-badge v-if="entry.isDraft" text="Draft" amber />
            <m-action
              v-if="!isPreview"
              :model-value="{ label: 'View more', click: openEncounter }"
            />
          </m-layout-stack>
          <div
            v-if="
              getAlsoPresent ||
              getSeenInEstablishment ||
              getConfidentialityText ||
              getLinkedProblemsText
            "
          >
            <span class="secondary-text">
              {{ getSecondaryText }}
            </span>
          </div>
        </div>
      </m-layout-stack>
    </m-layout-stack>

    <template v-if="encounterHasEntries()">
      <m-separator class="q-ma-xs" />
      <template v-for="(topic, index) in entry.data.consultationTopics" :key="topic.id">
        <m-separator v-if="index !== 0" class="q-ma-sm" />
        <ConsultationTopicJournalElement
          :topic="topic"
          hide-empty-headings
          :read-only="topic.isDraft !== undefined ? topic.isDraft : readOnly"
          :encounter-hidden-from-patient-facing-services="
            entry.data.hiddenFromPatientFacingServices
          "
          :encounter-confidential-from-third-parties="entry.data.confidentialFromThirdParties"
          :consultation-topic-count="entry.data.consultationTopics.length"
        />
      </template>
    </template>
  </m-card>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { openSlideover } from "../../../../composables/dialog/drawer";
import { PatientJournalRecord } from "../types";
import ConsultationTopicJournalElement from "../elements/consultation-topic/ConsultationTopicJournalElement.vue";

defineEmits(["setDataUrl"]);

const props = defineProps<{
  entry: PatientJournalRecord;
  readOnly?: boolean;
  isPreview?: boolean;
}>();

const getDeliveryMode = computed(() => {
  const data = props.entry.data;
  let text = data.deliveryMode ? data.deliveryMode + " consultation" : "Consultation";
  return text === "" ? "Consultation" : text;
});

const getFirstTopicTitle = computed(() => {
  const topic = props.entry.data.consultationTopics[0];
  return topic?.topicCode?.topicCode?.label ?? topic?.title ?? "";
});

const getTimes = computed(() => {
  const data = props.entry.data;
  let text = "";

  if (data.startTime) {
    text += " • " + data.startTime;
  }
  if (data.endTime) {
    text += " - " + data.endTime;
  }
  return text;
});

const getPractitioner = computed(() => {
  const data = props.entry.data;
  let text = "";
  if (data.responsiblePractitioner) {
    text += " • " + data.responsiblePractitioner;
  }
  if (data.responsibleOrganisation) {
    if (data.responsiblePractitioner) {
      text += " at";
    }
    text += " " + data.responsibleOrganisation;
  }
  return text === "" ? false : text;
});

const getAlsoPresent = computed(() => {
  const data = props.entry.data;

  let textArray: string[] = [];
  if (data.additionalStaff) {
    textArray.push(data.additionalStaff);
  }
  if (data.otherParticipants) {
    textArray.push(data.otherParticipants);
  }
  let combined = textArray.join(", ");
  if (combined) {
    return "Also present: " + combined;
  } else return "";
});

const getSeenInEstablishment = computed(() => {
  const data = props.entry.data;
  let text = "";
  if (data.seenInEstablishment) {
    text += data.seenInEstablishment;
  }
  return text;
});

const getSecondaryText = computed(() => {
  const data = props.entry.data;
  let textArray = [];
  if (getSeenInEstablishment.value) {
    textArray.push(data.seenInEstablishment);
  }
  if (getAlsoPresent.value) {
    textArray.push(getAlsoPresent.value);
  }
  if (getConfidentialityText.value) {
    textArray.push(getConfidentialityText.value);
  }
  if (data.consultationTopics?.length === 1 && getLinkedProblemsText.value) {
    textArray.push(getLinkedProblemsText.value);
  }
  return textArray.join(" • ");
});

const getConfidentialityText = computed(() => {
  const textArray = [];
  const data = props.entry.data;

  if (data.consultationTopics.length < 2) {
    if (data.hiddenFromPatientFacingServices || encounterHasRedactedTopic()) {
      textArray.push("Redacted from Patient Online Access");
    }
    if (data.confidentialFromThirdParties || encounterHasConfidentialTopic()) {
      textArray.push("Confidential from Third Parties");
    }
  }
  return textArray.join(" • ");
});

const getLinkedProblemsText = computed(() => {
  const topic = props.entry.data.consultationTopics[0];
  const problemsArray = topic?.linkedProblems || [];
  const problemDescriptions = problemsArray
    .filter((x) => x.problemCodeDescription)
    .map((x) => x.problemCodeDescription);

  return problemDescriptions.length ? `Linked Problems: ${problemDescriptions.join(", ")}` : "";
});

function formatRetrospectivelyAmendedLabel(item) {
  let label: string[] = [];
  if (item.isRetrospectivelyAmended) {
    label.push("Edited");
  }

  return label.join(" • ");
}

function encounterHasEntries() {
  let encounterHasEntries = false;
  props.entry.data.consultationTopics.forEach((consultationTopic) => {
    consultationTopic.headings.forEach((heading) => {
      if (heading.entries.length) {
        encounterHasEntries = true;
      }
    });
  });
  return encounterHasEntries;
}

function encounterHasConfidentialTopic() {
  let encounterHasConfidentialTopic = false;
  props.entry.data.consultationTopics.forEach((consultationTopic) => {
    if (consultationTopic.confidentialFromThirdParties) {
      encounterHasConfidentialTopic = true;
    }
  });
  return encounterHasConfidentialTopic;
}

function encounterHasRedactedTopic() {
  let encounterHasRedactedTopic = false;
  props.entry.data.consultationTopics.forEach((consultationTopic) => {
    if (consultationTopic.hiddenFromPatientFacingServices) {
      encounterHasRedactedTopic = true;
    }
  });
  return encounterHasRedactedTopic;
}

function openEncounter() {
  openSlideover(`/clinical/encounter/modal/overview/${props.entry.id}`, { close: true });
}
</script>

<style>
.card-description {
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #68778d;
}

.status-badge {
  align-self: start;
}
</style>
