<script lang="ts" setup></script>

<template>
  <ul class="m-list">
    <slot />
  </ul>
</template>

<style lang="scss">
.m-list {
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline: 0;
}
</style>
