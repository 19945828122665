<template>
  <div
    :class="[
      {
        clickable: !readOnly,
        'marked-incorrect': item.isMarkedIncorrect,
      },
    ]"
  >
    <m-layout-stack horizontal>
      <div class="flex-auto">
        <span>Medication administration: </span>
        <span v-if="getProductAndQuantityAndRouteAndSite()">
          {{ getProductAndQuantityAndRouteAndSite() }}</span
        >
        <span v-if="item.administeringOrganisation"> • {{ item.administeringOrganisation }} </span>
        <span
          v-if="
            item.administrationDateIsNotEncounterDate || item.administrationDateIsNotDocumentDate
          "
        >
          • Administered {{ item.administrationDate }}</span
        >
        <span v-if="item.additionalInformation"> • {{ item.additionalInformation }} </span>
      </div>
      <i v-if="item.isRetrospectivelyAmended" style="white-space: nowrap">{{
        formatRetrospectivelyAmendedLabel
      }}</i>
    </m-layout-stack>
    <m-layout-stack
      v-if="
        (item.hiddenFromPatientFacingServices && !contextHiddenFromPatientFacingServices) ||
        (item.confidentialFromThirdParties && !contextConfidentialFromThirdPartyServices) ||
        item.linkedProblems.length
      "
      horizontal
      class="q-mt-sm"
    >
      <m-badge
        v-if="
          item.hiddenFromPatientFacingServices &&
          item.hiddenFromPatientFacingServices !== contextHiddenFromPatientFacingServices
        "
        text="Redacted from patient online access"
        gray
        data-testid="topic-hidden-from-patient-facing-services-badge"
      />
      <m-badge
        v-if="
          item.confidentialFromThirdParties &&
          item.confidentialFromThirdParties !== contextConfidentialFromThirdPartyServices
        "
        text="Confidential from Third Parties"
        gray
        data-testid="topic-confidential-from-third-parties-badge"
      />
      <m-badge
        v-for="(linkedProblem, index) in item.linkedProblems"
        :key="`medication-administered-linked-problem-${index}`"
        :text="linkedProblem.problemCodeDescription"
        gray
        :data-testid="`medication-administered-linked-problem-badge-${index}`"
      />
    </m-layout-stack>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

export type MedicationAdministrationJournalItem = {
  linkedProblems: { problemCodeDescription: string }[];
  confidentialFromThirdParties: boolean;
  hiddenFromPatientFacingServices: boolean;
  isMarkedIncorrect: boolean;
  productName: string;
  quantityAdministered: number;
  isRetrospectivelyAmended: boolean;
};

const props = defineProps<{
  item: MedicationAdministrationJournalItem;
  readOnly: boolean;
  contextHiddenFromPatientFacingServices?: boolean;
  contextConfidentialFromThirdPartyServices?: boolean;
}>();

const formatRetrospectivelyAmendedLabel = computed(() => {
  let label = [];
  if (props.item.isRetrospectivelyAmended) {
    label.push("Edited");
  }

  return label.join(" • ");
});

function getProductAndQuantityAndRouteAndSite() {
  const textAsArray = [
    props.item.productName,
    props.item.quantityAdministered,
    props.item.route,
    props.item.site,
  ];

  const text = textAsArray.filter((x) => x).join(" • ");

  return text === "" ? false : text;
}
</script>
