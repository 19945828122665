<template>
  <div
    :class="[
      {
        clickable: !readOnly,
        'marked-incorrect': item.isMarkedIncorrect,
      },
    ]"
  >
    <m-layout-stack horizontal>
      <div class="flex-auto">
        <span> Allergy: {{ item.allergyCodeDescription ?? item.substanceDescription ?? "" }} </span>
        <span v-if="item.onsetDate"> • Onset {{ item.onsetDate }} </span>
        <span v-if="getSeverityAndCertainty()"> • {{ getSeverityAndCertainty() }} </span>
        <span v-if="item.reactions"> • {{ item.reactions }} </span>
        <span v-if="item.isEnded"> • Ended </span>
        <span v-if="getConfidentialityText"> • {{ getConfidentialityText }} </span>
        <span v-if="item.additionalInformation"> • {{ item.additionalInformation }} </span>
      </div>
      <i v-if="item.isRetrospectivelyAmended" style="white-space: nowrap">{{
        formatRetrospectivelyAmendedLabel(item)
      }}</i>
    </m-layout-stack>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

export type AllergyJournalItem = {
  entryType: string;
  id: string;
  substanceDescription?: string;
  allergyCodeDescription: string;
  isEnded: boolean;
  severity?: string;
  certainty?: string;
  reactions?: string;
  additionalInformation?: string;
  onsetDate?: string;
  recordedBy: string;
  recordedByOrganisation: string;
  isMarkedIncorrect: boolean;
  hiddenFromPatientFacingServices: boolean;
  confidentialFromThirdParties: boolean;
  isRetrospectivelyAmended: boolean;
  linkedProblems: { problemCodeDescription: string }[];
  sortOrder?: unknown[];
  createdDateTime?: string;
};

const props = defineProps<{
  item: AllergyJournalItem;
  readOnly: boolean;
  contextHiddenFromPatientFacingServices?: boolean;
  contextConfidentialFromThirdPartyServices?: boolean;
}>();

function getSeverityAndCertainty() {
  const textAsArray = [props.item.severity, props.item.certainty];

  const text = textAsArray.filter((x) => x).join(" • ");

  return text ?? "";
}

const getConfidentialityText = computed(() => {
  const textArray = [];

  if (props.item.hiddenFromPatientFacingServices && !props.contextHiddenFromPatientFacingServices) {
    textArray.push("Redacted from Patient Online Access");
  }
  if (props.item.confidentialFromThirdParties && !props.contextConfidentialFromThirdPartyServices) {
    textArray.push("Confidential from Third Parties");
  }
  return textArray.length ? textArray.join(" • ") : "";
});

function formatRetrospectivelyAmendedLabel(item: AllergyJournalItem) {
  let label = [];
  if (item.isRetrospectivelyAmended) {
    label.push("Edited");
  }

  return label.join(" • ");
}
</script>
