// All Entry Types
export enum EntryType {
  CARE_RECORD_NOTE = "note",
  ALLERGY = "allergy",
  APPOINTMENT = "appointment",
  OBSERVATION = "observation",
  PRESCRIPTION = "prescription",
  PRESCRIPTION_ISSUE = "prescription-issue",
  MEDICATION_STATEMENT_PRESCRIBED_ELSEWHERE = "medication-statement-prescribed-elsewhere",
  MEDICATION_STATEMENT_OVER_THE_COUNTER = "medication-statement-over-the-counter",
  FOLLOW_UP_APPOINTMENT = "follow-up-appointment",
  RECALL = "recall",
  FIT_NOTE = "fit-note",
  IMMUNISATION = "immunisation",
  MEDICATION_ADMINISTRATION = "medication-administration",
  PROCEDURE = "procedure",
  COMMUNICATION = "communication",
  COMMUNICATION_THREAD = "communication-thread",
  INVESTIGATION = "investigation",
  REFERRAL_ATTACHMENT = "referral-attachment",
  DOCUMENT = "document",
  OUTBOUND_REFERRAL = "outbound-referral",
  FUTURE_ACTION = "future-action",
  TASK = "task",
  INVESTIGATION_REQUEST = "investigation-request",
}

// Change-Notes API Object

export type Note = {
  id: string;
  entryType: EntryType;
  text?: string;
  code?: SnomedCode | string;
  hasUnsavedChanges: boolean;
  isMarkedAsIncorrect: boolean;
};

export type PostNotesPayload = {
  notesToSave: SaveNotePartial[];
  sortOrder: SortOrder;
  sortOrderHash: string;
};
export type PostNotesResponse = { sortOrderHash: string };

export type FetchEntriesResponse = {
  entries: Entry[];
  sortOrderHash: string;
};

export type SnomedCode = {
  description: string;
  conceptId?: string;
  descriptionId?: string;
  parentConceptIds?: string[];
};

// Entries API Objects
export type BaseEntryProps = {
  id: string;
  createdDateTime?: string;
  recordedByOrganisation?: string;
  confidentialFromThirdParties?: boolean;
  hiddenFromPatientFacingServices?: boolean;
  linkedProblems?: [];
  sortOrder?: number | null;
  isMarkedAsIncorrect: boolean;
};

export type Entry = {
  id: string;
  label: string;
  srLabel: string;
  text: string;
  type: string;
  code?: SnomedCode | string;

  __idForList: string;
  __preserveEmptyNoteInSortOrder: boolean;

  isMarkedIncorrect?: boolean;
  isFinalised?: boolean;
  onClickUrl?: string;
  hasUnsavedChanges?: boolean;
  openAsModal?: boolean;
  disabled?: boolean;
  disabledReason?: string;
};

// Other
export type SaveNotePartial = {
  uuid: string;
  entryType: EntryType;
  note?: string;
  code?: object;
};

export type Action = {
  type: string;
  props: [];
  contextId: string;
  contextType: string;
  label: string;
  url: string;
  openInDockedModal: boolean;
  dockedModalPosition: "left" | "right";
};

export type SortOrder = { id: string; entryType: EntryType | string }[];

export const REFRESH_ALL_ENTRY_LISTS_EVENT_NAME = "refresh-all-entry-lists";
export const ENTRY_CREATED_EVENT_NAME = "entry-created";
export const ENTRY_DELETED_EVENT_NAME = "entry-deleted";
export type EntryCreatedEventData = { context?: { contextId?: string; contextType?: string } };
export type EntryDeletedEventData = { context?: { contextId?: string; contextType?: string } };
