<script setup lang="ts">
import { QIcon, QTooltip } from "quasar";
import { Key } from "ts-key-enum";
import { computed, ref, useSlots, warn, watchEffect } from "vue";
import { type DrawerUrlOptions } from "../../../store/drawers";
import { usePermission } from "../../../composables/permission";
import MTooltip from "../MTooltip";
import { useRouter } from "vue-router";

const props = defineProps<{
  href?: string;

  // TODO add type name
  to?: string | { url: string; name?: string; method?: string } | { name: string };
  permission?: string | true | "true" | { url: string; method?: string };

  toModal?: string | DrawerUrlOptions | object | false;
  toSlideover?: string | DrawerUrlOptions | object | false;

  border?: boolean;
  disable?: boolean;
  disabled?: boolean;

  outline?: boolean;
  ghost?: boolean;

  icon?: string;
  count?: string | number;

  color?: "secondary" | "danger";
  label?: string;
  srLabel?: string;

  click?: (...args: any[]) => PromiseLike<any> | void;
  onClick?: (...args: any[]) => PromiseLike<any> | void;

  loading?: boolean;
  dense?: boolean;
  small?: boolean;

  class?: any;

  borderless?: boolean;

  iconOnly?: boolean;

  tooltip?: string;
}>();

const buttonEl = ref<HTMLButtonElement | HTMLAnchorElement | null>(null);

const slots = useSlots();

// eslint-disable-next-line no-undef
if (IS_APP && (import.meta.env.DEV || location.host.startsWith("uat."))) {
  watchEffect(() => {
    if (props.label || slots.default || props.srLabel) return;
    warn('This button is not accessible, please pass a "label" or "sr-label" property');
  });
}

defineEmits({
  click: (...args: any[]) => true,
});

const submitting = ref(false);
const { disabled, hasAccess, onClick } = usePermission(props, false, useRouter(), submitting);

const componentType = computed(() => {
  if (props.href) return "a";
  if (disabled.value || !props.to) {
    return "button";
  }
  return "router-link";
});

const fontAwesomeIcon = computed(() => {
  switch (props.icon) {
    case "add":
      return "fa-solid fa-plus";
    default: {
      return props.icon;
    }
  }
});

const bclass = computed(() => props.class);

async function handleClick(ev: MouseEvent | KeyboardEvent) {
  if (submitting.value || disabled.value) return;
  if ("code" in ev && ev.code === Key.Enter) {
    return;
  }
  ev.stopPropagation();

  onClick(ev);
}

function focus() {
  buttonEl.value?.focus();
}

defineExpose({
  focus,
});
</script>
<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<template>
  <div
    class="medicus-button-container"
    :color="color"
    :class="[
      !border && !ghost && 'raised',
      { disable: disabled, ghost, outline, dense },
      { 'icon-only small': small || iconOnly },
      bclass,
      { borderless },
    ]"
  >
    <q-tooltip v-if="!hasAccess"> You don’t have permission to perform this action. </q-tooltip>
    <component
      :is="componentType"
      ref="buttonEl"
      class="medicus-button flex-auto medicus-outline"
      :title="iconOnly ? label : null"
      v-bind="$attrs"
      :data-testid="$attrs['data-testid']"
      :disabled="disabled"
      :type="$attrs.type || 'button'"
      :to="to"
      :href="href"
      @click="handleClick"
      @keydown.enter="handleClick"
    >
      <template v-if="icon">
        <i v-if="icon.startsWith('fak')" class="q-mr-sm q-icon" :class="icon" />
        <q-icon v-else ref="iconEl" :name="fontAwesomeIcon" class="q-mr-sm" />
      </template>
      <div v-if="count !== undefined" class="button-count q-mr-sm">
        <span>{{ +count > 99 ? "+99" : count }}</span>
      </div>
      <div v-if="ghost" class="button-content" :class="{ 'sr-only': iconOnly || srLabel }">
        <slot>
          <span v-text="label ?? srLabel" />
        </slot>
      </div>
      <slot v-else>
        <span :class="{ 'sr-only': iconOnly || srLabel }">
          {{ label ?? srLabel }}
        </span>
      </slot>
      <m-tooltip v-if="tooltip" :text="tooltip" :scroll-target="buttonEl" />
    </component>
  </div>
</template>

<style lang="scss" scoped>
.medicus-button-container {
  height: 35px;
  min-width: 80px;
  font-weight: bold;
  display: flex;

  .medicus-button {
    border-radius: 4px;

    padding: 0 12px;

    width: 100%;
    height: 100%;
    border: none;

    background: transparent;

    // text
    font-style: normal;
    // font-weight: bold;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height */

    text-align: center;

    cursor: pointer;

    outline-width: 20px;

    text-decoration: none;

    outline: none;

    // display: inline-block;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  > a {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

  // colours
  .medicus-button {
    background: var(--theme-blue);
    color: #ffffff;
    border: 1px solid var(--theme-accent-blue);

    &:hover {
      background: var(--theme-accent-blue);
    }
  }

  &.hover-component .medicus-button,
  .medicus-button:hover {
    background: var(--theme-accent-blue);
  }

  &[color="danger"] {
    .medicus-button {
      font-weight: bold;

      border-color: var(--status-red);
      background: #ffffff;
    }
    button,
    a {
      color: var(--status-red);
    }

    &.hover-component .medicus-button,
    .medicus-button:hover {
      background: var(--status-light-red);
      border-color: var(--status-red);
    }

    &.ghost {
      .medicus-button {
        color: var(--status-red);
      }
      &.hover-component .medicus-button,
      .medicus-button:hover {
        background: none;
      }
    }
  }

  &[color="secondary"] {
    .medicus-button {
      font-weight: bold;
      border-color: var(--text-color-light);
      color: var(--text-color);
      background: #ffffff;
    }

    button,
    a {
      color: var(--text-color-light);
    }

    &.hover-component .medicus-button,
    .medicus-button:hover {
      // background: #e7eaee;
      background: var(--grey-light);
    }

    &.ghost {
      .medicus-button {
        color: var(--text-color);
      }
      &.hover-component .medicus-button,
      .medicus-button:hover {
        background: none;
      }
    }
  }

  // &[color].ghost.disabled > .medicus-button,
  &.disable > .medicus-button,
  .disabled,
  [disabled] {
    font-weight: bold;
    background: #ffffff !important;

    border-color: var(--grey-light) !important;
    color: var(--grey) !important;

    &:hover {
      background: #ffffff !important;

      border-color: var(--grey-light) !important;
      color: var(--grey) !important;
    }

    .button-content {
      background: #ffffff !important;

      border-color: var(--grey-light) !important;
      color: var(--grey) !important;

      text-decoration-line: none !important;
    }

    span {
      color: var(--grey) !important;
      text-decoration: none !important;
      text-decoration-line: none !important;
    }
  }

  // /colours

  // ghost

  &.ghost {
    border: none;
    box-shadow: none;

    // prevent size changes
    white-space: nowrap;
    background: none;

    min-width: auto;
    align-self: flex-start;

    height: auto;

    .medicus-button {
      color: var(--theme-blue);

      padding: 0;
      background: none;

      border: none;

      font-weight: bold;
    }

    > a {
      display: inline-block !important;
    }

    .button-content {
      // display: contents;
      text-decoration-line: underline;
      text-underline-offset: 3px;

      &:hover,
      &:focus-within {
        text-decoration-thickness: 3px;
      }
    }

    i {
      text-decoration: none !important;
      text-decoration-line: none !important;
      text-decoration-color: white;
    }

    &.disable {
      background-color: transparent !important;

      .button-content,
      .medicus-button {
        background-color: transparent !important;
      }
    }
  }
  // /ghost

  &.dense {
    min-width: auto;
    border-color: transparent !important;
    border-width: 3px !important;

    .medicus-button {
      padding: 5px 12px;
    }

    i,
    svg {
      margin-right: 0;
    }
  }

  &.small {
    min-width: auto;
    align-self: center;
  }

  &.borderless > .medicus-button {
    border: none;
  }
  &.icon-only {
    min-width: auto;
    &:focus {
      padding: 3px 8px;
    }
    i,
    svg {
      margin: 0;
    }

    button,
    a {
      padding: 5px 10px;
    }
  }

  .button-count {
    background-color: var(--status-light-blue);
    border: 1px solid var(--theme-accent-blue);
    color: var(--theme-blue);

    border-radius: 9999px;
    width: 22px;
    height: 22px;
    font-size: 12px;

    display: flex;
    align-items: center;
    justify-content: center;

    > span {
      padding-top: 2px;
    }
  }

  &.inline {
    display: inline-block;
  }
}
</style>
