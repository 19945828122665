<template>
  <div
    :class="[
      {
        clickable: !readOnly,
        'marked-incorrect': item.isMarkedIncorrect,
      },
    ]"
    data-testid="fit-note-presenter"
  >
    <m-layout-stack horizontal>
      <div class="flex-auto">
        <span>Fit Note: {{ item.decision }}</span>
        <span v-if="item.assessmentDateIsNotEncounterDate || item.assessmentDateIsNotDocumentDate">
          • Assessed {{ item.assessmentDate }}</span
        >
        <span v-if="getCaseAndCondition()"> • {{ getCaseAndCondition() }}</span>
        <span v-if="getConfidentialityText"> • {{ getConfidentialityText }} </span>
      </div>
      <i v-if="item.isRetrospectivelyAmended" style="white-space: nowrap">{{
        formatRetrospectivelyAmendedLabel
      }}</i>
    </m-layout-stack>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

export type FitNoteJournalItem = {
  entryType: string;
  id: string;
  decision: string;
  assessmentDate?: string;
  assessmentDateIsNotEncounterDate: boolean;
  assessmentDateIsNotDocumentDate: boolean;
  validPeriod: string;
  diagnosis: string;
  recordedBy: string;
  recordedByOrganisation: string;
  isMarkedIncorrect: boolean;
  hiddenFromPatientFacingServices: boolean;
  confidentialFromThirdParties: boolean;
  isRetrospectivelyAmended: boolean;
  linkedProblems: any[];
  createdDateTime?: string;
};

const props = defineProps<{
  item: FitNoteJournalItem;
  readOnly: boolean;
  contextHiddenFromPatientFacingServices?: boolean;
  contextConfidentialFromThirdPartyServices?: boolean;
}>();

const formatRetrospectivelyAmendedLabel = computed(() => {
  let label = [];
  if (props.item.isRetrospectivelyAmended) {
    label.push("Edited");
  }

  return label.join(" • ");
});

const getConfidentialityText = computed(() => {
  const textArray = [];

  if (props.item.hiddenFromPatientFacingServices && !props.contextHiddenFromPatientFacingServices) {
    textArray.push("Redacted from Patient Online Access");
  }
  if (props.item.confidentialFromThirdParties && !props.contextConfidentialFromThirdPartyServices) {
    textArray.push("Confidential from Third Parties");
  }
  return textArray.length ? textArray.join(" • ") : "";
});

function getCaseAndCondition() {
  const textAsArray = [props.item.validPeriod, props.item.diagnosis];

  const text = textAsArray.filter((x) => x).join(" • ");

  return text === "" ? false : text;
}
</script>
