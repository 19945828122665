<script setup lang="ts">
import { Loading, QSpinnerBall } from "quasar";
import {
  ComponentOptions,
  ComponentPublicInstance,
  getCurrentInstance,
  inject,
  provide,
  ref,
  watch,
  onMounted,
} from "vue";
import { NO_OP, debounce, usePromise, usePromiseLazy } from "vue-composable";
import { ComponentTypes } from "./helper";

import merge from "deepmerge";
import { isPlainObject } from "is-plain-object";
import { onBeforeRouteUpdate } from "vue-router";
import { DrawerItem, DrawerOptions, useDrawerStore } from "../../../../store/drawers";
import bus, { dispatch, listen } from "../../../../utils/bus";
import { loadComponentData } from "../../../../utils/component";
import { showDialog } from "../../../../utils/dialog";
import {
  ENTRY_CREATED_EVENT_NAME,
  ENTRY_DELETED_EVENT_NAME,
} from "../../../medicus/MInlineEntryList/types";
import { useKeyboardShortcut } from "../../../../composables/keyboardShortcut";
import { useApp } from "../../../../store";

const props = defineProps<{
  type: DrawerItem["type"];

  title?: string;
  patientId?: string | null;

  params?: Record<string, any>;
  props?: Record<string, any>;
  additionalCreatePayloadData?: Record<string, any>;
  additionalDeletePayloadData?: Record<string, any>;
  options?: DrawerOptions;

  noData?: boolean;
  ignoreReload?: boolean;
  /* deprecated property
   * use close instead
   * @deprecated
   */
  keepOpen?: boolean;

  close?: true;
  component: null | ComponentOptions;
  componentUrl: string;
  promise: Promise<ComponentOptions>;

  last: boolean;
}>();
const emit = defineEmits({
  close: () => true,
  cancel: () => true,
  open: () => true,
});

const { loading, result, exec } = usePromise(() => props.promise);

watch(() => props.promise, exec, { deep: false });
watch(
  loading,
  (loading, _, onInvalidate) => {
    if (loading) {
      Loading.show({
        spinner: QSpinnerBall,
        delay: 500,
        customClass: "medicus-loading-ball",
      });
      onInvalidate(() => Loading.hide());
    }
  },
  {
    immediate: true,
  },
);

// const setTitle = inject<(s: string) => void>("setTitle", NO_OP);
// const setPatient = inject<(s: Patient) => void>("setPatient", NO_OP);
// const setModalType = inject<(s: string | undefined) => void>("setModalType", NO_OP);
// const setBgGrey = inject<(s: boolean | undefined) => void>("setBgGrey", NO_OP);
// const setPatientId = inject<(s: string | undefined) => void>("setPatientId", NO_OP);
// const removePadding = inject<(s: boolean | undefined) => void>("removePadding", NO_OP);

const title = ref(props.title);
const preventBackgroundClose = ref(props.options?.preventBackgroundClose ?? false);
const patientId = ref(props.patientId);
const formTouched = ref(false);

const contentEl = ref<ComponentPublicInstance | null>(null);
const resultEl = ref<ComponentPublicInstance | null>(null);

provide("cancel", (shouldClose: boolean) => {
  if (shouldClose) {
    emit("cancel");

    onClose();
  }
});

provide("success", (shouldClose: boolean, reloadOnSubmit = true) => {
  props.options?.onSuccess?.();

  if (shouldClose) {
    onClose();
  }

  if (props.additionalCreatePayloadData) dispatch(ENTRY_CREATED_EVENT_NAME, {});
  if (props.additionalDeletePayloadData) dispatch(ENTRY_DELETED_EVENT_NAME, {});

  if (reloadOnSubmit) reloadData();
});

provide("additionalCreatePayloadData", props.additionalCreatePayloadData || null);
provide("additionalDeletePayloadData", props.additionalDeletePayloadData || null);
provide("drawerOptions", props.options ?? null);

function onClose() {
  formTouched.value = false;
  emit("cancel");
  bus.emit("modal:close", true);
}

function shouldClose(e?: Event) {
  e?.preventDefault();
  if (formTouched.value) {
    showDialog({
      title: "Unsaved Changes",
      text: "You have unsaved changes. Are you sure you want to close the form?",
      cancelLabel: "No, don't close",
      okLabel: "Yes, close",
      type: "dialog",
    }).onOk(() => {
      onClose();
    });

    return false;
  } else {
    onClose();
  }

  return true;
}

provide("setTitle", (s: string) => (title.value = s));
provide("setPreventBackgroundClose", (newPreventBackgroundClose: boolean) => {
  preventBackgroundClose.value = newPreventBackgroundClose;
});
provide("setPatientId", (s: string) => (patientId.value = s));
provide("close", onClose);
provide("formTouched", (e: boolean) => (formTouched.value = e));

const reloadData = inject("reloadData", NO_OP);
provide("reloadData", () => {
  refreshData();
  reloadData();
});

useKeyboardShortcut("close-drawer-item", shouldClose);

onBeforeRouteUpdate(() => {
  if (formTouched.value) {
    return shouldClose();
  }
  return true;
});

onMounted(() => {
  if (props.ignoreReload && !props.noData) {
    loadComponentData(props.componentUrl).then((x: any) => {
      if (resultEl.value && x) {
        Object.assign(
          resultEl.value.$data,
          merge(resultEl.value.$data, x, {
            isMergeableObject: isPlainObject,
          }),
        );
      }
    });
  }
});

const instance = getCurrentInstance();
const { exec: _refreshData } = usePromiseLazy(
  () =>
    instance.isMounted &&
    !instance.isUnmounted &&
    !props.noData &&
    !props.ignoreReload &&
    props.componentUrl &&
    loadComponentData(props.componentUrl).then((x: any) => {
      if (resultEl.value && x) {
        Object.assign(
          resultEl.value.$data,
          merge(resultEl.value.$data, x, {
            isMergeableObject: isPlainObject,
          }),
        );
      }
    }),
);

const refreshData = debounce(_refreshData, 50);
listen("reloadData", refreshData);
provide("drawer-url", props.componentUrl);

watch(
  contentEl,
  () => {
    // Not allowed to open a form modal/slideover if docked modal open
    if (contentEl.value) {
      const formEl = (contentEl.value.$el as HTMLElement).querySelector("form");

      if (useApp().isDockedModalOpen && formEl) {
        // Close current drawer item and inform user
        useDrawerStore().closeLast();
        showDialog({
          title: "Incomplete Action",
          html: `
            <p>You have ${
              useApp().dockedModalTitle ? `the ${useApp().dockedModalTitle}` : "a"
            } form open which means you cannot start another action.</p>
            <p class="q-mt-sm">Please complete or close the other form to continue</p>
          `,
          noCancel: true,
          okLabel: "Dismiss",
          okColor: "submit",
        });
      }
    }
  },
  { flush: "pre" },
);
</script>
<template>
  <div class="drawer-render" :class="['render-' + type]">
    <suspense>
      <component
        :is="ComponentTypes[type]"
        v-if="!loading && result"
        ref="contentEl"
        v-bind="$props.props"
        :title="title"
        :patient-id="patientId"
        @close="onClose"
      >
        <component :is="result" v-if="result" ref="resultEl" v-bind="$props.props" />
        <div v-else>[ERROR]</div>
      </component>
    </suspense>
  </div>
</template>

<style scoped lang="scss">
.drawer-render {
  position: fixed;
  display: flex;

  height: 100%;
  width: 100%;

  &.render-modal {
    padding: 1rem;

    @media (max-width: 700px) {
      padding: 0;
    }
  }
}
</style>
