<template>
  <div
    :class="[
      {
        clickable: !readOnly,
        'marked-incorrect': item.isMarkedIncorrect,
      },
      'note-presenter',
    ]"
  >
    <m-layout-stack horizontal>
      <div class="flex-auto">
        <span v-if="getCodeAndNote()">
          {{ getCodeAndNote() }}
        </span>
        <span v-if="item.isExplicitlyIncludedInSCR"> • Explicitly included in SCR</span>
      </div>
      <i v-if="item.isRetrospectivelyAmended" style="white-space: nowrap">{{
        formatRetrospectivelyAmendedLabel
      }}</i>
    </m-layout-stack>
    <div></div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

type NoteItem = {
  entryType: string;
  id: string;
  note: string;
  clinicalCodeDescription?: string;
  recordedBy: string;
  recordedByOrganisation?: string;
  isMarkedIncorrect: boolean;
  hiddenFromPatientFacingServices: boolean;
  confidentialFromThirdParties: boolean;
  isExplicitlyIncludedInSCR: boolean;
  isRetrospectivelyAmended: boolean;
  patientBannerFlags: string[];
  linkedProblems: { problemCodeDescription: string }[];
  createdDateTime?: string;
};

const props = defineProps<{
  item: NoteItem;
  readOnly: boolean;
  contextHiddenFromPatientFacingServices?: boolean;
  contextConfidentialFromThirdPartyServices?: boolean;
}>();

const formatRetrospectivelyAmendedLabel = computed(() => {
  let label = [];
  if (props.item.isRetrospectivelyAmended) {
    label.push("Edited");
  }

  return label.join(" • ");
});

function getCodeAndNote() {
  const textAsArray = [props.item.clinicalCodeDescription, props.item.note];

  const text = textAsArray.filter((x) => x).join(" • ");

  return text === "" ? false : text;
}
</script>
<style>
.note-presenter {
  white-space: pre-wrap;
}
</style>
