<template>
  <m-card
    :class="{ 'cursor-pointer': readOnly }"
    sr-label="View communication"
    @click="openCareRecordCommunicationDrawer"
  >
    <div
      :class="[
        {
          clickable: !readOnly,
          'marked-incorrect': entry.data.isMarkedIncorrect,
        },
      ]"
    >
      <m-layout-stack no-gap>
        <m-layout-stack horizontal justify-between>
          <p>
            <strong>{{ communicationTitle }}</strong>
            <span>{{ expandedCommunicationTitle }}</span>
          </p>

          <i
            v-if="entry.data.isRetrospectivelyAmended && !isInConsultation"
            style="white-space: nowrap"
          >
            Edited
          </i>
          <m-badge
            v-for="badge in formattedBadges"
            :key="badge"
            :text="badge.text"
            :amber="badge.color === 'amber' ? true : undefined"
            :data-testid="badge['data-testid']"
          />
        </m-layout-stack>
        <div
          v-if="
            entry.data.hiddenFromPatientFacingServices ||
            entry.data.confidentialFromThirdParties ||
            entry.data.linkedProblems.length
          "
        >
          <span v-if="getConfidentialityTextAndLinkedProblems()" class="secondary-text">
            {{ getConfidentialityTextAndLinkedProblems() }}
          </span>
        </div>
        <m-separator v-if="!isInConsultation && showMessage" class="q-my-xs" />
        <p
          v-if="showMessage"
          :class="isInConsultation && formattedBadges.length ? 'q-mt-sm' : undefined"
          style="white-space: pre; text-wrap: wrap"
        >
          {{ entry.data.communication.messageBody }}
        </p>
      </m-layout-stack>
    </div>
  </m-card>
</template>

<script setup lang="ts">
import { openModal, openSlideover } from "../../../../composables/dialog/drawer";
import { PatientJournalRecord } from "../types";
import MBadge from "../../MBadge";
import MLayoutStack from "../../MLayoutStack";
import MSeparator from "../../MSeparator";
import { computed } from "vue";

const props = defineProps<{
  entry: PatientJournalRecord;
  readOnly: boolean;
  clickable?: boolean;
}>();
function getConfidentialityTextAndLinkedProblems() {
  const textArray = [];

  if (props.entry.data.hiddenFromPatientFacingServices) {
    textArray.push("Redacted from Patient Online Access");
  }
  if (props.entry.data.confidentialFromThirdParties) {
    textArray.push("Confidential from Third Parties");
  }
  if (props.entry.data.isExplicitlyIncludedInSCR) {
    textArray.push("Explicitly included in SCR");
  }

  const text = textArray.join(" • ");

  const problemsArray = props.entry.data.linkedProblems || [];
  const problemDescriptions = problemsArray
    .filter((x) => x.problemCodeDescription)
    .map((x) => x.problemCodeDescription);

  const problemText = problemDescriptions.length
    ? `Linked Problems: ${problemDescriptions.join(", ")}`
    : "";

  const finalTextArray = [text, problemText].filter(Boolean);

  return finalTextArray.length ? finalTextArray.join(" • ") : false;
}

function openCareRecordCommunicationDrawer() {
  if (props.readOnly) {
    if (props.entry.data.communication.patientQuestionnaireResponse) {
      openModal(`/clinical/communication/care-record-communication-overview/${props.entry.id}`, {
        close: true,
      });
    } else {
      openSlideover(
        `/clinical/communication/care-record-communication-overview/${props.entry.id}`,
        { close: true },
      );
    }
  }
}

const isInConsultation = computed(() => {
  return (
    props.entry.data.contextType?.isConsultationTopic ||
    props.entry.data.contextType?.isConsultationTopicHeading
  );
});

const formattedBadges = computed(() => {
  const badges = [];

  if (props.entry.data.communication.displayStatus?.isDeliveryFailed) {
    badges.push({
      text: "Delivery Failed",
      color: "amber",
    });
  }
  return badges;
});

const showMessage = computed(() => {
  if (
    props.entry.data.communication.direction.isOutbound &&
    props.entry.data.showMessageOnJournal
  ) {
    return true;
  }

  return (
    props.entry.data.communication.direction.isInbound &&
    props.entry.data.inboundCareRecordCommunicationContext.isInboundCommunication
  );
});

const communicationTitle = computed(() => {
  let entryName;

  if (props.entry.data.communication.direction.isOutbound) {
    entryName = "Communication";
  } else {
    entryName = props.entry.data.outboundCareRecordCommunicationTitle ?? "Inbound communication";
  }

  return `${entryName}${props.entry.data.snomedCTCode ? `:` : ""}`;
});

const expandedCommunicationTitle = computed(() => {
  let parts = [];

  if (props.entry.data.snomedCTCode) {
    parts.push(` ${props.entry.data.snomedCTCode}`);
  }

  if (!isInConsultation.value && props.entry.data.communication.direction.isOutbound) {
    parts.push(` • Sent by ${props.entry.data.sentBy ?? "Unknown"}`);
  }

  return parts.join(" ");
});
</script>
