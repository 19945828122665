<template>
  <m-card
    :class="{ 'cursor-pointer': readOnly }"
    sr-label="View referral"
    @click="referralOverview()"
  >
    <div
      :class="[
        {
          clickable: !readOnly,
          'marked-incorrect': entry.data.isMarkedIncorrect,
        },
      ]"
    >
      <m-layout-stack horizontal>
        <div class="flex-auto">
          <span><b>Referral: </b>{{ entry.data.referralCode }}</span>
          <span v-if="entry.data.priority"> ({{ entry.data.priority }})</span>
          <span v-if="entry.data.referralProvider"> • {{ entry.data.referralProvider }}</span>
          <span v-if="entry.data.referringClinician"> • {{ entry.data.referringClinician }}</span>
          <span v-if="entry.data.cancelledStatusLabel">
            • {{ entry.data.cancelledStatusLabel }}</span
          >
        </div>
        <i v-if="entry.data.isRetrospectivelyAmended" style="white-space: nowrap">{{
          formatRetrospectivelyAmendedLabel
        }}</i>
        <m-badge
          v-if="entry.data.incompleteStatusLabel"
          amber
          :text="entry.data.incompleteStatusLabel"
        />
      </m-layout-stack>
      <div
        v-if="
          entry.data.hiddenFromPatientFacingServices ||
          entry.data.confidentialFromThirdParties ||
          entry.data.isExplicitlyIncludedInSCR ||
          entry.data.linkedProblems.length
        "
      >
        <span class="secondary-text">
          {{ getConfidentialityTextAndLinkedProblems() }}
        </span>
      </div>
      <m-separator v-if="entry.data.additionalInformation" class="q-my-xs" />
      <span v-if="entry.data.additionalInformation">
        {{ entry.data.additionalInformation }}
      </span>
    </div>
  </m-card>
</template>

<script setup lang="ts">
import { PatientJournalRecord } from "../types";
import { openModal, openSlideover } from "../../../../composables/dialog/drawer";
import { computed } from "vue";
import MSeparator from "../../MSeparator";

const props = defineProps<{
  entry: PatientJournalRecord;
  readOnly: boolean;
  clickable?: boolean;
}>();

function referralOverview() {
  if (props.entry.data.isManual === true) {
    return props.readOnly
      ? openSlideover(`/clinical/referral/manual-referral/overview/${props.entry.id}`, {
          close: true,
        })
      : undefined;
  } else if (props.entry.data.isAdviceAndGuidance === true) {
    return props.readOnly
      ? openModal(
          `/clinical/referral/nhs-e-referral/advice-and-guidance/overview/${props.entry.id}`,
          {
            close: true,
          },
        )
      : "";
  } else {
    return props.readOnly
      ? openSlideover(`/clinical/referral/nhs-e-referral/overview/${props.entry.id}`, {
          close: true,
        })
      : "";
  }
}
const formatRetrospectivelyAmendedLabel = computed(() => {
  let label = [];
  if (props.entry.data.isRetrospectivelyAmended) {
    label.push("Edited");
  }

  return label.join(" • ");
});

function getConfidentialityTextAndLinkedProblems() {
  const textArray = [];

  if (props.entry.data.hiddenFromPatientFacingServices) {
    textArray.push("Redacted from Patient Online Access");
  }
  if (props.entry.data.confidentialFromThirdParties) {
    textArray.push("Confidential from Third Parties");
  }
  if (props.entry.data.isExplicitlyIncludedInSCR) {
    textArray.push("Explicitly included in SCR");
  }

  const text = textArray.join(" • ");

  const problemsArray = props.entry.data.linkedProblems || [];
  const problemDescriptions = problemsArray
    .filter((x) => x.problemCodeDescription)
    .map((x) => x.problemCodeDescription);

  const problemText = problemDescriptions.length
    ? `Linked Problems: ${problemDescriptions.join(", ")}`
    : "";

  const finalTextArray = [text, problemText].filter(Boolean);

  return finalTextArray.length ? finalTextArray.join(" • ") : false;
}
</script>
