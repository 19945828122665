<script lang="ts" setup>
import { isString } from "@vue/shared";
import { Key } from "ts-key-enum";
import { computed, getCurrentInstance, onMounted, provide, ref, watch } from "vue";
import { isObject, useDebounce, useEvent, useOnOutsidePress } from "vue-composable";
import { useInputRules } from "../../../../composables/horizontalLabel";
import { randomID } from "../../../../utils/helpers";
import MInput from "../MInput";
import { GroupOption, ItemOption, SelectLabelSymbol } from "../MSelect";
import SimpleMenu from "../SearchRenderers/SimpleMenu.vue";
import SelectRenderList from "../SearchRenderers/SelectRenderList.vue";

//// PROPS
const props = withDefaults(
  defineProps<{
    modelValue?: any;
    name?: string;

    label?: string;

    placeholder?: string;

    for?: string;

    options: Array<GroupOption | ItemOption>;

    clearable?: boolean;

    useInput?: boolean;

    helper?: string;

    requiredText?: string;

    instructions?: string;

    autofocus?: boolean;
  }>(),
  {
    useInput: undefined,
  },
);

//// EVENTS
const emit = defineEmits({
  "update:modelValue": (_value: any) => true,
  "input-value": (_option: ItemOption) => true,

  blur: () => true,
});

//// STATE PROPERTIES
const inputEl = ref<InstanceType<typeof MInput> | null>(null);
const text = ref("");
const isMousedown = ref(false); // Used to determine if input focus came from a click or a tab
const focusedItem = ref<ItemOption | null>(null);
const focusedItemId = ref(0);
const isOpen = ref(false);
const { proxy } = getCurrentInstance()!;
const originalValue = props.modelValue;
const isFetching = ref(false);
const { value, displayErrors, formTouched, touched } = useInputRules(
  props,
  () => {
    //focus
    inputEl.value?.focus();
  },
  [],
  true,
  () => props.requiredText || `Enter a value for '${props.label}'`,
  undefined,
  () => {
    inputEl.value.$el.scrollIntoView?.();
  },
);
const selectedOption = ref<ItemOption | null>(null);
let lastSearchCancel: Function | undefined;
const selectableItems = ref<Array<ItemOption>>([]);

provide("formData", undefined);
provide("validationController", undefined);

//// COMPUTED VARS
const id = computed(() => (props.for ? props.for : randomID("select")));
const menuId = computed(() => (props.for ? `${props.for}-menu` : randomID("select-menu")));
const isInput = computed(() => Boolean(props.useInput !== false));
const filteredItems = computed(() => {
  if (text.value === selectedOption.value?.label) {
    return props.options ?? [];
  }
  if (!props.options) return [];
  if (!text.value) {
    return props.options;
  }

  const needle = `${text.value ?? ""}`.toLocaleLowerCase();

  return props.options
    .map((it) => {
      if ("items" in it) {
        const items = it.items.filter((i) => i.label.toLocaleLowerCase().includes(needle));
        if (items.length === 0) {
          return null;
        }
        return {
          ...it,
          items,
        };
      }
      if (it.label.toLocaleLowerCase().includes(needle)) return it;
      return null;
    })
    .filter(Boolean);
});

//// WATCHERS & LISTENERS
watch(filteredItems, (items) => {
  populateSelectableItems(items);

  // as we recompute the list reset the pointer to the top of the list
  if (selectableItems.value[0]) {
    focusedItemId.value = 0;
    focusedItem.value = selectableItems.value[0];
  } else {
    focusedItemId.value = 0;
    focusedItem.value = null;
  }
});

function populateSelectableItems(items: Array<ItemOption | GroupOption>) {
  selectableItems.value = [];
  for (const item of items) {
    if ("items" in item) {
      for (const subItem of item.items) {
        selectableItems.value.push(subItem);
      }
    } else {
      selectableItems.value.push(item);
    }
  }
}

watch(isOpen, (open) => {
  // as we recompute the list reset the pointer to the top of the list
  if (open && focusedItem.value === null && selectableItems.value[0]) {
    focusedItemId.value = 0;
    focusedItem.value = selectableItems.value[0];
  }
});

watch(
  value,
  async (v, o) => {
    if (null === v && o) {
      onClear();
      return;
    }
    if (v && typeof v === "object") {
      if (v === selectedOption.value?.value) return;
      if (Array.isArray(v)) {
        // same object
        if (JSON.stringify(v) === JSON.stringify(o)) return;
        // if not multiple, just ignore -> remove??
        return;
      } else {
        const keys = Object.keys(v);
        if (keys.length == 2 && keys.includes("label") && keys.includes("value")) {
          selectedOption.value = v;
          text.value = getLabel(v);
          value.value = v.value;
        }
      }
    }

    // ENG-8731
    if (!props.options) {
      if (isString(v)) {
        selectedOption.value = { label: v, value: v };
        text.value = v;
        value.value = v;
      } else if (isObject(v) && !Array.isArray(v)) {
        const keys = Object.keys(v);
        if (keys.length == 2 && keys.includes("label") && keys.includes("value")) {
          // @ts-expect-error not the correct type
          selectedOption.value = v;
          text.value = getLabel(v);
          value.value = v.value;

          return;
        }
        selectedOption.value = {
          label: getLabel(v),
          value: v,
        };
        text.value = getLabel(v);
        value.value = v;
      }
      return;
    }

    if (Array.isArray(v)) {
      return;
    }

    setOption(v);
  },
  { immediate: true, flush: "pre" },
);

watch(
  text,
  (v, p, onInvalidate) => {
    if (!v) {
      return;
    }
    if (v === selectedOption.value?.label) return;

    onInvalidate(() => {
      lastSearchCancel?.();
    });
  },
  {
    flush: "sync",
  },
);

//// LIFECYCLE HOOKS
onMounted(() => {
  populateSelectableItems(filteredItems.value);
});

//// GENERAL FUNCTIONS
function onKeydown(e: KeyboardEvent) {
  if ((e.target as HTMLButtonElement).tagName === "BUTTON") {
    return;
  }
  let newOpen = isOpen.value;

  switch (e.code) {
    case Key.Tab:
      newOpen = false;
      break;
    case Key.ArrowDown:
      e.preventDefault();
      if (focusedItemId.value < selectableItems.value.length - 1) {
        // go down, until hitting the end of the list, then cycle back to the top
        if (newOpen) {
          ++focusedItemId.value;
        }
        focusedItem.value = selectableItems.value[focusedItemId.value];
      } else {
        if (newOpen) {
          focusedItemId.value = 0;
          focusedItem.value = selectableItems.value[focusedItemId.value];
        }
      }
      if (!newOpen) {
        newOpen = true;
      }
      break;
    case Key.ArrowUp: {
      e.preventDefault();
      if (!newOpen) {
        newOpen = true;
      }
      if (focusedItemId.value > 0) {
        // go up, until hitting the top of the list, then cycle back to the bottom
        if (newOpen) {
          --focusedItemId.value;
        }
        focusedItem.value = selectableItems.value[focusedItemId.value];
      } else {
        if (newOpen) {
          focusedItemId.value = selectableItems.value.length - 1;
          focusedItem.value = selectableItems.value[focusedItemId.value];
        }
      }
      break;
    }
    case "Space": {
      if (isInput.value) {
        return;
      }
      e.preventDefault();
      newOpen = !newOpen;
      break;
    }
    // eslint-disable-next-line no-fallthrough
    case Key.Enter:
      e.preventDefault();
      if (!newOpen) {
        newOpen = true;
      } else {
        if (focusedItem.value) {
          setOption(focusedItem.value);
          newOpen = false;
        }
      }
      break;
    case Key.Escape: {
      e.preventDefault();
      if (isOpen.value) {
        e.stopPropagation();
      }
      newOpen = false;
      break;
    }
    case Key.Backspace:
    case Key.Delete: {
      if (isInput.value) return;

      if (props.clearable) {
        selectedOption.value = null;
        text.value = "";
        value.value = null;
      }
      break;
    }
  }

  // only update the state after the keydownCB has done it's thing
  isOpen.value = newOpen;
}

function onSelect(e: GroupOption | ItemOption) {
  if ("title" in e) return;

  emit("input-value", e);

  setOption(e);
  isOpen.value = false;
}

function onClick(e: MouseEvent) {
  if ((e.currentTarget as HTMLDivElement).contains(e.target as HTMLElement)) {
    isOpen.value = !isOpen.value;
  }
}

function getLabel(e: any) {
  if (!e) return "";
  return (
    e.label || e.text || e.displayName || e.name || e.description || e || e[SelectLabelSymbol] || ""
  );
}

function setOption(e: ItemOption | any) {
  // if is the value, just ignore
  if (selectedOption.value && selectedOption.value.value === value.value && value.value === e) {
    if (e === null || e === undefined) {
      unsetOption();
    }
    return;
  }

  if (e != null) {
    if (props.options) {
      let found = false;
      const p = e;

      // find by instance

      // instance object
      const ins = isObject(e) ? ("value" in e ? e.value : e) : e;
      for (const it of props.options) {
        if ("items" in it) {
          const t = it.items.find((i) => i.value === ins);
          if (t) {
            found = true;
            e = t;
            break;
          }
        } else if (it.value === ins) {
          found = true;
          e = it;
        }
      }
      if (!found) {
        const str = isObject(e) ? JSON.stringify("value" in e ? e.value : e) : null;
        for (const it of props.options) {
          if ("items" in it) {
            const t = it.items.find((i) => (str ? str === JSON.stringify(i.value) : i.value === e));
            if (t) {
              found = true;
              e = t;
              break;
            }
          } else if (str ? JSON.stringify(it.value) === str : it.value === e) {
            found = true;
            e = it;
          }
          if (e?.value) break;
          if (found) break;
        }
      }
      if (!found) {
        // if the value is empty and no options matched it should not set the value
        if (!e) {
          unsetOption();
          return;
        }
        // just believe the value the user passed in
        if (e === p) {
          const keys = Object.keys(e);
          if (keys.length !== 2 || !keys.includes("label") || !keys.includes("value")) {
            e = {
              label: getLabel(e),
              value: e,
            };
          }
        }
      }
    }
  } else {
    unsetOption();
    return;
  }
  if (!isObject(e)) {
    unsetOption();
    return;
  }

  selectedOption.value = e as ItemOption;
  text.value = getLabel(e);
  value.value = e.value;
}

function unsetOption() {
  selectedOption.value = null;
  text.value = "";
  value.value = null;
}

const emitBlur = useDebounce(() => emit("blur"), 10);

useOnOutsidePress(
  computed(() => proxy.$el),
  () => {
    if (isOpen.value) {
      isOpen.value = false;
      emitBlur();
    }
  },
);

function onBlur(e: FocusEvent) {
  if (e.relatedTarget) {
    const target = e.relatedTarget as HTMLElement;

    if (proxy.$el.contains(target)) return;
  }

  // reset text
  if (text.value !== selectedOption.value?.label) {
    onClear();
  }

  focusedItemId.value = 0;
  focusedItem.value = selectableItems.value[0];

  isOpen.value = false;
  emitBlur();

  if (formTouched) {
    setTimeout(() => {
      const t = (touched.value = touched.value || value.value !== originalValue);

      if (!proxy?.$.isUnmounted) {
        formTouched(t);
      }
    }, 50);
  }
}

function onClear() {
  if (isFetching.value) {
    lastSearchCancel?.();
    isFetching.value = false;
  }

  setOption(null);

  text.value = "";
  value.value = null;
  isOpen.value = false;
}

const open = () => (isOpen.value = true);
const focus = () => inputEl.value?.focus();

useEvent(
  computed(() => inputEl.value?.$el),
  "blur",
  onBlur,
  { capture: true },
);

useEvent(window, "mouseup", () => {
  isMousedown.value = false;
});

defineExpose({
  open,
  focus,

  onClear,

  isOpen: isOpen,

  isInput,
  text,
});

function handleInput() {
  isOpen.value = true;
}
</script>
<template>
  <MInput
    ref="inputEl"
    v-bind="$props"
    v-model="text"
    :class="['m-simple-select', isInput && 'use-input']"
    :for="id"
    :instructions="instructions"
    :autofocus="autofocus"
    :placeholder="placeholder"
    :clearable="clearable"
    :loading="isFetching"
    :aria-controls="menuId"
    :aria-owns="menuId"
    :aria-expanded="isOpen"
    :aria-activedescendant="focusedItemId"
    input-role="combobox"
    data-form-type="other"
    no-validation
    :readonly="$attrs.readonly || !isInput"
    :show-clear="undefined"
    :display-errors="displayErrors"
    @keydown="onKeydown"
    @click="onClick"
    @clear="onClear"
    @mousedown="isMousedown = true"
    @input="handleInput"
    @focus="
      () => {
        if (isMousedown === false) {
          inputEl?.selectText();
        }
      }
    "
  >
    <template v-if="$slots.prepend" #prepend>
      <slot name="prepend" />
    </template>
    <template #append>
      <div class="select-caret-icon">
        <i v-if="!isOpen" class="fa fa-caret-down" />
        <i v-else class="fa fa-caret-up" />
      </div>
    </template>

    <template #default="{ el }">
      <SimpleMenu
        v-if="filteredItems.length > 0 && $attrs.readonly !== true"
        :id="menuId"
        v-model="isOpen"
        :target="el"
        :limit-width-to-target="true"
        no-auto-open
        role="listbox"
        @keydown="onKeydown"
      >
        <SelectRenderList
          :focused-item="focusedItem"
          :items="filteredItems"
          :value="value"
          :disabled="isFetching"
          :data-testid="$attrs['data-testid']"
          @select="onSelect"
        >
        </SelectRenderList>
      </SimpleMenu>
    </template>
  </MInput>
</template>
<style lang="scss">
.m-simple-select {
  &.use-input input {
    // quasar has aria-owns changing the cursor to pointer
    cursor: text;
  }

  .select--icon {
    transition: transform 0.28s;
  }

  * + .select--icon {
    padding-right: 4px;
    padding-left: 4px;
  }
}
.select-caret-icon {
  padding-left: 6px;
  padding-right: 6px;
}
</style>
