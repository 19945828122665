<script setup lang="ts">
import { GroupOption, ItemOption } from "../MSelect";
import MLayoutStack from "../../MLayoutStack";
import { nextTick, watch } from "vue";

const props = defineProps<{
  focusedItem: any;

  items: Array<GroupOption | ItemOption>;

  value: any;

  disabled?: boolean;
}>();

const emit = defineEmits({
  select: (_options: ItemOption) => true,
});

function onSelect(option: ItemOption) {
  emit("select", option);
}

watch(
  () => props.focusedItem,
  (v) =>
    nextTick(() => {
      document
        .getElementById(`select-item-${stringifyValues(v.value)}`)
        ?.scrollIntoView({ block: "end" });
    }),
);

function stringifyValues(value: any) {
  return JSON.stringify(value);
}
</script>
<template>
  <div class="select-render-list">
    <template v-for="(item, k) in items" :key="`item-outer-${k}${item.title}${item.label}`">
      <!--   Grouped options with a title   -->
      <template v-if="'title' in item">
        <div :class="['simple-select-item', 'title', disabled]" role="heading">
          {{ item.title }}
        </div>
        <m-layout-stack
          v-for="(subItem, si) in item.items"
          :id="`select-item-${stringifyValues(subItem.value)}`"
          :key="`item-inner-${subItem.label}-${si}`"
          :class="{
            'simple-select-item': true,
            selected: subItem.value === value,
            focused: subItem === focusedItem,
            title: 'title' in subItem,
            disabled,
          }"
          horizontal
          justify-between
          @click="onSelect(subItem)"
        >
          <div
            :class="[
              subItem.color && `color-${subItem.color}`,
              subItem.description && 'm-select-item-with-description',
            ]"
          >
            <div v-if="subItem.description">
              <p class="m-select-item-label">
                {{ subItem.label }}
              </p>
              <template v-if="Array.isArray(subItem.description)">
                <p
                  v-for="d in subItem.description"
                  :key="`${subItem.label}.${d}`"
                  class="m-select-item-description"
                >
                  {{ d }}
                </p>
              </template>

              <p v-else class="m-select-item-description">
                {{ subItem.description }}
              </p>
            </div>
            <template v-else>
              {{ subItem.label }}
            </template>
          </div>

          <div v-if="subItem.value === value">
            <i class="fa-light fa-check" />
          </div>
        </m-layout-stack>
      </template>
      <!--   Simple list of items   -->
      <m-layout-stack
        v-else
        :id="`select-item-${stringifyValues(item.value)}`"
        :class="{
          'simple-select-item': true,
          selected: item.value === value,
          focused: item === focusedItem,
          title: 'title' in item,
        }"
        horizontal
        justify-between
        @click="onSelect(item)"
      >
        <div
          :class="[
            item.color && `color-${item.color}`,
            item.description && 'm-select-item-with-description',
          ]"
        >
          <div v-if="item.description">
            <p class="m-select-item-label">
              {{ item.label }}
            </p>
            <template v-if="Array.isArray(item.description)">
              <p
                v-for="d in item.description"
                :key="`${item.label}.${d}`"
                class="m-select-item-description"
              >
                {{ d }}
              </p>
            </template>

            <p v-else class="m-select-item-description">
              {{ item.description }}
            </p>
          </div>
          <template v-else>
            {{ item.label }}
          </template>
        </div>

        <div v-if="item.value === value">
          <i class="fa-light fa-check" />
        </div>
      </m-layout-stack>
    </template>
  </div>
</template>
<style>
.select-render-list {
  .title {
    font-family: Arial, sans-serif;
    font-style: normal;
    font-size: 14px;

    font-weight: bold;
    color: var(--text-color);
    padding-left: var(--gap-4);
    margin-top: var(--gap-2);
  }
  .title:hover {
    background-color: white !important;
    cursor: default;
  }

  .simple-select-item {
    padding: 8px 16px;
    cursor: pointer;
  }

  .simple-select-item.focused {
    background-color: var(--grey-light);
  }

  .disabled {
    color: var(--text-color-lightest);
  }

  .simple-select-item:hover {
    background-color: var(--grey-light);
  }

  &:has(.m-simple-select--group-title) .q-item:not(.m-simple-select--group-title) {
    padding-left: var(--gap-6);
  }
}
</style>
