<template>
  <m-card>
    <m-layout-stack
      :class="{ 'marked-incorrect': inboundDocument.isMarkedIncorrect }"
      data-testid="document-journal-presenter"
    >
      <m-layout-stack flex horizontal>
        <div v-if="inboundDocument.documentTypeLabel !== 'Document'" class="flex-auto">
          <b>Document:</b> {{ inboundDocument.documentTypeLabel }}
          <span v-if="clinicalAndOrganisation"> • {{ clinicalAndOrganisation }} </span>
        </div>
        <div v-else class="flex-auto">
          <b>Document</b>
          <span v-if="clinicalAndOrganisation"> • {{ clinicalAndOrganisation }} </span>
        </div>
        <i v-if="inboundDocument.isRetrospectivelyAmended" style="white-space: nowrap">{{
          formatRetrospectivelyAmendedLabel
        }}</i>
        <m-badge v-if="inboundDocument.hasPlaceholderFile" text="GP2GP Placeholder File" gray />
        <m-badge
          v-if="inboundDocument.filingStatus === 'awaiting-filing'"
          text="Awaiting Filing"
          amber
          class="status-badge"
          data-testid="awaiting-filing-badge"
        />
        <m-action
          v-if="!noAction"
          :model-value="{ label: 'View document', click: openDocumentModal }"
        />
      </m-layout-stack>
    </m-layout-stack>
    <div v-if="showBadges">
      <span class="secondary-text">
        {{ getSecondaryText }}
      </span>
    </div>
    <m-separator
      v-if="inboundDocument.additionalInformation || inboundDocument.careRecordElements.length"
      class="q-ma-xs"
    />

    <span v-if="inboundDocument.additionalInformation">
      {{ inboundDocument.additionalInformation }}
    </span>

    <m-labeled-text v-if="inboundDocument.linkedProblemLabel">
      <strong class="care-record-presenter">{{ inboundDocument.linkedProblemLabel }}</strong>
    </m-labeled-text>

    <m-list>
      <ConsultationCareRecordElements
        :items="inboundDocument.careRecordElements"
        :read-only="entry.isDraft !== undefined ? entry.isDraft : false"
        :context-hidden-fps="inboundDocument.hiddenFromPatientFacingServices"
        :context-confidential-ftp="inboundDocument.confidentialFromThirdParties"
      />
    </m-list>
  </m-card>
</template>
<script setup lang="ts">
import { computed, ref } from "vue";
import { PatientJournalRecord } from "../types";
import { openModal } from "../../../../composables/dialog/drawer";
import ConsultationCareRecordElements from "../elements/consultation-topic/ConsultationCareRecordElements.vue";

const props = defineProps<{
  entry: PatientJournalRecord;
  readOnly: boolean;
  noAction?: boolean;
}>();

const inboundDocument = ref(props.entry.data);

const formatRetrospectivelyAmendedLabel = computed(() => {
  let label = [];
  if (inboundDocument.value.isRetrospectivelyAmended) {
    label.push("Edited");
  }

  return label.join(" • ");
});

const showBadges = computed(() => {
  return (
    inboundDocument.value.hiddenFromPatientFacingServices ||
    inboundDocument.value.confidentialFromThirdParties ||
    inboundDocument.value.linkedProblems.length ||
    inboundDocument.value.hasPlaceholderFile
  );
});

const getSecondaryText = computed(() => {
  let textArray = [];
  if (getConfidentialityText.value) {
    textArray.push(getConfidentialityText.value);
  }
  if (getLinkedProblemsText.value) {
    textArray.push(getLinkedProblemsText.value);
  }
  return textArray.join(" • ");
});

const getConfidentialityText = computed(() => {
  const textArray = [];
  if (inboundDocument.value.hiddenFromPatientFacingServices) {
    textArray.push("Redacted from Patient Online Access");
  }
  if (inboundDocument.value.confidentialFromThirdParties) {
    textArray.push("Confidential from Third Parties");
  }
  return textArray.join(" • ");
});

const getLinkedProblemsText = computed(() => {
  const problemsArray = inboundDocument.value.linkedProblems || [];
  const problemDescriptions = problemsArray
    .filter((x) => x.problemCodeDescription)
    .map((x) => x.problemCodeDescription);

  return problemDescriptions.length ? `Linked Problems: ${problemDescriptions.join(", ")}` : "";
});

const clinicalAndOrganisation = computed(() => {
  const textAsArray = [
    inboundDocument.value.title,
    inboundDocument.value.clinicalSpeciality,
    inboundDocument.value.organisationName,
  ];
  const text = textAsArray.filter((x) => x).join(" • ");
  return text === "" ? false : text;
});

function openDocumentModal() {
  openModal(`/clinical/document/modals/preview/${inboundDocument.value.id}`, {
    close: true,
  });
}
</script>
<style>
.card-description {
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #68778d;
}
.status-badge {
  align-self: start;
}
</style>
