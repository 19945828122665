<template>
  <div
    :class="[
      {
        clickable: !readOnly,
        'marked-incorrect': item.isMarkedIncorrect,
      },
    ]"
  >
    <m-layout-stack horizontal>
      <div class="flex-auto">
        <span>Referral: {{ item.referralCode }}</span>
        <span v-if="item.priority"> ({{ item.priority }})</span>
        <span v-if="item.referralProvider"> • {{ item.referralProvider }}</span>
        <span v-if="item.referralDateIsNotEncounterDate || item.referralDateIsNotDocumentDate">
          • {{ item.referralDate }}</span
        >
        <span v-if="item.cancelledStatusLabel"> • {{ item.cancelledStatusLabel }}</span>
        <span v-if="item.isExplicitlyIncludedInSCR"> • Explicitly included in SCR</span>
        <span v-if="item.additionalInformation"> • {{ item.additionalInformation }}</span>
      </div>
      <i v-if="item.isRetrospectivelyAmended" style="white-space: nowrap">{{
        formatRetrospectivelyAmendedLabel
      }}</i>
      <m-badge v-if="item.incompleteStatusLabel" amber :text="item.incompleteStatusLabel" />
    </m-layout-stack>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

export type OutboundReferralJournalItem = {
  isExplicitlyIncludedInSCR: boolean;
  linkedProblems: { problemCodeDescription: string }[];
  confidentialFromThirdParties: boolean;
  hiddenFromPatientFacingServices: boolean;
  incompleteStatusLabel: string;
  cancelledStatusLabel: string;
  referringClinician: string;
  priority: string;
  referralCode: string;
  isMarkedIncorrect: boolean;
  isRetrospectivelyAmended: boolean;
};

const props = defineProps<{
  item: OutboundReferralJournalItem;
  readOnly: boolean;
  contextHiddenFromPatientFacingServices?: boolean;
  contextConfidentialFromThirdPartyServices?: boolean;
}>();

const formatRetrospectivelyAmendedLabel = computed(() => {
  let label = [];
  if (props.item.isRetrospectivelyAmended) {
    label.push("Edited");
  }

  return label.join(" • ");
});
</script>
