<template>
  <div
    :class="[
      {
        clickable: !readOnly,
        'marked-incorrect': item.isMarkedIncorrect,
      },
    ]"
  >
    <m-layout-stack horizontal>
      <div class="flex-auto">
        <span v-if="item.displayStatus === 'Planned'">
          Future action/recall: {{ item.clinicalCode }} • Planned for {{ item.plannedDateRange }}
        </span>
        <span v-else>
          Future action/recall: {{ item.clinicalCode }} • Due
          {{ item.plannedDateRange }}
        </span>
        <span v-if="item.displayStatus === 'Completed' || item.displayStatus === 'Cancelled'">
          • {{ item.displayStatus }}
        </span>
        <span v-if="getConfidentialityText"> • {{ getConfidentialityText }} </span>
        <span v-if="item.details"> • {{ item.details }} </span>
      </div>
      <i v-if="item.isRetrospectivelyAmended" style="white-space: nowrap">{{
        formatRetrospectivelyAmendedLabel
      }}</i>
      <m-badge
        v-if="!item.isMarkedIncorrect && item.displayStatus === 'Overdue'"
        amber
        :text="item.displayStatus"
      />
    </m-layout-stack>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

export type FutureActionJournalItem = {
  plannedDateRange?: string;
  clinicalCode?: string;
  displayStatus?: string;
  confidentialFromThirdParties?: boolean;
  isMarkedIncorrect?: boolean;
  isRetrospectivelyAmended?: boolean;
  linkedProblems?: { problemCodeDescription: string }[];
};

const props = defineProps<{
  item: FutureActionJournalItem;
  readOnly: boolean;
  contextConfidentialFromThirdPartyServices?: boolean;
}>();

const formatRetrospectivelyAmendedLabel = computed(() => {
  let label = [];
  if (props.item.isRetrospectivelyAmended) {
    label.push("Edited");
  }

  return label.join(" • ");
});

const getConfidentialityText = computed(() => {
  const textArray = [];

  if (props.item.confidentialFromThirdParties && !props.contextConfidentialFromThirdPartyServices) {
    textArray.push("Confidential from Third Parties");
  }
  return textArray.length ? textArray.join(" • ") : "";
});
</script>
