import deepmerge from "deepmerge";
import { defineStore } from "pinia";
import { computed, reactive, readonly, ref, toRef, watch } from "vue";
import { isObject, usePromiseLazy } from "vue-composable";
import axios from "../../../utils/axios";
import { CreatePrescriptionQueryResult, PrescribeAgainQueryResult } from "./types";
import {
  getDosageCalculation,
  getDosageSuggestion,
  getIssueQuantity,
  getMetaInformation,
  getSafetyChecks,
} from "./api";
import {
  CalculatePredefinedDosagesQueryResult,
  SearchPredefinedDosagesQueryResult,
} from "./api/types";
import { SortOrder } from "../../../components/medicus/consultation/MConsultationTopicHeading/types";

function prescriptionMerge<T>(a: T, b: T): T {
  return deepmerge(a, b, {
    arrayMerge: (_, source) =>
      source.map((a) => (isObject(a) ? (prescriptionMerge({}, a) as any) : a)),
  });
}

if (import.meta.vitest) {
  const { describe, test, expect } = import.meta.vitest;

  describe("prescription deepmerge", () => {
    test("should clone", () => {
      const obj = { test: 1 };
      const secondList = { other: [obj] };

      const list = [obj, secondList] as const;

      const o = {
        list,
        secondList,
        obj,
      };

      const merged = prescriptionMerge({} as any, o);
      expect(merged).toMatchObject(o);

      expect(o).not.toBe(merged);
      expect(merged.list === list).toBe(false);
      expect(merged.list).not.toBe(list);
      expect(merged.secondList).not.toBe(secondList);
      expect(merged.obj).not.toBe(obj);

      expect(merged.list[0]).not.toBe(obj);
      expect(merged.list[1]).not.toBe(secondList);

      expect(merged.list[1].other[0]).not.toBe(obj);
    });

    test("should replace array", () => {
      expect(
        prescriptionMerge(
          {
            list: [1, 2, 3],
          },
          {
            list: [4],
          },
        ),
      ).toMatchObject({
        list: [4],
      });
    });
  });
}

type DrugOrDevice = {
  description: string;
  itemCode: string;
  vmp?: Object;
};

type NewProblemCode = {
  conceptId: string;
  description: string;
  descriptionId: string;
};

type CreateTopicHeadingIntent = {
  consultationTopicId: string;
  defaultTopicHeadingId: string;
};

export default defineStore("prescription", () => {
  // init
  const state = reactive<CreatePrescriptionQueryResult | PrescribeAgainQueryResult>({} as any);

  const drugOrDevice = ref<DrugOrDevice | null>(null);
  const prescriptionFormType = ref(null);
  const isInitialising = ref(true);
  const isReauthorising = ref(null);
  const shouldPreserveDosageOnFirstUpdate = ref(false);
  const selectedPredefinedDosage = ref<Object | string | null>(null);

  const originalDrugOrDevice = ref<DrugOrDevice | null>(null);
  const originalPrescriptionType = ref<string | null>(null);
  const originalDosageInstructionText = ref<string | null>(null);

  // const createNewProblem = ref<boolean>(false);

  const dosage = ref<
    SearchPredefinedDosagesQueryResult | CalculatePredefinedDosagesQueryResult | null
  >(null);

  const patientId = ref<string>();

  async function initByPatient(
    id: string,
    contextId?: string,
    contextType?: string,
    taskId?: string,
    taskType?: string,
  ) {
    patientId.value = id;
    const { data } = await axios.get<CreatePrescriptionQueryResult>(
      `/clinical/data/prescription/modal/create-prescription/${id}`,
      {
        params: {
          contextId,
          contextType,
          taskId,
          taskType,
        },
      },
    );

    form.contextId = contextId;
    form.contextType = contextType;

    initState(data);
    isInitialising.value = false;
  }

  async function initById(
    id: string,
    type: "prescribe-again" | "re-authorise" | "modify" | "issue-one-off" | "indeterminate",
  ) {
    isReauthorising.value = ["re-authorise", "prescribe-again"].indexOf(type) !== -1;
    shouldPreserveDosageOnFirstUpdate.value = true;
    selectedPredefinedDosage.value = "free-text";
    prescriptionFormType.value = type;

    const resourceType = "indeterminate" === type ? "re-authorise" : type;

    const { data } = await axios.get<PrescribeAgainQueryResult>(
      `/clinical/data/prescription/${resourceType}/${id}`,
    );

    patientId.value = data.patientId;
    initState(data);

    drugOrDevice.value = data.drugOrDevice;
    originalDrugOrDevice.value = data.drugOrDevice;
    originalPrescriptionType.value = data.prescription.prescriptionType;
    originalDosageInstructionText.value = data.prescription.dosageInstruction.dosageText;

    // needed for watch to trigger
    Promise.resolve();

    // waiting if the checks from the watch are still running
    await resetDrugAndDevice.promise.value;

    if (drugOrDevice.value) {
      // wait for all the promises to be done
      Promise.all([
        safetyChecksPromise.value,
        dosageSuggestionPromise.value,
        metaInformationPromise.value,

        dosageCalculations.promise.value,
        issueQuantity.promise.value,
      ]);
    }

    initState(data);

    Object.keys(form).forEach((key) => (key in data ? ((form as any)[key] = data[key]) : null));

    // this is needed to make sure the prescription is correct and not defaulted
    Object.assign(state.prescription, data.prescription);

    if (data.linkedProblemIds) {
      form.linkedProblemIds = Array.from(data.linkedProblemIds);
    }

    // TODO remove, this should come from the backend
    state.prescription.category = metaInformation.value?.category;
    isInitialising.value = false;
  }

  // default state
  function initState(data: CreatePrescriptionQueryResult) {
    Object.assign(state, data);
  }

  function resetPrescriptionStructure() {
    const dispenserInstructions = state.prescription.dispenserInstructions;
    const endorsements = state.prescription.prescriberEndorsements;

    if (true === isReauthorising.value) {
      return;
    }

    const preAuthorisedBy = state.prescription.authorisedBy;

    // @ts-expect-error just nuke prescription
    state.prescription = prescriptionMerge({}, state.defaultPrescriptionStructure);
    resetDosageInstruction();
    resetIssueQuantity();

    state.prescription.dispenserInstructions = dispenserInstructions;
    state.prescription.prescriberEndorsements = endorsements;

    if (null === preAuthorisedBy) {
      state.prescription.authorisedBy = null;
    }
  }

  function resetDosageInstruction() {
    Object.assign(
      state.prescription.dosageInstruction,
      prescriptionMerge(
        state.prescription.dosageInstruction,
        state.defaultDosageInstructionStructure,
      ),
    );
  }

  function resetIssueQuantity() {
    state.prescription.issueQuantity.snomedCtCode = null;
    state.prescription.issueQuantity.value = null;
  }

  function resetAllowablePrescriberEndorsements() {
    state.allowablePrescriberEndorsements = state.minimumAllowablePrescriberEndorsements;
  }

  function resetMetaInformation() {
    state.bnfcUrl = null;
    state.bnfUrl = null;
    state.preventManualIssueQuantityEntry = false;
    state.isInNurseFormulary = false;
  }

  // end reset

  const {
    exec: refreshSafetyChecks,
    result: safetyChecks,
    loading: fetchingSafetyChecks,
    promise: safetyChecksPromise,
  } = usePromiseLazy(getSafetyChecks);

  const {
    exec: refreshDosageSuggestion,
    result: dosageSuggestion,
    loading: fetchingDosageSuggestion,
    promise: dosageSuggestionPromise,
  } = usePromiseLazy(getDosageSuggestion);

  const {
    exec: refreshMetaInformation,
    result: metaInformation,
    loading: fetchingMetaInformation,
    promise: metaInformationPromise,
  } = usePromiseLazy(getMetaInformation);

  const dosageCalculations = usePromiseLazy(getDosageCalculation);

  const issueQuantity = usePromiseLazy(getIssueQuantity);

  const resetDrugAndDevice = usePromiseLazy(
    async (
      drugOrDevice: {
        description: string;
        itemCode: string;
      } | null,
    ) => {
      state.confirmationAcknowledgedAndProceed = false;
      state.confirmationAcknowledgedAndProceedSafetyChecks = false;

      if (true !== isReauthorising.value) {
        // TODO create a reset function
        resetPrescriptionStructure();
        resetAllowablePrescriberEndorsements();

        if (false === shouldPreserveDosageOnFirstUpdate.value) {
          dosageSuggestion.value = null;
          dosage.value = null;

          dosageCalculations.result.value = null;
          issueQuantity.result.value = null;
          selectedPredefinedDosage.value = null;
        }

        shouldPreserveDosageOnFirstUpdate.value = false;

        state.prescription.category = null;
        // if there's already endorsements, we clone them
        state.prescription.prescriberEndorsements = state.prescription.prescriberEndorsements
          ? Array.from(state.prescription.prescriberEndorsements)
          : null;
        state.prescription.productName = null;
      }

      resetMetaInformation();
      metaInformation.value = null;
      safetyChecks.value = null;

      // createNewProblem.value = false;
      // newProblemCode.value = null;

      if (drugOrDevice) {
        return refreshDrugOrDevice(drugOrDevice);
      }
    },
  );

  function refreshDrugOrDevice(drugOrDevice: DrugOrDevice) {
    const { description, itemCode, vmpProductCode } = drugOrDevice;

    state.prescription.productDescription = description;
    state.prescription.productCode = itemCode;
    state.prescription.vmpProductCode = vmpProductCode;

    return Promise.all([
      refreshSafetyChecks(patientId.value!, itemCode),
      refreshMetaInformation(itemCode),
      state.patientDOB
        ? refreshDosageSuggestion(patientId.value!, itemCode)
        : new Promise<void>((res) => {
            prescription.value.dosageInstruction.useManualDosageText = true;
            res();
          }),
    ]);
  }

  watch(drugOrDevice, resetDrugAndDevice.exec);

  watch(metaInformation, (meta) => {
    if (!meta) return;

    state.allowablePrescriberEndorsements = meta.allowablePrescriberEndorsements;

    state.prescription.allowedPrescriptionTypes = meta.allowedPrescriptionTypes;
    state.prescription.category = meta.category;

    state.bnfUrl = meta.bnfUrl;
    state.bnfcUrl = meta.bnfcUrl;
    state.isInNurseFormulary = meta.isInNurseFormulary;

    if (false === meta.isInNurseFormulary) {
      let resetAuthorisedBy = true;
      state.prescribingStaffExcludingCommunityNurses.forEach((item) => {
        if (item.value === state.prescription.authorisedBy) {
          resetAuthorisedBy = false;
        }
      });

      if (resetAuthorisedBy) {
        state.prescription.authorisedBy = null;
      }
    }

    if (meta.restrictedIssueQuantityUnitsOfMeasure?.length == 1) {
      state.prescription.issueQuantity.snomedCtCode = {
        conceptId: meta.restrictedIssueQuantityUnitsOfMeasure[0].value.conceptId,
        description: meta.restrictedIssueQuantityUnitsOfMeasure[0].value.description,
        descriptionId: meta.restrictedIssueQuantityUnitsOfMeasure[0].value.descriptionId,
      };

      state.preventManualIssueQuantityEntry = true;
    } else {
      state.preventManualIssueQuantityEntry = false;
      state.prescription.issueQuantity.snomedCtCode = null;
    }

    const prescriberEndorsementsThatShouldBePreSelected =
      state.allowablePrescriberEndorsements?.filter(
        (e) => e.selected === true || state.prescription.prescriberEndorsements?.includes(e.value),
      );

    if (prescriberEndorsementsThatShouldBePreSelected?.length > 0) {
      state.prescription.prescriberEndorsements = prescriberEndorsementsThatShouldBePreSelected.map(
        (e) => e.value,
      );
    }
  });

  // getters
  const prescription = toRef(state, "prescription");

  const loadingChecks = computed(
    () =>
      fetchingSafetyChecks.value || fetchingDosageSuggestion.value || fetchingMetaInformation.value,
  );

  // form used to send to the server
  const form = reactive({
    // drugOrDevice,
    prescription,
    patientId,
    confidentialFromThirdParties: toRef(state, "confidentialFromThirdParties"),
    hiddenFromPatientFacingServices: toRef(state, "hiddenFromPatientFacingServices"),
    workflowIdentifier: toRef(state, "workflowIdentifier"),
    skipIssuing: undefined as boolean | false,
    linkedProblemIds: [] as string[],
    // optional
    contextId: undefined as string | undefined,
    contextType: undefined as string | undefined,
    originalPrescriptionId: undefined as string | undefined,

    action: undefined as string | undefined,

    newLinkedProblem: undefined as NewProblemCode | undefined,

    createTopicHeadingIntent: undefined as CreateTopicHeadingIntent | undefined,
  });

  // methods

  // dosage

  async function commitDosage(
    predefinedDosage:
      | SearchPredefinedDosagesQueryResult
      | CalculatePredefinedDosagesQueryResult
      | null,
  ) {
    const prescription = state.prescription;
    const { dosageInstruction } = prescription;
    if (!predefinedDosage) {
      dosageInstruction.useManualDosageText = true;
      return;
    }
    dosageInstruction.dosageText = predefinedDosage.dosageTextToPersist;

    if (predefinedDosage.additionalInstructions) {
      dosageInstruction.additionalInstructions = predefinedDosage.additionalInstructions;
    }

    if (predefinedDosage.shouldUseStructuredDosage === false) {
      dosageInstruction.useManualDosageText = true;
    } else {
      dosageInstruction.useManualDosageText = false;

      if (predefinedDosage.doseQuantityValue) {
        dosageInstruction.dose.value = predefinedDosage.doseQuantityValue;

        if (predefinedDosage.doseUnitSnomedConceptID) {
          dosageInstruction.dose.snomedCtCode = {
            conceptId: predefinedDosage.doseUnitSnomedConceptID,
            description: predefinedDosage.doseUnitLabel,
            descriptionId: null,
          };
        }
      } else if (
        "doseQuantityRange" in predefinedDosage &&
        predefinedDosage.doseQuantityRange?.minimum?.value &&
        predefinedDosage.doseQuantityRange?.maximum?.value
      ) {
        dosageInstruction.dose.minimum.value = predefinedDosage.doseQuantityRange.minimum.value;
        dosageInstruction.dose.maximum.value = predefinedDosage.doseQuantityRange.maximum.value;

        if (predefinedDosage.doseQuantityRange.minimum.doseUnitSnomedConceptID) {
          dosageInstruction.dose.minimum.snomedCtCode = {
            conceptId: predefinedDosage.doseQuantityRange.minimum.doseUnitSnomedConceptID,
            description: predefinedDosage.doseQuantityRange.minimum.doseUnitLabel,
            descriptionId: null,
          };
        }

        if (predefinedDosage.doseQuantityRange.maximum.doseUnitSnomedConceptID) {
          dosageInstruction.dose.maximum.snomedCtCode = {
            conceptId: predefinedDosage.doseQuantityRange.maximum.doseUnitSnomedConceptID,
            description: predefinedDosage.doseQuantityRange.maximum.doseUnitLabel,
            descriptionId: null,
          };
        }
      }

      if (
        "doseQuantityMaximumLimit" in predefinedDosage &&
        predefinedDosage.doseQuantityMaximumLimit
      ) {
        if (
          predefinedDosage.doseQuantityMaximumLimit.period?.value &&
          predefinedDosage.doseQuantityMaximumLimit.period?.unit
        ) {
          dosageInstruction.doseQuantityMaximumLimit.period.value =
            predefinedDosage.doseQuantityMaximumLimit.period.value;
          dosageInstruction.doseQuantityMaximumLimit.period.unit =
            predefinedDosage.doseQuantityMaximumLimit.period.unit;
        }

        if (
          predefinedDosage.doseQuantityMaximumLimit.quantity &&
          predefinedDosage.doseQuantityMaximumLimit.quantity.value &&
          predefinedDosage.doseQuantityMaximumLimit.quantity.snomedCtCode &&
          predefinedDosage.doseQuantityMaximumLimit.quantity.snomedCtCode.conceptId &&
          predefinedDosage.doseQuantityMaximumLimit.quantity.snomedCtCode.description
        ) {
          dosageInstruction.doseQuantityMaximumLimit.quantity.value =
            predefinedDosage.doseQuantityMaximumLimit.quantity.value;
          dosageInstruction.doseQuantityMaximumLimit.quantity.snomedCtCode = {
            conceptId: predefinedDosage.doseQuantityMaximumLimit.quantity.snomedCtCode.conceptId,
            description:
              predefinedDosage.doseQuantityMaximumLimit.quantity.snomedCtCode.description,
            descriptionId:
              predefinedDosage.doseQuantityMaximumLimit.quantity.snomedCtCode?.descriptionId,
          };
        }
      }

      dosageInstruction.route = {
        conceptId: predefinedDosage.routeSnomedConceptID,
        description: predefinedDosage.routeDescription,
        descriptionId: null,
      };

      dosageInstruction.method = {
        conceptId: predefinedDosage.methodSnomedConceptID,
        description: predefinedDosage.methodDescription,
        descriptionId: null,
      };

      dosageInstruction.site = {
        conceptId: predefinedDosage.siteSnomedConceptID,
        description: predefinedDosage.siteDescription,
        descriptionId: null,
      };

      dosageInstruction.frequency = predefinedDosage.frequencyValue;
      if (!predefinedDosage.frequencyValue) {
        dosageInstruction.asNeeded = true;
      }

      if (dosageInstruction.period) {
        dosageInstruction.period.value = predefinedDosage.periodValue;
        dosageInstruction.period.unit = predefinedDosage.periodUnitSnomedCode;
      } else {
        dosageInstruction.period = {
          value: predefinedDosage.periodValue,
          unit: predefinedDosage.periodUnitSnomedCode,
        };
      }
      dosageInstruction.maximumPeriodInDays = predefinedDosage.maximumPeriodInDays;
    }

    await calculateIssueQuantity();
  }

  async function calculateIssueQuantity() {
    if (state.prescription?.dosageInstruction?.useManualDosageText) {
      return;
    }
    const { prescription } = form;

    const issueQuantityUnit = prescription.issueQuantity?.snomedCtCode?.conceptId;
    const doseUnit = prescription.dosageInstruction.dose?.snomedCtCode?.conceptId;
    const productCode = prescription.productCode;

    const data = await issueQuantity.exec(prescription, true);
    if (
      data?.issueQuantity &&
      // if we didn't change the units
      issueQuantityUnit === prescription.issueQuantity?.snomedCtCode?.conceptId &&
      doseUnit === prescription.dosageInstruction.dose?.snomedCtCode?.conceptId &&
      productCode === prescription.productCode
    ) {
      prescription.issueQuantity.value = data.issueQuantity;
    }
  }

  // form

  watch(
    [() => state.prescription?.prescriberEndorsements, () => state.allowablePrescriberEndorsements],
    ([endorsements, allowed]) => {
      if (!endorsements || !allowed) return;

      // loop back through the allowed endorsements and remove what's not allowed
      for (let i = endorsements.length - 1; i >= 0; i--) {
        if (!allowed.find((a) => a.value === endorsements[i])) {
          endorsements.splice(i, 1);
        }
      }
    },
    {
      flush: "post",
      deep: false,
    },
  );

  watch(
    [() => form.prescription?.prescriptionType, () => form.prescription?.allowedPrescriptionTypes],
    ([t, allowed], [pt]) => {
      // if (t && allowed?.length && !allowed.includes(t)) {
      if (t && allowed?.length && !allowed.find((x) => x.value === t)) {
        form.prescription.prescriptionType = undefined;
        return;
      }
      // if prescription type wasn't changed
      if (t === pt) return;
      if (t === "repeat" || t === "repeat-dispensing") return;
      form.prescription.numberOfIssues = 1;
    },
    {
      flush: "post",
      deep: false,
    },
  );

  watch(
    () => form.prescription?.expectedDaysSupply,
    async () => {
      await calculateIssueQuantity();
    },
  );

  // default unitOfMeasures
  const unitOfMeasures = computed(
    () => metaInformation.value?.restrictedIssueQuantityUnitsOfMeasure ?? state.unitsOfMeasure,
  );

  const sortOrder = ref<SortOrder | null>(null);
  const sortOrderHash = ref<string | null>(null);
  const newPrescriptionId = ref<string | null>(null);

  const computedCountOfDangerTypeSafetyChecks = ref(0);
  watch([() => loadingChecks.value, () => safetyChecks.value], () => {
    if (true === loadingChecks.value || !safetyChecks.value) {
      computedCountOfDangerTypeSafetyChecks.value = 0;

      return;
    }

    computedCountOfDangerTypeSafetyChecks.value = (
      safetyChecks.value.sensitivity?.alerts || []
    ).length;
  });

  return {
    state: state,
    patientId: readonly(patientId),

    prescriptionFormType,
    originalPrescriptionType,
    originalDosageInstructionText,
    prescription,
    form,
    dosage,
    drugOrDevice,
    originalDrugOrDevice,
    unitOfMeasures,

    initByPatient,
    initById,

    loadingChecks,

    safetyChecks,
    dosageSuggestion,
    metaInformation,

    dosageCalculations,
    issueQuantity,

    commitDosage,

    resetPrescriptionStructure,

    sortOrder,
    sortOrderHash,
    newPrescriptionId,

    isInitialising,
    isReauthorising,
    selectedPredefinedDosage,
    computedCountOfDangerTypeSafetyChecks,
  };
});
