<script setup lang="ts">
import { Ref, computed, inject, provide, ref, watch } from "vue";

import MButton from "../MButton";
import MExpander from "../MExpander";
import MLabeledText from "../MLabeledText";
import MLayoutStack from "../MLayoutStack";
import MBadge from "../MBadge";
import MSeparator from "../MSeparator";

import { setInGroup } from "../../../composables/horizontalLabel";

import { isObject, usePromiseLazy } from "vue-composable";
import * as GLOBAL_API from "../../../api/medicus";
import { useTenant } from "../../../store";
import { formatAddress } from "../../../utils/address";
import { listen } from "../../../utils/bus";
import { formatDate, getAge } from "../../../utils/date";
import { randomID } from "../../../utils/helpers";
import { PropsOf } from "../../../utils/types";

const props = defineProps<{
  secondary?: boolean;
  patient?: Patient;
  patientId?: string;
  badges?: PropsOf<typeof MBadge>[];
  lightBackground?: boolean;
  blueAccent?: boolean;
  noBorder?: boolean;
  small?: boolean;
  banner?: boolean;
  disableLink?: boolean;
  open?: boolean;
  boxShadow?: boolean;
}>();

export interface Patient {
  id: string;
  preferredPronouns?: string;
  displayName: string;
  preferredName: string;
  dateOfBirth: string;
  age: string;
  nhsNumber?: string;
  genderIdentity: string;
  extras: Array<{ label: string; value: string; type?: string | "phone" }>;
  badges: PropsOf<typeof MBadge>[] | null;
  testPatient: boolean;
  namedGP?: string;
}

const tenant = useTenant();

setInGroup(ref(true));
provide("labelHorizontal", false);

const wrapper = ref<HTMLElement>(null);
const reloading = ref<boolean | null>(null);
const patientBanner = inject<Ref<Patient> | undefined>("patientBanner", undefined);

const cancelRequestId = randomID("patient-info-id");
const badges = ref<PropsOf<typeof MBadge>[] | null>(null);

const {
  result: fetchedPatient,
  exec,
  loading,
  error,
} = usePromiseLazy((patientId?: string) => {
  const el = wrapper.value;
  if (el && el.scrollHeight) {
    el.style.height = el.getBoundingClientRect().height + "px";
  }

  return patientId
    ? GLOBAL_API.load(`/patient/data/patient/patient-banner/${patientId}`, {
        cancelRequestId,
      }).then((x) => {
        if (el) el.style.height = null;
        return x.data;
      })
    : undefined;
});

const patient = computed(() => props.patient ?? patientBanner?.value ?? fetchedPatient.value);

const showInfo = ref(props.open);

function formatValue(v: string, type?: string) {
  switch (type) {
    case "address": {
      // @ts-expect-error
      return formatAddress(v);
    }
    default:
      return v;
  }
}

watch(() => props.patientId, exec, { immediate: true });

watch(
  () => patient.value,
  (newPatient) => {
    if (isObject(newPatient) && "badges" in newPatient && Array.isArray(newPatient.badges)) {
      badges.value = newPatient.badges;
    }
  },
);

listen("reloadData", () => {
  reloading.value = true;
  exec(props.patientId).then(() => {
    reloading.value = null;
  });
});
</script>

<template>
  <div
    v-if="!patientId || !error"
    ref="wrapper"
    :class="{
      'm-patient-banner': true,
      'encounter-patient': true,
      secondary: props.secondary,
      light: lightBackground,
      blueAccent: blueAccent,
      'no-border': noBorder,
      small,
      banner,
      'medicus-box-shadow': boxShadow,
      loading: loading,
    }"
    :inert="loading"
  >
    <div v-if="reloading === true || loading === false" class="encounter-patient-info">
      <m-expander
        v-model:expanded="showInfo"
        auto
        borderless
        dense
        transition-name="expand-medicus-fast"
      >
        <template #head="{ ariaButton }">
          <m-layout-stack class="first-row" horizontal>
            <m-layout-stack full no-gap>
              <div class="encounter-patient-info-top">
                <div class="left flex">
                  <m-button
                    v-if="props.secondary !== true && patient?.extras?.length"
                    class="more-info q-mr-sm"
                    v-bind="ariaButton"
                    :icon="showInfo ? 'fa-solid fa-chevron-up' : 'fa-solid fa-chevron-down'"
                    :label="`${
                      ariaButton['aria-expanded'] ? 'Collapse' : 'Expand'
                    } patient information`"
                    icon-only
                    ghost
                    @click="showInfo = !showInfo"
                  />
                  <h1 class="patient-name">
                    {{ patient ? patient.displayName : "Patient unknown" }}
                  </h1>
                </div>
                <div class="right">
                  <span v-if="patient?.dateOfBirth">
                    {{ formatDate(patient?.dateOfBirth) }} ({{
                      patient?.age || `${getAge(patient?.dateOfBirth)} years old`
                    }})</span
                  ><span v-else>Date of birth unknown</span>
                  <span v-if="tenant.isNHS && patient?.nhsNumber">
                    <picture>
                      <source media="(min-width:1024px)" srcset="/images/nhs/nhs-original.webp" />
                      <source media="(min-width:1024px)" srcset="/images/nhs/nhs-original.jpg" />
                      <source media="(min-width:800px)" srcset="/images/nhs/nhs-high.webp" />
                      <source media="(min-width:800px)" srcset="/images/nhs/nhs-high.jpg" />
                      <source srcset="/images/nhs/nhs-low.webp" />
                      <source srcset="/images/nhs/nhs-low.jpg" />
                      <img src="/images/nhs/nhs-low.jpg" style="width: 30px" alt="NHS number" />
                    </picture>
                    {{ patient?.nhsNumber }}
                  </span>
                  <span v-else-if="tenant.isNHS">NHS no unknown</span>
                </div>
              </div>

              <m-layout-stack horizontal no-gap>
                <div
                  v-if="!small && props.secondary !== true"
                  class="encounter-patient-info-details"
                >
                  <span v-if="patient?.preferredName">
                    Preferred name: {{ patient?.preferredName }}</span
                  >
                  <span v-if="patient?.preferredPronouns">
                    Pronouns: {{ patient?.preferredPronouns }}</span
                  >
                </div>
              </m-layout-stack>
              <slot />
            </m-layout-stack>
            <!-- <m-layout-stack class="items-center self-center"> -->
            <!-- </m-layout-stack> -->
          </m-layout-stack>
        </template>
        <m-layout-stack v-if="props.secondary !== true" class="expander-content">
          <m-separator class="separator top" />
          <div class="patient-info">
            <m-labeled-text
              v-for="({ label, value, type }, i) in patient?.extras"
              :key="`patient-info-${label}-${i}`"
              :label="label"
              :text="formatValue(value, type)"
              horizontal
            />
          </div>
        </m-layout-stack>
        <template #footer>
          <div
            v-if="
              props.badges?.length > 0 ||
              patient?.badges?.length > 0 ||
              patient?.testPatient ||
              patient.namedGP
            "
            class="footer"
          >
            <m-separator class="separator footer" />
            <m-layout-stack horizontal justify-between center>
              <m-layout-stack class="badges" horizontal gap="1" wrap>
                <m-badge v-for="(badge, index) in badges" :key="index" v-bind="badge" />
              </m-layout-stack>

              <m-layout-stack v-if="patient.namedGP" horizontal end>
                <span>Named GP: {{ patient.namedGP }}</span>
              </m-layout-stack>
            </m-layout-stack>
          </div>
        </template>
      </m-expander>
    </div>
  </div>
</template>

<style lang="scss" scoped>
:where(.encounter-patient) {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  align-items: flex-start;
  height: auto;
  background: var(--grey-lightest);
  min-height: 33px;
  flex: 0 0 auto;

  &.no-border {
    border-style: none;
  }

  &.small {
    padding-bottom: 0;
  }

  &.banner {
    padding: 0.5rem 1rem;
    background: white;
    border-radius: 4px;
    border: 1px solid var(--grey-light);

    .encounter-patient-info {
      padding: 0 !important;
    }
  }
}

.badges {
  padding: 6px 0 6px 26px;
}

.right {
  margin-top: 2px;

  > *:not(:last-child) + *::before {
    content: "•";
    margin: 0 0.4rem;
  }
}

.second-row {
  padding: 0 10px 6px;

  .separator {
    margin-bottom: 6px;
  }
}

.secondary {
  .patient-name {
    font-size: 18px;
  }

  .badges {
    padding: 4px 0 2px;
  }
}
.expander-content {
  padding: 0 0 10px 16px;
}

.encounter-patient.light {
  background-color: #fff;
}

.encounter-patient.blueAccent {
  background: var(--theme-accent-blue);

  color: #fff;

  .patient-name {
    color: #fff;
  }
}

.encounter-patient-avatar {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  margin-right: 24px;
}

.encounter-patient-no-avatar {
  height: 50px;
  margin-right: 24px;
}

.encounter-patient-info {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.encounter-patient-info .encounter-patient-info-top {
  display: flex;
  align-items: center;
  justify-content: space-between;

  i {
    font-size: 1.2em !important;
  }
}

.labeled-text {
  margin-top: 0;
  margin-bottom: 0;
}

.encounter-patient-info-top > .patient-name {
  color: var(--text-color);

  font-family: Georgia;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;

  letter-spacing: 0.01em;
  line-height: 150%;
  /* identical to box height */

  display: flex;
  align-items: center;

  text-decoration: none;
}

a.patient-name:hover {
  text-decoration: underline;
  text-underline-offset: 3px;
}

:not(.secondary) {
  .encounter-patient-info-details:not(:empty) {
    padding-left: 26px;
    margin-bottom: 4px;
  }
}
.encounter-patient-info-details {
  display: flex;
  flex-direction: row;

  > span::after {
    content: "•";
    margin: 0 0.4rem;
  }

  span:last-of-type::after {
    content: "";
  }

  > span {
    height: 21px;
  }
}

picture {
  vertical-align: middle;
}

/* apply padding to all children */
.encounter-patient-info-details > div {
  padding: 5px 0;
  padding-right: 40px;
}

/* remove padding from popup-container */
.encounter-patient-info-details > .popup-container {
  cursor: pointer;
  padding-right: 5px;

  align-self: flex-end;
}

/* custom style popup */
.encounter-patient-info-details > .popup-container > .popup {
  width: 176px;

  padding: 0 8px;
  margin-left: 8px;

  /* center */
  top: -180%;
}

.encounter-patient-info-details > .popup-container > .popup > div {
  padding: 8px 8px;
}

.patient-action-button-group {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
}

.patient-action-button-group > button {
  margin: 8px 4px;

  width: 180px;
}

.patient-info {
  display: grid;
  row-gap: 0.75rem;
  column-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  padding: 0 10px;
}

.popup-container {
  position: relative;
}

.link {
  position: relative;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height */

  text-decoration-line: underline;
  text-underline-offset: 3px;

  color: #0099ff;
}

.link:hover + .popup {
  display: block;
}

.popup {
  display: none;
  position: absolute;
  background: #222222;
  box-shadow:
    0px 4px 6px rgba(0, 0, 0, 0.02),
    0px 2px 4px rgba(0, 0, 0, 0.06);

  z-index: 9001;
  border-radius: 10px;
}

.popup-center-right {
  top: -100%;
  left: 100%;
}

.popup-center-right::before {
  content: " ";
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;

  border-right: 8px solid #222222;

  position: absolute;
  top: 50%;
  bottom: 50%;
  left: -8px;
}

.popup .text,
.popup pre {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;

  color: #ffffff !important;
}
</style>
<style lang="scss">
.more-info {
  border: none;
  background-color: transparent;
  padding: 0;

  button:focus {
    &::before {
      display: none !important;
    }
  }

  button:focus-visible {
    outline: 2px solid var(--focus-ring) !important;
  }

  > .q-icon {
    cursor: pointer;
    padding-right: 5px;

    color: var(--status-blue);
  }
}

.encounter-patient.blueAccent {
  .more-info {
    span,
    .medicus-button,
    > .q-icon {
      color: white !important;
    }
  }

  .patient-info {
    .labeled-text {
      color: white;

      .label {
        font-weight: bold;
        color: white;
      }
    }
  }
}
.encounter-patient-info {
  i {
    font-size: 1.2rem !important;
  }
}

.m-expander > * {
  padding-left: 0;
  padding-right: 0;
}

.first-row {
  &:has(.encounter-patient-info-details:empty) {
    margin-bottom: 6px;
  }
}

.m-expander.expanded {
  padding-bottom: 0;
}

.separator {
  background: var(--grey-light);
}
</style>
