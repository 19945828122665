<script lang="ts" setup>
import { isString } from "@vue/shared";
import { Key } from "ts-key-enum";
import { computed, getCurrentInstance, provide, ref, watch, watchEffect } from "vue";
import { debounce, isArray, isObject, useDebounce, useEvent } from "vue-composable";
import { useInputRules } from "../../../../composables/horizontalLabel";
import axios from "../../../../utils/axios";
import { randomID } from "../../../../utils/helpers";
import MInput from "../MInput";
import { GroupOption, ItemOption, SelectLabelSymbol } from "./_types";
import SimpleMenu from "../SearchRenderers/SimpleMenu.vue";
import SelectRenderList from "../SearchRenderers/SelectRenderList.vue";

//// PROPS
const props = withDefaults(
  defineProps<{
    modelValue?: any;
    name?: string;

    label?: string;

    placeholder?: string;

    for?: string;

    clearable?: boolean;

    useInput?: boolean;

    debounce?: string | number;

    minSearchValueLength?: number;

    requiredText?: string;

    instructions?: string;

    autofocus?: boolean;

    searchFilter?: (item: string) => boolean;

    searchUrl: string;

    itemMap?: (v: any) => any;
  }>(),
  {
    useInput: undefined,
  },
);

//// EVENTS
const emit = defineEmits({
  "update:modelValue": (_value: any) => true,
  "input-value": (_option: ItemOption) => true,
  "search-results": (_results: Array<any>) => true,

  blur: () => true,
});

provide("formData", undefined);
provide("validationController", undefined);

///// STATE VARIABLES
const inputEl = ref<InstanceType<typeof MInput> | null>(null);
const { proxy } = getCurrentInstance()!;
const selectableItems = ref<Array<ItemOption>>([]);
const debounceMs = computed(() => props.debounce);
const originalValue = props.modelValue;
const { value, displayErrors, formTouched, touched } = useInputRules(
  props,
  () => {
    //focus
    inputEl.value?.focus();
  },
  [],
  true,
  () => props.requiredText || `Enter a value for '${props.label}'`,
  debounceMs,
  () => {
    inputEl.value.$el.scrollIntoView?.();
  },
);
const open = () => (isOpen.value = true);
const focus = () => inputEl.value?.focus();
const selectedOption = ref<ItemOption | null>(null);
const text = ref("");
const isMousedown = ref(false); // Used to determine if input focus came from a click or a tab
const focusedItem = ref<ItemOption | null>(null);
const focusedItemId = ref(0);
const searchedItems = ref<Array<GroupOption | ItemOption> | null>(null);
const isOpen = ref(false);
let lastSearchCancel: Function | undefined;
const isFetching = ref(false);
const searchFn = ref(doSearch);
const emitBlur = useDebounce(() => emit("blur"), 10);

//// COMPUTED
const id = computed(() => (props.for ? props.for : randomID("select")));
const menuId = computed(() => (props.for ? `${props.for}-menu` : randomID("select-menu")));
const isInput = computed(() => Boolean(props.useInput !== false || props.searchUrl));

const filteredItems = computed(() => {
  return searchedItems.value ?? [];
});

//// WATCHERS & LISTENERS
watch(filteredItems, (items) => {
  populateSelectableItems(items);

  // as we recompute the list reset the pointer to the top of the list
  if (selectableItems.value[0]) {
    focusedItemId.value = 0;
    focusedItem.value = selectableItems.value[0];
  } else {
    focusedItemId.value = 0;
    focusedItem.value = null;
  }
});

watch(isOpen, (open) => {
  // as we recompute the list reset the pointer to the top of the list
  if (open && focusedItem.value === null && selectableItems.value[0]) {
    focusedItemId.value = 0;
    focusedItem.value = selectableItems.value[0];
  }
});

watch(
  value,
  async (v, o) => {
    if (null === v && o) {
      onClear();
      return;
    }
    if (v && typeof v === "object") {
      if (v === selectedOption.value?.value) return;
      if (Array.isArray(v)) {
        // same object
        if (JSON.stringify(v) === JSON.stringify(o)) return;

        return;
      } else {
        const keys = Object.keys(v);
        if (keys.length == 2 && keys.includes("label") && keys.includes("value")) {
          selectedOption.value = v;
          text.value = getLabel(v);
          value.value = v.value;
        }
      }
    }

    // ENG-8731
    if (isString(v)) {
      selectedOption.value = { label: v, value: v };
      text.value = v;
      value.value = v;
    } else if (isObject(v) && !Array.isArray(v)) {
      const keys = Object.keys(v);
      if (keys.length == 2 && keys.includes("label") && keys.includes("value")) {
        // @ts-expect-error not the correct type
        selectedOption.value = v;
        text.value = getLabel(v);
        value.value = v.value;

        return;
      }
      selectedOption.value = {
        label: getLabel(v),
        value: v,
      };
      text.value = getLabel(v);
      value.value = v;
    }
  },
  { immediate: true, flush: "pre" },
);

watchEffect(() => {
  searchFn.value = debounceMs.value ? debounce(doSearch, +debounceMs.value) : doSearch;
});

watch(
  text,
  (v, p, onInvalidate) => {
    if (!v) {
      if (props.searchUrl) {
        searchedItems.value = [];
      }

      return;
    }
    if (!props.searchUrl) return;
    if (v === selectedOption.value?.label) return;

    searchFn.value(v);
    onInvalidate(() => {
      lastSearchCancel?.();
    });
  },
  {
    flush: "sync",
  },
);

useEvent(
  computed(() => inputEl.value?.$el),
  "blur",
  onBlur,
  { capture: true },
);

useEvent(window, "mouseup", () => {
  isMousedown.value = false;
});

//// HOOKS

//// FUNCTIONS
function populateSelectableItems(items: Array<ItemOption | GroupOption>) {
  selectableItems.value = [];
  for (const item of items) {
    if ("items" in item) {
      for (const subItem of item.items) {
        selectableItems.value.push(subItem);
      }
    } else {
      selectableItems.value.push(item);
    }
  }
}

function onKeydown(e: KeyboardEvent) {
  if ((e.target as HTMLButtonElement).tagName === "BUTTON") {
    return;
  }
  let newOpen = isOpen.value;

  switch (e.code) {
    case Key.Tab:
      newOpen = false;
      break;
    case Key.ArrowDown:
      e.preventDefault();
      if (!newOpen) {
        newOpen = true;
      }
      if (focusedItemId.value < selectableItems.value.length - 1) {
        // go down, until hitting the end of the list, then cycle back to the top
        if (newOpen) {
          ++focusedItemId.value;
        }
        focusedItem.value = selectableItems.value[focusedItemId.value];
      } else {
        if (newOpen) {
          focusedItemId.value = 0;
          focusedItem.value = selectableItems.value[focusedItemId.value];
        }
      }
      break;
    case Key.ArrowUp: {
      e.preventDefault();
      if (!newOpen) {
        newOpen = true;
      }
      if (focusedItemId.value > 0) {
        // go up, until hitting the top of the list, then cycle back to the bottom
        if (newOpen) {
          --focusedItemId.value;
        }
        focusedItem.value = selectableItems.value[focusedItemId.value];
      } else {
        if (newOpen) {
          focusedItemId.value = selectableItems.value.length - 1;
          focusedItem.value = selectableItems.value[focusedItemId.value];
        }
      }
      break;
    }
    case "Space": {
      if (isInput.value) {
        return;
      }
      e.preventDefault();
      newOpen = !newOpen;
      break;
    }
    case Key.Enter:
      e.preventDefault();
      if (!newOpen) {
        newOpen = true;
      } else {
        if (focusedItem.value && !isFetching.value) {
          onSelect(focusedItem.value);
          newOpen = false;
        }
      }
      break;
    case Key.Escape: {
      e.preventDefault();
      if (isOpen.value) {
        e.stopPropagation();
      }
      newOpen = false;
      break;
    }
    case Key.Backspace:
    case Key.Delete: {
      if (isInput.value) return;

      if (props.clearable || !!props.searchUrl) {
        selectedOption.value = null;
        text.value = "";
        value.value = null;
      }
      break;
    }
  }

  isOpen.value = newOpen;
}

function onSelect(e: GroupOption | ItemOption) {
  if ("title" in e) return;

  emit("input-value", e);

  setOption(e);
  isOpen.value = false;
}

function onClick(e: MouseEvent) {
  if ((e.currentTarget as HTMLDivElement).contains(e.target as HTMLElement)) {
    isOpen.value = !isOpen.value;
  }
}

function getLabel(e: any) {
  if (!e) return "";
  return (
    e.label || e.text || e.displayName || e.name || e.description || e || e[SelectLabelSymbol] || ""
  );
}

function setOption(e: ItemOption | any) {
  // if is the value, just ignore
  if (selectedOption.value && selectedOption.value.value === value.value && value.value === e) {
    if (e === null || e === undefined) {
      unsetOption();
    }
    return;
  }

  if (e == null) {
    unsetOption();
    return;
  }
  if (!isObject(e)) {
    unsetOption();
    return;
  }

  selectedOption.value = e as ItemOption;
  text.value = getLabel(e);
  value.value = e.value;
}

function unsetOption() {
  selectedOption.value = null;
  text.value = "";
  value.value = null;
}

async function doSearch(searchText: string) {
  if (props.minSearchValueLength && searchText.length < props.minSearchValueLength) {
    return;
  }

  try {
    lastSearchCancel?.();
    isFetching.value = true;
    let currentSearch: () => void = null;
    const cancelToken = new axios.CancelToken((cancel) => {
      currentSearch = lastSearchCancel = () => {
        cancel();
      };
    });

    const v = searchText;

    try {
      const results = await axios.get(props.searchUrl!, {
        cancelToken,
        params: {
          query: v,
        },
      });

      const data = isArray(results)
        ? results
        : isArray(results.data)
        ? results.data
        : results.data.results || [];

      emit("search-results", data);

      const items = props.itemMap ? data.map(props.itemMap) : data;

      if (currentSearch === lastSearchCancel) {
        searchedItems.value = props.searchFilter ? items.filter(props.searchFilter) : items;
        isOpen.value = true;
      }
    } catch (e) {
      currentSearch();
      if (currentSearch === lastSearchCancel) {
        lastSearchCancel = undefined;
        isFetching.value = false;
      }
    } finally {
      if (currentSearch === lastSearchCancel) {
        isFetching.value = false;
      }
    }
  } finally {
    //
  }
}

// useOnOutsidePress(
//   computed(() => proxy.$el),
//   () => {
//     if (isOpen.value) {
//       isOpen.value = false;
//       emitBlur();
//     }
//   },
// );

function onBlur(e: FocusEvent) {
  if (e.relatedTarget) {
    const target = e.relatedTarget as HTMLElement;

    if (proxy.$el.contains(target)) return;
  }

  // reset text
  if (text.value !== selectedOption.value?.label) {
    onClear();
  }

  focusedItemId.value = 0;
  focusedItem.value = null;

  isOpen.value = false;
  emitBlur();

  if (formTouched) {
    setTimeout(() => {
      const t = (touched.value = touched.value || value.value !== originalValue);

      if (!proxy?.$.isUnmounted) {
        formTouched(t);
      }
    }, 50);
  }
}

function onClear() {
  if (isFetching.value) {
    lastSearchCancel?.();
    isFetching.value = false;
  }

  setOption(null);

  text.value = "";
  value.value = null;
  isOpen.value = false;
}

defineExpose({
  open,
  focus,

  clear: () => onClear,

  isOpen: isOpen,

  isInput,
  text,
});

function handleInput() {
  isOpen.value = true;
}
</script>
<template>
  <MInput
    ref="inputEl"
    v-bind="$props"
    v-model="text"
    :class="['m-search-select', isInput && 'use-input']"
    :for="id"
    :instructions="instructions"
    :autofocus="autofocus"
    :placeholder="placeholder"
    :clearable="clearable || !!searchUrl"
    :loading="isFetching"
    :aria-controls="menuId"
    :aria-owns="menuId"
    :aria-expanded="isOpen"
    :aria-activedescendant="focusedItemId"
    input-role="combobox"
    data-form-type="other"
    no-validation
    :readonly="$attrs.readonly || !isInput"
    :show-clear="clearable && value !== null"
    :display-errors="displayErrors"
    @keydown="onKeydown"
    @click="onClick"
    @clear="onClear"
    @mousedown="isMousedown = true"
    @input="handleInput"
    @focus="
      () => {
        if (isMousedown === false) {
          inputEl?.selectText();
        }
      }
    "
  >
    <template v-if="$slots.prepend" #prepend>
      <slot name="prepend" />
    </template>

    <template #default="{ el }">
      <SimpleMenu
        v-if="filteredItems.length > 0 && $attrs.readonly !== true"
        :id="menuId"
        v-model="isOpen"
        no-auto-open
        :limit-width-to-target="true"
        role="listbox"
        :target="el"
        @keydown="onKeydown"
      >
        <SelectRenderList
          :focused-item="focusedItem"
          :items="filteredItems"
          :value="value"
          :disabled="isFetching"
          :data-testid="$attrs['data-testid']"
          @select="onSelect"
        >
        </SelectRenderList>
      </SimpleMenu>
    </template>
  </MInput>
</template>
<style lang="scss">
.m-search-select {
  &.use-input input {
    // quasar has aria-owns changing the cursor to pointer
    cursor: text;
  }

  .select--icon {
    transition: transform 0.28s;
  }

  * + .select--icon {
    padding-right: 4px;
    padding-left: 4px;
  }
}
.select-caret-icon {
  padding-left: 6px;
  padding-right: 6px;
}
</style>
