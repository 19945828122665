<script lang="ts" setup>
import { computed } from "vue";
import { usePrescriptionStore } from "../../../../store/transitient/prescription";
import { randomID } from "../../../../utils/helpers";
import MLink from "../../../medicus/MLink";
import MBanner from "../../../medicus/MBanner";

const store = usePrescriptionStore();
const currentAllergiesLabelId = randomID("current-allergies-label-");

const currentAllergies = computed(
  () =>
    store.state.currentAllergies
      ?.map((x) => ({
        isDraft: x.isDraft,
        isFinalised: x.isFinalised,
        description: x.allergyCode?.description || x.substance?.description,
        id: x.allergyId,
      }))
      .filter(Boolean) ?? [],
);
const showDrugAllergyWarning = computed(
  () =>
    (store.state.currentAllergies ?? []).findIndex(
      (allergy) => "416098002" === allergy.allergyCode?.conceptId,
    ) !== -1,
);
</script>
<template>
  <m-section title="Current Allergies">
    <m-banner
      v-if="store.state.currentAllergies?.length === 0"
      type="info"
      data-testid="no-allergies-banner"
    >
      There are no current allergies recorded.
    </m-banner>
    <m-banner v-else type="danger" data-testid="current-allergies-banner">
      <span v-if="showDrugAllergyWarning" data-testid="current-allergies-drug-allergy-banner">
        Please note the allergy in the list coded as "416098002 | Drug allergy". This is a
        historical code that can't be used in sensitivity checks and should be replaced.
      </span>

      <ul
        class="q-pl-lg"
        data-testid="current-allergies"
        :aria-labelledby="currentAllergiesLabelId"
      >
        <li v-for="(allergy, index) in currentAllergies" :key="`allergy_${allergy.id}_${index}`">
          <m-link
            v-if="allergy.isFinalised || allergy.isDraft"
            :to-slideover="`/clinical/allergy/overview-allergy/${allergy.id}`"
            :data-testid="`current-allergy-link-${allergy.id}`"
          >
            {{ allergy.description }}{{ allergy.isDraft ? " (Draft)" : "" }}
          </m-link>
          <span v-else>{{ allergy.description }}</span>
        </li>
      </ul>
    </m-banner>
  </m-section>
</template>
