<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from "vue";
import MFilterButton from "../MFilterButton";
import MHighlightSearch from "../MHighlightSearch";
import MLayoutStack from "../MLayoutStack";
import { DateHeading, JournalEntryType, PatientJournalGroup, PatientJournalRecord } from "./types";
import { computed } from "@vue/reactivity";
import { useDebouncedThrottledFunction } from "../../../composables/throttleDebounce";

import ConsultationJournalPresenter from "./presenters/ConsultationJournalPresenter.vue";
import AllergyHistoryJournalPresenter from "./presenters/AllergyHistoryJournalPresenter.vue";
import NoteJournalPresenter from "./presenters/NoteJournalPresenter.vue";
import ObservationJournalPresenter from "./presenters/ObservationJournalPresenter.vue";
import FitNoteJournalPresenter from "./presenters/FitNoteJournalPresenter.vue";
import CommunicationJournalPesenter from "./presenters/CommunicationJournalPesenter.vue";
import PrescriptionJournalPresenter from "./presenters/PrescriptionJournalPresenter.vue";
import ImmunisationJournalPresenter from "./presenters/ImmunisationJournalPresenter.vue";
import DocumentJournalPresenter from "./presenters/DocumentJournalPresenter.vue";
import FutureActionJournalPresenter from "./presenters/FutureActionJournalPresenter.vue";
import InvestigationJournalPresenter from "./presenters/InvestigationJournalPresenter.vue";
import InvestigationRequestJournalPresenter from "./presenters/InvestigationRequestJournalPresenter.vue";
import ReferralJournalPresenter from "./presenters/ReferralJournalPresenter.vue";
import MedicationAdministrationJournalPresenter from "./presenters/MedicationAdministrationJournalPresenter.vue";
import MedicationStatementOverTheCounterJournalPresenter from "./presenters/MedicationStatementOverTheCounterJournalPresenter.vue";
import ProcedureJournalPresenter from "./presenters/ProcedureJournalPresenter.vue";
import MedicationStatementPrescribedElsewhereJournalPresenter from "./presenters/MedicationStatementPrescribedElsewhereJournalPresenter.vue";
import { generateUUIDv7 } from "../../../utils/helpers";
import { getScrollParent } from "../../../utils/misc";

const props = defineProps({
  items: {
    type: Array as () => PatientJournalGroup[],
    default: () => [],
  },
  filterCount: Number,
  showFilter: {
    type: Boolean,
  },
  readOnly: {
    type: Boolean,
  },
  showSearch: {
    type: Boolean,
  },
});

const allItems = computed(() =>
  props.items
    .flatMap(({ title, items }) => [
      { title, isDateHeading: true, id: generateUUIDv7() } as DateHeading,
      ...items,
    ])
    .map((item, index) => {
      return {
        ...item,
        allItemsIndex: index,
      };
    }),
);
const visibleItems = ref<
  ((PatientJournalRecord & { mounted?: boolean; allItemsIndex: number }) | DateHeading)[]
>([]);
const itemsPerLoad = 40;
const currentIndex = ref(0);
const allItemsLoaded = ref<boolean>(false);
const isLoading = ref<boolean>(false);
const journalTimelineRef = ref<HTMLDivElement>();
const scrollPanelEl = ref<HTMLElement>();

function loadNextPageOfItems() {
  if (allItemsLoaded.value === false) {
    isLoading.value = true;

    const nextBatch = allItems.value.slice(currentIndex.value, currentIndex.value + itemsPerLoad);

    currentIndex.value = currentIndex.value + itemsPerLoad;

    visibleItems.value.push(...nextBatch);

    if (nextBatch.length < itemsPerLoad || visibleItems.value.length === allItems.value.length) {
      allItemsLoaded.value = true;
    }

    isLoading.value = false;
  }
}

function handleScroll(e: Event) {
  if (e.target && "scrollTop" in e.target) {
    const el = e.target as HTMLDivElement;
    const scrollTop = el.scrollTop;
    const scrollHeight = el.scrollHeight;
    const remainingHeight = scrollHeight - scrollTop - el.clientHeight;

    if (remainingHeight < 800 && isLoading.value === false) {
      loadNextPageOfItems();
    }
  }
}

const { debouncedThrottled: debouncedThrottledHandleScroll } = useDebouncedThrottledFunction(
  handleScroll,
  200,
  500,
);

onMounted(() => {
  loadNextPageOfItems();

  const scrollEl = getScrollParent(journalTimelineRef.value!);

  if (scrollEl) {
    scrollPanelEl.value = scrollEl as HTMLElement;

    scrollPanelEl.value.addEventListener("scroll", debouncedThrottledHandleScroll, {
      passive: true,
    });
  }
});

onBeforeUnmount(() => {
  if (scrollPanelEl.value) {
    scrollPanelEl.value.removeEventListener("scroll", debouncedThrottledHandleScroll);
  }
});

const careRecordTypeToComponentMap: Record<JournalEntryType, any> = {
  [JournalEntryType.ENCOUNTER]: ConsultationJournalPresenter,
  [JournalEntryType.ALLERGY_HISTORY]: AllergyHistoryJournalPresenter,
  [JournalEntryType.NOTE]: NoteJournalPresenter,
  [JournalEntryType.OBSERVATION]: ObservationJournalPresenter,
  [JournalEntryType.FIT_NOTE]: FitNoteJournalPresenter,
  [JournalEntryType.COMMUNICATION]: CommunicationJournalPesenter,
  [JournalEntryType.PRESCRIPTION]: PrescriptionJournalPresenter,
  [JournalEntryType.IMMUNISATION]: ImmunisationJournalPresenter,
  [JournalEntryType.MEDICATION_ADMINISTRATION]: MedicationAdministrationJournalPresenter,
  [JournalEntryType.MEDICATION_STATEMENT_PRESCRIBED_ELSEWHERE]:
    MedicationStatementPrescribedElsewhereJournalPresenter,
  [JournalEntryType.MEDICATION_STATEMENT_OVER_THE_COUNTER]:
    MedicationStatementOverTheCounterJournalPresenter,
  [JournalEntryType.FUTURE_ACTION]: FutureActionJournalPresenter,
  [JournalEntryType.REFERRAL]: ReferralJournalPresenter,
  [JournalEntryType.DOCUMENT]: DocumentJournalPresenter,
  [JournalEntryType.PROCEDURE]: ProcedureJournalPresenter,
  [JournalEntryType.INVESTIGATION]: InvestigationJournalPresenter,
  [JournalEntryType.INVESTIGATION_REQUEST]: InvestigationRequestJournalPresenter,

  [JournalEntryType.COMMUNICATION_THREAD]: undefined,
  [JournalEntryType.PROBLEM]: undefined,
};
</script>
<template>
  <div ref="journalTimelineRef" class="journal-timeline q-pb-xl">
    <div v-if="showSearch" class="journal-filter flex gap-3">
      <m-highlight-search placeholder="Text search" :content="$el" show-icon />
      <m-filter-button
        v-if="showFilter"
        show-icon
        label="Filters"
        color="grey"
        :filter-count="filterCount"
        borderless
      >
        <slot name="filter" />
      </m-filter-button>
    </div>
    <div v-if="showFilter" ref="filter-placeholder" class="filter-placeholder" />
    <Transition name="fade">
      <m-layout-stack v-if="visibleItems.length > 0" gap="2" class="word-break">
        <template v-for="item in visibleItems" :key="item.id">
          <component
            :is="careRecordTypeToComponentMap[item.type] ?? 'div'"
            v-if="'type' in item"
            ref="journalCards"
            :entry="item"
            :read-only="readOnly"
            :data-index="`${item.allItemsIndex}`"
          />
          <div
            v-else-if="'isDateHeading' in item"
            class="journal-group-title"
            :data-index="`${item.allItemsIndex}`"
          >
            {{ item.title }}
          </div>
        </template>
      </m-layout-stack>
    </Transition>
  </div>
</template>
<style lang="scss">
.journal-timeline {
  display: flex;
  flex-direction: column;
  position: relative;

  padding-top: 0 !important;

  .journal-group-title {
    color: var(--grey-darkest);
    font-weight: bold;
  }

  .m-card + .journal-group-title {
    margin-top: var(--gap-1);
  }

  .word-break {
    word-break: break-word;
  }

  .journal-item {
    //padding-left: 27px;

    position: relative;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;

    //&::before {
    //  left: 0;
    //  top: 4px;
    //  position: absolute;
    //  background-color: var(--grey-darker);
    //  // border: 2px solid #FFFFFF;
    //  height: 15px;
    //  width: 15px;
    //  border-radius: 50%;
    //  display: inline-block;
    //  z-index: 1;
    //  content: " ";
    //  // font-family: "Font Awesome 5 Pro";
    //  font-family: "Font Awesome 5 Free";
    //  color: #fff;
    //  padding-top: 4px;
    //  padding-left: 10px;
    //}

    // &.item-diagnosis::before {
    //   content: "\0f82e";
    //   padding-left: 8px;
    // }
    // &.item-procedure::before {
    //   content: "\0f61d";
    //   padding-left: 7px;
    // }
    // &.item-allergy-history::before {
    //   content: "\0f461";
    //   padding-left: 9px;
    // }
    // &.item-observation::before {
    //   content: "\0f201";
    //   padding-left: 9px;
    // }
    // &.item-prescription::before {
    //   content: "\0f572";
    // }
    // &.item-medication-statement::before {
    //   content: "\0f46b";
    //   padding-left: 9px;
    // }
    // &.item-medication-administration::before {
    //   content: "\0f490";
    // }
    // &.item-vaccine-administration::before {
    //   content: "\0f48e";
    // }
    // &.item-manual-referral::before {
    //   content: "\0f56c";
    //   padding-left: 8px;
    // }
    // &.item-note::before,
    // .item-patient-note::before {
    //   content: "\0f4a6";
    //   padding-left: 9px;
    //   padding-top: 5px;
    // }
    // &.item-patient-document::before {
    //   content: "\0f15c";
    // }
    // &.item-gp-connect-document::before {
    //   content: "\0f0f0";
    //   padding-left: 9px;
    // }
    // &.item-post-event-message-111::before {
    //   content: "\0f590";
    //   padding-left: 8px;
    //   padding-top: 5px;
    // }
    // &.item-future-action::before {
    //   content: "\0f073";
    //   padding-left: 9px;
    // }
    // &.item-fit-note::before {
    //   content: "\0f15c";
    // }
    // &.item-vaccination-history::before {
    //   content: "\0f48e";
    //   padding-left: 9px;
    // }
    // &.item-encounter::before {
    //   content: "\0f0f0";
    //   padding-left: 9px;
    // }

    //&:not(:last-child)::after {
    //  left: 7px;
    //  top: 26px;
    //  content: " ";
    //  position: absolute;
    //
    //  height: calc(100% + 2.5rem - 60px);
    //  width: 1px;
    //  background-color: var(--grey-light);
    //}

    .journal-item--text {
      font-family: Arial, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: var(--grey-darkest);
      .text-with-separator:not(:first-child)::before {
        content: " • ";
      }
    }
  }

  .journal-filter {
    z-index: 10;

    width: 100%;

    // position: fixed;
    position: sticky;
    top: 0px;

    padding: 19px;

    margin-left: -16px;
    margin-right: -16px;
    width: calc(100% + 32px);

    background: var(--medicus-background);
    .medicus-button-container[color="grey"] {
      .medicus-button {
        font-weight: 400;
        background: var(--medicus-background);
      }
    }
    /* Large Drop Shadow */
    .shadow {
      box-shadow:
        0px 4px 6px rgba(0, 0, 0, 0.06),
        0px 2px 4px rgba(0, 0, 0, 0.06);
    }

    .q-field__prepend {
      padding-right: 0;
    }
  }
}
</style>
