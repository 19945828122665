<script lang="ts">
export interface Action {
  label: string;
  onClick: Function;
  click: Function;
  to?: string;
  toSlideover?: string | DrawerUrlOptions | false;
  toModal?: string | DrawerUrlOptions | false;

  disabled?: boolean;
  permission?: string | any;

  beforeSubmit?: () => PromiseLike<boolean | void>;
  submit?: () => PromiseLike<boolean | void>;
  afterSubmit?: () => PromiseLike<void>;

  dataTestid?: string;
}
</script>

<script lang="ts" setup>
import { NOOP } from "@vue/shared";
import { QItem, QItemLabel, QItemSection, QList } from "quasar";
import { inject, ref } from "vue";
import { type DrawerUrlOptions } from "../../../store/drawers";
import MButton from "../MButton";
import MDropdownButton from "../MDropdownButton";
import MForm from "../MForm";
import MLayoutStack from "../MLayoutStack";
import { useSnackbar } from "../../../composables/snackbar";

const props = defineProps({
  cancelLabel: {
    type: String,
    default: "Cancel",
  },
  submitLabel: {
    type: String,
    default: "Save",
  },

  disableSubmit: Boolean,

  secondaryAction: [Object, Array] as any as () => Array<Action> | Action,

  enterSubmit: Boolean,
  actions: Array as () => Array<Action>,

  hideCancel: Boolean,
  hideActions: { type: Boolean },
  beforeSubmit: Function,

  onSuccess: [Function, Array] as any as () => (() => void) | Array<() => void>,
  onCancel: Function,
  prefix: String,

  successMessage: String,

  noClose: Boolean,
});

const formEl = ref<InstanceType<typeof MForm> | null>(null);

defineExpose({
  submit() {
    return formEl.value?.submit();
  },

  validate() {
    return formEl.value?.validate();
  },
});

const emit = defineEmits({
  success: (e: any) => true,
  cancel: (e: any) => true,
});

const cancelForm = inject<(shouldClose: boolean) => void>("cancel", NOOP);
const additionalCreatePayloadData = inject<Record<string, any>>("additionalCreatePayloadData", {});

function handleSuccess(event: any) {
  if (props.successMessage !== "" && props.successMessage !== null) {
    useSnackbar().add({
      message: props.successMessage ?? "Success.",
      type: "success",
    });
  }
  emit("success", event);
}

function handleCancel(event: any) {
  emit("cancel", event);
  cancelForm?.(!props.noClose);
}

async function submitAction(a: Action) {
  if (a.beforeSubmit) {
    if ((await a.beforeSubmit()) === false) {
      return;
    }
  }

  if (a.submit) {
    if ((await a.submit()) === false) {
      return;
    }
  }
  formEl.value?.submit();

  a.afterSubmit && a.afterSubmit();
}
</script>

<template>
  <m-form
    ref="formEl"
    v-bind="$attrs"
    :prefix="prefix"
    :before-submit="beforeSubmit"
    :enter-submit="enterSubmit"
    :no-close="noClose"
    :additional-create-payload-data="additionalCreatePayloadData"
    @success="handleSuccess"
    @cancel="handleCancel"
  >
    <slot />

    <template #footer="{ submitting, hasAccess }">
      <slot name="footer" v-bind="{ cancelLabel, submitLabel }">
        <m-layout-stack class="flex-auto" horizontal end>
          <m-button
            v-if="!hideCancel"
            :label="cancelLabel"
            color="secondary"
            :loading="submitting"
            @click.prevent="handleCancel"
          />
          <template v-if="secondaryAction">
            <template v-if="Array.isArray(secondaryAction)">
              <m-button
                v-for="(action, i) in secondaryAction"
                :key="`secondary-action-${i}`"
                color="secondary"
                v-bind="action"
                :loading="submitting"
                @click="submitAction(action)"
              />
            </template>
            <m-button
              v-else
              color="secondary"
              v-bind="secondaryAction"
              :loading="submitting"
              @click="submitAction(secondaryAction!)"
            />
          </template>
          <m-dropdown-button
            v-if="actions"
            split
            type="submit"
            :label="submitLabel"
            :loading="submitting"
            :disabled="disableSubmit || !hasAccess"
            data-testid="submit-button"
          >
            <q-list>
              <q-item
                v-for="(action, i) in actions"
                :key="i"
                v-close-popup
                clickable
                :loading="submitting"
                :disabled="disableSubmit || action.disabled || !hasAccess ? true : undefined"
                :disable="disableSubmit || action.disabled || !hasAccess ? true : undefined"
                :data-testid="action.dataTestid"
                @click="submitAction(action)"
              >
                <q-item-section>
                  <q-item-label>{{ action.label }}</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </m-dropdown-button>

          <m-button
            v-else-if="!hideActions"
            :label="submitLabel"
            type="submit"
            color="primary"
            :loading="submitting"
            :disabled="disableSubmit || !hasAccess"
            data-testid="submit-button"
          />
        </m-layout-stack>
      </slot>
    </template>
  </m-form>
</template>
