import { Key } from "ts-key-enum";
import {
  InjectionKey,
  Ref,
  computed,
  inject,
  provide,
  reactive,
  ref,
  shallowReadonly,
  watch,
} from "vue";
import { isFunction, promisedTimeout, useEvent } from "vue-composable";

const focusableElements =
  'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

function isAllowedToFocus(x: HTMLElement) {
  if (!isFunction(x.getAttribute)) return undefined;
  return (
    x.getAttribute("tabindex") !== "-1" && !x.hasAttribute("disabled") && !x.hasAttribute("disable")
  );
}

// based on https://uxdesign.cc/how-to-trap-focus-inside-modal-to-make-it-ada-compliant-6a50f9a70700
export function useFocusTrap(el: Ref<HTMLElement | undefined | null>, autoFocus = false) {
  const focusableContent = ref<HTMLElement[] | null | undefined>(null);

  function refreshElements() {
    focusableContent.value = Array.from(
      el.value?.querySelectorAll<HTMLElement>(focusableElements) ?? [],
    ).filter(isAllowedToFocus);
  }

  const observer = new MutationObserver(refreshElements);

  let focusTarget: EventTarget | null = null;
  watch(el, (e) => {
    if (e) {
      Promise.resolve().then(refreshElements);
      observer.observe(e, {
        childList: true,
        subtree: true,
        attributeFilter: ["tabindex", "disable", "disabled"],
        attributes: true,
      });
    } else {
      observer.disconnect();
      focusTarget = null;
    }
  });

  if (autoFocus) {
    const removeWatch = watch(
      focusableContent,
      (e) => {
        if (e) {
          e[0]?.focus();
          Promise.resolve().then(removeWatch);
        }
      },
      {
        flush: "post",
        immediate: true,
        deep: false,
      },
    );
  }

  const first = computed(() => focusableContent.value?.[0]);
  const last = computed(() =>
    focusableContent.value ? focusableContent.value[focusableContent.value.length - 1] : undefined,
  );

  useEvent(
    el,
    "focus",
    (e) => {
      focusTarget = e.target;
    },
    {
      capture: true,
    },
  );

  useEvent(document, "keydown", (e) => {
    if (!el.value) return;
    const isTabPressed = e.key === Key.Tab || e.keyCode === 9;

    if (!isTabPressed) {
      return;
    }

    if (e.shiftKey || e.metaKey) {
      if (focusTarget === first.value) {
        last.value?.focus();
        e.preventDefault();
      }
    } else {
      if (focusTarget === last.value) {
        first.value?.focus();
        e.preventDefault();
      }
    }
  });

  return {
    focusableContent,
    first,
    last,
  };
}

let loadingCount = 0;
export function startLoading() {
  return ++loadingCount;
}

export function stopLoading() {
  return --loadingCount;
}

async function waitLoading() {
  while (loadingCount > 0) {
    await new Promise((resolve) => setTimeout(resolve, 10));
  }
}

function getCSSPath(el: Element | null): string | undefined {
  if (!el || !(el instanceof Element)) return undefined;

  const path: string[] = [];
  while (el && el.nodeType === Node.ELEMENT_NODE) {
    let selector = el.nodeName.toLowerCase();
    if (el.id) {
      selector += `#${el.id}`;
      path.unshift(selector);
      break;
    } else {
      let sibling: Element | null = el;
      let nth = 1;
      while ((sibling = sibling.previousElementSibling)) {
        if (sibling.nodeName.toLowerCase() === selector) nth++;
      }
      if (nth !== 1) selector += `:nth-of-type(${nth})`;
    }
    path.unshift(selector);
    el = el.parentNode as Element | null;
  }

  return path.join(" > ");
}

const FocusHistorySymbol = Symbol(
  IS_APP && import.meta.env.DEV ? "FocusHistory" : undefined,
) as InjectionKey<{
  queue: readonly HTMLElement[];
  focusLast: () => void;
}>;

export function defineFocusHistory(queueMax: number | undefined = 500) {
  const queue = reactive<HTMLElement[]>([]);
  const cssMap = new WeakMap<HTMLElement, string>();

  useEvent(
    window,
    "focus",
    (e) => {
      const target = e.relatedTarget ?? e.target;
      if (target && isAllowedToFocus(target as HTMLElement)) {
        queue.push(target as HTMLElement);
        cssMap.set(target as HTMLElement, getCSSPath(target as HTMLElement));
      }
    },
    {
      capture: true,
      passive: true,
    },
  );

  async function focusLast() {
    let item;

    await waitLoading();
    loadingCount = 0;

    await promisedTimeout(10);
    while ((item = queue.pop())) {
      if (!document.body.contains(item)) {
        const p = cssMap.get(item);
        if (p) {
          try {
            const e = document.querySelector(p);
            if (e && e instanceof HTMLElement && e.focus) {
              e.focus();
              return;
            }
          } catch {
            //ignore
          }
        }
        continue;
      }
      if (item && item instanceof HTMLElement && item.focus) {
        item.focus();
        return;
      }
    }
  }

  if (queueMax) {
    watch(
      () => queue.length,
      (e) => {
        if (queueMax >= e) return;
        while (e > queueMax) {
          queue.shift();
        }
      },
      {
        flush: "post",
      },
    );
  }

  const result = {
    queue: shallowReadonly(queue),

    focusLast,
  };

  provide(FocusHistorySymbol, result);

  return result;
}

export function useFocusHistory() {
  return inject(FocusHistorySymbol);
}
