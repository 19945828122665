<template>
  <m-layout-stack v-if="!hideTopicDetailsSection" data-testid="encounter-details-section">
    <div :class="['flex flex-col gap-1']">
      <p
        v-if="consultationTopicCount > 1"
        :class="[
          {
            'marked-incorrect': topic.isMarkedIncorrect,
          },
        ]"
      >
        {{ topic?.topicCode?.topicCode?.label ?? topic.title }}
        <span
          v-if="topic.hiddenFromPatientFacingServices || encounterHiddenFromPatientFacingServices"
        >
          • Redacted from Patient Online Access</span
        >
        <span v-if="topic.confidentialFromThirdParties || encounterConfidentialFromThirdParties">
          • Confidential from Third Parties</span
        >
        <span v-if="topic.linkedProblems.length"> • {{ getLinkedProblemsText() }}</span>
      </p>
    </div>
  </m-layout-stack>

  <span v-if="!topic.headings.length" class="empty-text">
    No sections have been added to this consultation topic.
  </span>

  <template v-for="heading in topic.headings" :key="heading.id">
    <template v-if="hideEmptyHeadings ? heading.entries.length > 0 : true">
      <p
        :class="[
          {
            'marked-incorrect': heading.isMarkedIncorrect,
          },
          'secondary-text',
        ]"
      >
        <span> {{ getHeadingTitleLine(heading) }}</span>
      </p>

      <m-list v-if="heading.entries.length">
        <ConsultationCareRecordElements
          :data-testid="`section-${heading.title}`"
          :items="heading.entries"
          :read-only="readOnly"
          :context-hidden-fps="getContextIsHiddenFromPatientFacingServices(heading)"
          :context-confidential-ftp="getContextIsConfidentialFromThirdParties(heading)"
        />
      </m-list>
      <div v-else>
        <p class="empty-text">There are no entries recorded.</p>
      </div>
    </template>
  </template>
</template>

<script setup lang="ts">
import { computed } from "vue";
import ConsultationCareRecordElements from "./ConsultationCareRecordElements.vue";

export interface Topic {
  id: string;
  title: string;
  topicCode: TopicCode;
  linkedProblems: any[];
  headings: Heading[];
  isDraft: boolean;
  isMarkedIncorrect: boolean;
  hiddenFromPatientFacingServices: boolean;
  confidentialFromThirdParties: boolean;
  encounterHiddenFromPatientFacingServices: boolean;
  encounterConfidentialFromThirdParties: boolean;
  isRetrospectivelyAmended: boolean;
}

export interface TopicCode {
  topicCode?: {
    label: string;
  };
}

export interface Heading {
  id: string;
  title: string;
  entries: Entry[];
  isMarkedIncorrect: boolean;
  hiddenFromPatientFacingServices: boolean;
  confidentialFromThirdParties: boolean;
  encounterHiddenFromPatientFacingServices: boolean;
  encounterConfidentialFromThirdParties: boolean;
  isRetrospectivelyAmended: boolean;
}

export interface Entry {
  entryType: string;
  id: string;
  note?: string;
  clinicalCodeDescription?: string;
  recordedBy: string;
  recordedByOrganisation?: string;
  isMarkedIncorrect: boolean;
  hiddenFromPatientFacingServices: boolean;
  confidentialFromThirdParties: boolean;
  isExplicitlyIncludedInSCR: boolean;
  isRetrospectivelyAmended: boolean;
  patientBannerFlags: any[];
  linkedProblems: any[];
  createdDateTime?: string;
}

defineOptions({
  inheritAttrs: false,
});

const props = defineProps<{
  topic: Topic;
  topicTitle?: string;
  readOnly?: boolean;
  showTopicTitleInside?: boolean;
  patientId?: string;
  hideEmptyHeadings?: boolean;
  encounterHiddenFromPatientFacingServices?: boolean;
  encounterConfidentialFromThirdParties?: boolean;
  consultationTopicCount: number;
}>();

const hideTopicDetailsSection = computed(() => {
  return hideTopicCodeWhenEmpty.value;
});

const hideTopicCodeWhenEmpty = computed(() => {
  return !props.topic.topicCode.topicCode && !props.topic.title;
});

function getHeadingTitleLine(heading) {
  let textArray = [];
  if (heading.title) {
    textArray.push(heading.title);
  }
  if (showHeadingIsHiddenFromPatientFacingServices(heading)) {
    textArray.push("Redacted from Patient Online Access");
  }
  if (showHeadingIsConfidentialFromThirdParties(heading)) {
    textArray.push("Confidential from Third Parties");
  }

  return textArray.join(" • ") ?? "";
}

function getLinkedProblemsText() {
  const problemsArray = props.topic.linkedProblems || [];
  const problemDescriptions = problemsArray
    .filter((x) => x.problemCodeDescription)
    .map((x) => x.problemCodeDescription);

  return problemDescriptions.length ? `Linked Problems: ${problemDescriptions.join(", ")}` : "";
}

function showHeadingIsHiddenFromPatientFacingServices(heading) {
  return (
    !props.topic.hiddenFromPatientFacingServices &&
    !props.encounterHiddenFromPatientFacingServices &&
    heading.hiddenFromPatientFacingServices
  );
}

function showHeadingIsConfidentialFromThirdParties(heading) {
  return (
    !props.topic.confidentialFromThirdParties &&
    !props.encounterConfidentialFromThirdParties &&
    heading.confidentialFromThirdParties
  );
}

function getContextIsHiddenFromPatientFacingServices(heading) {
  return (
    props.topic.hiddenFromPatientFacingServices ||
    props.encounterHiddenFromPatientFacingServices ||
    heading.hiddenFromPatientFacingServices
  );
}

function getContextIsConfidentialFromThirdParties(heading) {
  return (
    props.topic.confidentialFromThirdParties ||
    props.encounterConfidentialFromThirdParties ||
    heading.confidentialFromThirdParties
  );
}
</script>
