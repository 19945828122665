<template>
  <div
    :class="[
      {
        clickable: !readOnly,
        'marked-incorrect': item.isMarkedIncorrect,
      },
    ]"
    gap="1"
  >
    <m-layout-stack horizontal>
      <div class="flex-auto">
        <span>{{ item.prescriptionTypeLabel }} prescription: {{ item.productName }}</span>
        <span v-if="item.dosageText"> • {{ item.dosageText }}</span>
        <span v-if="item.displayStatus"> • {{ item.displayStatus }}</span>
      </div>
      <i v-if="item.isRetrospectivelyAmended" style="white-space: nowrap">{{
        formatRetrospectivelyAmendedLabel
      }}</i>
    </m-layout-stack>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

export type PrescriptionJournalItem = {
  linkedProblems: { problemCodeDescription: string }[];
  confidentialFromThirdParties: boolean;
  hiddenFromPatientFacingServices: boolean;
  requiresAction: boolean;
  displayStatus: string;
  productName: string;
  dosageText: string;
  prescriptionTypeLabel: string;
  isMarkedIncorrect: boolean;
  isRetrospectivelyAmended: boolean;
};

const props = defineProps<{
  item: PrescriptionJournalItem;
  readOnly: boolean;
  contextHiddenFromPatientFacingServices?: boolean;
  contextConfidentialFromThirdPartyServices?: boolean;
}>();

const formatRetrospectivelyAmendedLabel = computed(() => {
  let label = [];
  if (props.item.isRetrospectivelyAmended) {
    label.push("Edited");
  }

  return label.join(" • ");
});
</script>
