<template>
  <m-list-item
    v-for="(entry, i) in items"
    :key="entry"
    :sr-label="renderSrLabel(entry)"
    :on-click="readOnly || !eventsMap[entry.entryType] ? null : () => onClick(entry)"
    presentation-style="bullet"
    :class="`${entry.entryType}`"
  >
    <slot :name="`item:${entry.entryType}`" :item="entry" :index="i">
      <slot :name="entry.entryType" :item="entry">
        <component
          :is="careRecordTypeToComponentMap[entry.entryType]"
          v-bind="$attrs"
          :read-only="readOnly"
          :item="entry"
          :inline="inline"
          :context-hidden-from-patient-facing-services="contextHiddenFps"
          :context-confidential-from-third-party-services="contextConfidentialFtp"
        />
      </slot>
    </slot>
  </m-list-item>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { openModal, openSlideover } from "../../../../../composables/dialog/drawer";
import {
  Entry,
  PrescribedElsewhereEntry,
} from "../../../consultation/MConsultationTopicHeading/types";
import { EntryType } from "../../../MInlineEntryList/types";

import NoteJournalElement from "../NoteJournalElement.vue";
import AllergyJournalElement from "../AllergyJournalElement.vue";
import FitNoteJournalElement from "../FitNoteJournalElement.vue";
import ObservationJournalElement from "../ObservationJournalElement.vue";
import ProcedureJournalElement from "../ProcedureJournalElement.vue";
import CommunicationJournalElement from "../CommunicationJournalElement.vue";
import CommunicationThreadJournalElement from "../CommunicationThreadJournalElement.vue";
import InvestigationJournalElement from "../InvestigationJournalElement.vue";
import ReferralJournalElement from "../ReferralJournalElement.vue";
import DocumentJournalElement from "../DocumentJournalElement.vue";
import MedicationAdministrationJournalElement from "../MedicationAdministrationJournalElement.vue";
import InvestigationRequestJournalElement from "../InvestigationRequestJournalElement.vue";
import ImmunisationJournalElement from "../ImmunisationJournalElement.vue";
import MedicationStatementPrescribedElsewhereJournalElement from "../MedicationStatementPrescribedElsewhereJournalElement.vue";
import MedicationStatementIOverTheCounterJournalElement from "../MedicationStatementIOverTheCounterJournalElement.vue";
import PrescriptionJournalElement from "../PrescriptionJournalElement.vue";
import PrescriptionIssueJournalElement from "../PrescriptionIssueJournalElement.vue";
import FutureActionJournalElement from "../FutureActionJournalElement.vue";

defineOptions({
  inheritAttrs: false,
});

const props = defineProps<{
  items: Entry[];
  readOnly: boolean;
  consultationTopicId?: string;
  inline?: boolean;
  draft?: boolean;
  contextHiddenFps: boolean;
  contextConfidentialFtp: boolean;
}>();

defineEmits(["rendered"]);

const careRecordTypeToComponentMap: Record<EntryType, any> = {
  [EntryType.ALLERGY]: AllergyJournalElement,
  [EntryType.CARE_RECORD_NOTE]: NoteJournalElement,
  [EntryType.OBSERVATION]: ObservationJournalElement,
  [EntryType.FIT_NOTE]: FitNoteJournalElement,
  [EntryType.PRESCRIPTION]: PrescriptionJournalElement,
  [EntryType.PRESCRIPTION_ISSUE]: PrescriptionIssueJournalElement,
  [EntryType.MEDICATION_STATEMENT_PRESCRIBED_ELSEWHERE]:
    MedicationStatementPrescribedElsewhereJournalElement,
  [EntryType.MEDICATION_STATEMENT_OVER_THE_COUNTER]:
    MedicationStatementIOverTheCounterJournalElement,
  [EntryType.IMMUNISATION]: ImmunisationJournalElement,
  [EntryType.MEDICATION_ADMINISTRATION]: MedicationAdministrationJournalElement,
  [EntryType.PROCEDURE]: ProcedureJournalElement,
  [EntryType.COMMUNICATION]: CommunicationJournalElement,
  [EntryType.COMMUNICATION_THREAD]: CommunicationThreadJournalElement,
  [EntryType.INVESTIGATION]: InvestigationJournalElement,
  [EntryType.DOCUMENT]: DocumentJournalElement,
  [EntryType.OUTBOUND_REFERRAL]: ReferralJournalElement,
  [EntryType.INVESTIGATION_REQUEST]: InvestigationRequestJournalElement,
  [EntryType.FUTURE_ACTION]: FutureActionJournalElement,

  // These do not show in consultation care record elements list
  [EntryType.TASK]: undefined,
  [EntryType.APPOINTMENT]: undefined,
  [EntryType.FOLLOW_UP_APPOINTMENT]: undefined,
  [EntryType.REFERRAL_ATTACHMENT]: undefined, //TODO: Check if deprecated and delete
  [EntryType.RECALL]: undefined, //TODO: Check if deprecated and delete
};

const eventsMap = computed(() => {
  return {
    note: props.draft ? noteEdit : noteOverview,

    allergy: props.draft ? allergyEdit : allergyOverview,

    "medication-statement-over-the-counter": props.draft
      ? medicationOTCEdit
      : medicationOTCOverview,

    "medication-statement-prescribed-elsewhere": props.draft
      ? medicationPEEdit
      : medicationPEOverview,

    observation: props.draft ? observationEdit : observationOverview,

    prescription: prescriptionOverview,

    "outbound-referral": outboundReferralOverview,

    "future-action": props.draft ? futureActionEdit : futureActionOverview,

    "fit-note": props.draft ? fitNoteEdit : fitNoteOverview,

    "medication-administration": props.draft
      ? medicationAdministrationEdit
      : medicationAdministrationOverview,

    immunisation: props.draft ? immunisationEdit : immunisationOverview,

    procedure: props.draft ? procedureEdit : procedureOverview,

    communication: communicationOverview,
    "communication-thread": communicationThreadOverview,

    "prescription-issue": prescriptionIssueOverview,
    document: documentOverview,
    "investigation-request": props.draft ? investigationRequestEdit : investigationRequestOverview,
  };
});

function renderSrLabel(entry: Entry) {
  let label = `${props.draft ? "Edit " : "View "} `;

  switch (entry.entryType) {
    case "note":
      return `${label} Note: ${entry.clinicalCodeDescription ?? ""} ${entry.note ?? ""}`;
    case "allergy":
      return `${label} Allergy: ${
        entry.substanceDescription ? entry.substanceDescription : entry.allergyCodeDescription
      }`;
    case "observation":
      return `${label} Observation${
        entry.type || entry.value || entry.additionalInformation ? ":" : ""
      } ${entry.type ?? ""} ${entry.value ?? ""} ${entry.additionalInformation ?? ""}`;
    case "prescription":
      return `${label} Prescription: ${entry.productName} ${
        entry.numberOfIssues ? `${entry.numberOfIssues} issues ${entry.dosageText}` : ""
      }`;
    case "medication-statement-prescribed-elsewhere": {
      const supplementaryLineText = (item: PrescribedElsewhereEntry) => {
        let txt = [];
        if (item.dosageInstruction) {
          txt.push(item.dosageInstruction);
        }
        if (item.prescriptionType) {
          txt.push(item.prescriptionType);
        }
        return txt.join(" • ");
      };

      return `${label} Medication prescribed elsewhere: ${entry.productName} ${
        entry.dosageInstruction ? supplementaryLineText(entry) : ""
      }`;
    }
    case "medication-statement-over-the-counter":
      return `${label} Over-the-counter medication: ${entry.productName}`;
    case "follow-up-appointment":
      return `${label} Appointment: ${entry.startDateTime ?? ""} (${
        entry.deliveryMode ? `(${entry.deliveryMode})` : ""
      }) ${entry.serviceName}`;
    case "future-action":
      return `${label} Future action/recall: ${entry.clinicalCode ?? ""} ${entry.plannedDateRange}`;
    case "fit-note":
      return `${label} MED3 Fit Note: ${entry.decision}`;
    case "immunisation":
      return `${label} Immunisation: ${entry.immunisation} ${entry.quantityAdministered ?? ""} ${
        entry.productName ?? ""
      }`;
    case "medication-administration":
      return `${label} Drug/device administered: ${entry.quantityAdministered ?? ""} ${
        entry.productName ?? ""
      }`;
    case "procedure":
      return `${label} Procedure: ${entry.procedureCode}`;
    case "communication":
      return `${label} ${entry.snomedCTCode?.description ?? "communication"}: ${entry.summary}`;
    case "communication-thread":
      return "View Communication thread";
    case "investigation":
      return `${label} Investigation: ${entry.descriptionDetails
        .map((d) => d.description)
        .join(" ")} ${entry.requesterText}`;
    case "referral-attachment":
      return; // TODO - Is this in use?
    case "outbound-referral":
      return `${label} Referral: ${entry.referralCode} ${
        entry.referralType === "nhs-e-referral"
          ? `(UBRN: ${entry.uniqueBookingReferenceNumber}) ${entry.priority} ${entry.shortlistedServices}`
          : `${entry.priority} ${entry.referralProvider}`
      }`;
    case "general-task":
      return `Miscellaneous task`;
    case "appointment-request-task":
      return `Appointment request task`;
    case "investigation-request":
      return `Investigation request: ${entry.investigationRequestItems.join(" • ")}`;
    case "required-intervention":
      return `Required intervention`;
    case "document":
      return `document`;
    case "prescription-issue":
      return `prescription-issue`;
    default:
      throw new Error(`Unknown entry type: ${entry.entryType}`);
  }
}

function onClick(entry: Entry) {
  // handle the click event
  const handler = entry.entryType ? eventsMap.value[entry.entryType] : eventsMap.value["note"];
  handler(entry.id, entry);
}

// note
function noteEdit(id: string) {
  openSlideover(`/clinical/note/edit-note/${id}`);
}

function noteOverview(id: string) {
  openSlideover(`/clinical/note/overview/${id}`);
}

// allergy
function allergyEdit(id: string) {
  openSlideover("/clinical/allergy/edit-allergy/" + id);
}

function allergyOverview(id: string) {
  openSlideover("/clinical/allergy/overview-allergy/" + id);
}

// medication-statement-over-the-counter
function medicationOTCEdit(id: string) {
  openSlideover(`/clinical/medication-statement/edit-over-the-counter-medication/${id}`);
}

function medicationOTCOverview(id: string) {
  openSlideover(
    `/clinical/medication-statement/overview-medication-statement-over-the-counter/${id}`,
  );
}

// medication-statement-prescribed-elsewhere
function medicationPEEdit(id: string) {
  openSlideover(`/clinical/medication-statement/edit-prescribed-elsewhere-medication/${id}`);
}

function medicationPEOverview(id: string) {
  openSlideover(
    `/clinical/medication-statement/overview-medication-statement-prescribed-elsewhere/${id}`,
  );
}

// observation
function observationEdit(id: string) {
  openSlideover("/clinical/observation/edit/" + id);
}

function observationOverview(id: string) {
  openSlideover("/clinical/observation/overview/" + id);
}

function prescriptionOverview(id: string) {
  openSlideover("/clinical/prescription/overview/" + id);
}

function outboundReferralOverview(id, entry) {
  if (entry.referralType === "manual") {
    openSlideover(`/clinical/referral/manual-referral/overview/${id}`);
  } else if (entry.isAdviceAndGuidance === true) {
    openModal(`/clinical/referral/nhs-e-referral/advice-and-guidance/overview/${id}`, {
      close: true,
    });
  } else {
    openSlideover(`/clinical/referral/nhs-e-referral/overview/${id}`);
  }
  return;
}

// Future Action
function futureActionEdit(id: string) {
  openSlideover(`/future-action/edit/${id}`);
}

function futureActionOverview(id: string) {
  openSlideover(`/future-action/patient-overview/${id}`);
}

// fit-note
function fitNoteEdit(id: string) {
  openSlideover(`/clinical/gb/fit-note/edit/${id}`);
}

function fitNoteOverview(id: string) {
  openModal(`/clinical/gb/fit-note/modals/preview-fit-note/${id}`);
}

// medication-administration
function medicationAdministrationEdit(id: string) {
  openSlideover(`/clinical/medication-administration/edit/${id}`);
}

function medicationAdministrationOverview(id: string) {
  openSlideover(`/clinical/medication-administration/overview/${id}`);
}

// immunisation
function immunisationEdit(id: string, entry) {
  if (entry?.isPointOfCare) {
    openSlideover(`/clinical/immunisation/edit-immunisation/${id}`);
  } else if (entry?.isVaccinationHistory) {
    openSlideover(`/clinical/immunisation/edit-historical-immunisation/${id}`);
  }
}

function immunisationOverview(id: string) {
  openSlideover(`/clinical/immunisation/immunisation-overview/${id}`);
}

// procedure
function procedureEdit(id: string) {
  openSlideover("/clinical/procedure/edit-procedure/" + id);
}
function procedureOverview(id: string) {
  openSlideover("/clinical/procedure/overview/" + id);
}

function communicationOverview(id: string) {
  openSlideover(`/clinical/communication/care-record-communication-overview/${id}`);
}

function prescriptionIssueOverview(id: string) {
  openSlideover(`/clinical/prescription/prescription-issue/overview/${id}`);
}

function communicationThreadOverview(id, communicationThread) {
  if (communicationThread.hasPatientQuestionnaireResponseCommunications) {
    openModal(`/communication/communication-thread/communication-thread-overview/${id}`, {
      props: {
        viewInSlideover: false,
      },
    });
  } else {
    openSlideover(`/communication/communication-thread/communication-thread-overview/${id}`, {
      props: {
        viewInSlideover: true,
      },
    });
  }
}

function investigationRequestEdit(id: string) {
  openModal(`/clinical/investigation-request/edit/${id}`);
}

function investigationRequestOverview(id: string) {
  openSlideover(`/clinical/investigation-request/overview/${id}`, {
    props: {
      viewInSlideover: true,
    },
  });
}

function documentOverview(id: string) {
  openModal(`/clinical/document/modals/preview/${id}`, {
    close: true,
  });
}
</script>
<style>
.topic-entry-card span {
  white-space: pre-wrap;
}
</style>
