<template>
  <m-card
    :class="{ 'cursor-pointer': readOnly }"
    sr-label="View immunisation details"
    @click="immunisationOverview"
  >
    <div
      :class="[
        {
          clickable: !readOnly,
          'marked-incorrect': entry.data.isMarkedIncorrect,
        },
      ]"
    >
      <m-layout-stack horizontal>
        <div class="flex-auto">
          <span v-if="entry.data.administrationStatus === 'given'">
            <b>Immunisation:</b> {{ entry.data.code ?? "" }}
          </span>
          <span v-if="entry.data.administrationStatus === 'not-given'">
            <b>Immunisation not given:</b> {{ getCodeAndReasonNotGiven ?? "" }}
          </span>
          <span v-if="entry.data.administeringOrganisation">
            • {{ entry.data.administeringOrganisation }}
          </span>
        </div>
        <i v-if="entry.data.isRetrospectivelyAmended" style="white-space: nowrap">{{
          formatRetrospectivelyAmendedLabel
        }}</i>
      </m-layout-stack>
      <div
        v-if="
          entry.data.hiddenFromPatientFacingServices ||
          entry.data.confidentialFromThirdParties ||
          entry.data.linkedProblems.length
        "
      >
        <span v-if="getConfidentialityTextAndLinkedProblems()" class="secondary-text">
          {{ getConfidentialityTextAndLinkedProblems() }}
        </span>
      </div>
      <m-separator v-if="entry.data.additionalInformation" class="q-my-xs" />
      <span v-if="entry.data.additionalInformation">
        {{ entry.data.additionalInformation }}
      </span>
    </div>

    <!--    <ImmunisationJournalElement :item="entry.data" :read-only="readOnly" :clickable="clickable" />-->
  </m-card>
</template>

<script setup lang="ts">
import { openSlideover } from "../../../../composables/dialog/drawer";
import { PatientJournalRecord } from "../types";
import { computed } from "vue";
import MSeparator from "../../MSeparator";

const props = defineProps<{
  entry: PatientJournalRecord;
  readOnly: boolean;
  clickable?: boolean;
}>();

const formatRetrospectivelyAmendedLabel = computed(() => {
  let label = [];
  if (props.entry.data.isRetrospectivelyAmended) {
    label.push("Edited");
  }

  return label.join(" • ");
});

const getCodeAndReasonNotGiven = computed(() => {
  const textAsArray = [props.entry.data.code, props.entry.data.reasonNotGiven];

  const text = textAsArray.filter((x) => x).join(" • ");

  return text === "" ? false : text;
});

function getConfidentialityTextAndLinkedProblems() {
  const textArray = [];

  if (props.entry.data.hiddenFromPatientFacingServices) {
    textArray.push("Redacted from Patient Online Access");
  }
  if (props.entry.data.confidentialFromThirdParties) {
    textArray.push("Confidential from Third Parties");
  }
  if (props.entry.data.isExplicitlyIncludedInSCR) {
    textArray.push("Explicitly included in SCR");
  }

  const text = textArray.join(" • ");

  const problemsArray = props.entry.data.linkedProblems || [];
  const problemDescriptions = problemsArray
    .filter((x) => x.problemCodeDescription)
    .map((x) => x.problemCodeDescription);

  const problemText = problemDescriptions.length
    ? `Linked Problems: ${problemDescriptions.join(", ")}`
    : "";

  const finalTextArray = [text, problemText].filter(Boolean);

  return finalTextArray.length ? finalTextArray.join(" • ") : false;
}

function immunisationOverview() {
  return props.readOnly
    ? openSlideover(`/clinical/immunisation/immunisation-overview/${props.entry.id}`, {
        close: true,
      })
    : undefined;
}
</script>
