<template>
  <div
    :class="[
      {
        clickable: !readOnly,
        'marked-incorrect': item.isMarkedIncorrect,
      },
    ]"
  >
    <m-layout-stack horizontal>
      <div class="flex-auto">
        <span>Procedure: {{ getProcedureAndBody }}</span>
        <span v-if="item.procedureDateIsNotEncounterDate || item.procedureDateIsNotDocumentDate">
          ({{ item.procedureDate }})</span
        >
        <span> • {{ item.performingOrganisation }}</span>
        <span v-if="item.isExplicitlyIncludedInSCR"> • Explicitly included in SCR</span>
        <span v-if="item.additionalInformation"> • {{ item.additionalInformation }}</span>
      </div>
      <i v-if="item.isRetrospectivelyAmended" style="white-space: nowrap">{{
        formatRetrospectivelyAmendedLabel
      }}</i>
    </m-layout-stack>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

export type ProcedureJournalItem = {
  isExplicitlyIncludedInSCR: boolean;
  linkedProblems: { problemCodeDescription: string }[];
  confidentialFromThirdParties: boolean;
  hiddenFromPatientFacingServices: boolean;
  isMarkedIncorrect: boolean;
  body: string;
  procedureCode: string;
  isRetrospectivelyAmended: boolean;
};

const props = defineProps<{
  item: ProcedureJournalItem;
  readOnly: boolean;
  contextHiddenFromPatientFacingServices?: boolean;
  contextConfidentialFromThirdPartyServices?: boolean;
}>();

const formatRetrospectivelyAmendedLabel = computed(() => {
  let label = [];
  if (props.item.isRetrospectivelyAmended) {
    label.push("Edited");
  }

  return label.join(" • ");
});

const getProcedureAndBody = computed(() => {
  const textAsArray = [props.item.procedureCode, props.item.body];
  const text = textAsArray.filter((x) => x).join(" • ");
  return text === "" ? false : text;
});
</script>
