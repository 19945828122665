<template>
  <m-validation-component :errors="displayErrors" :actions="actions" :actions-left="actionsLeft">
    <m-labeled-text
      class="m-labeled-telephone"
      :class="{ large }"
      :label="label"
      :horizontal="horizontalLabel"
      :helper="helper"
      :instructions="instructions"
      :required="$attrs.required"
      :label-width="width"
      :data-testid="$attrs['data-testid'] || name || undefined"
    >
      <template v-if="$slots.helper" #helper> <slot name="helper" /></template>
      <template #text>
        <MazPhoneNumberInput
          ref="input"
          class="m-telephone"
          :class="{
            required: validAttribute($attrs.required),
            readonly: validAttribute($attrs.readonly),
            disabled: validAttribute($attrs.disabled),
          }"
          no-validation
          no-example
          no-label
          :default-country-code="defaultCountryCode"
          v-bind="{ ...$attrs, onInput: undefined }"
          :error="!valid"
          :value="value"
          size="xs"
          :translations="{ countrySelectorLabel: '' }"
          autocomplete="chrome-off"
          data-form-type="other"
          @update="updateTelephoneNumber"
          @input="onInput"
          @blur="onBlur"
          @clear="onClear"
        >
          <template #icon-right> </template>
        </MazPhoneNumberInput>
      </template>
    </m-labeled-text>
  </m-validation-component>
</template>
<script lang="ts" setup>
import "maz-ui/lib/css/base.css";
import "maz-ui/lib/css/maz-phone-number-input.css";
import { getCurrentInstance, ref } from "vue";
import MazPhoneNumberInput from "../../../../components/maz-ui/MazPhoneNumberInput";
import {
  useHorizontalLabel,
  useInputRules,
  useInputWidth,
} from "../../../../composables/horizontalLabel";
import { alpha3ToAlpha2Map } from "../../../../utils/country";
import { validAttribute } from "../../../../utils/misc";
import MLabeledText from "./../../MLabeledText";
import MValidationComponent from "./../MValidationComponent";

import { isValidPhoneNumber } from "libphonenumber-js";

const props = defineProps({
  modelValue: {
    type: String,
    default() {
      return null;
    },
  },
  label: String,

  name: String,

  defaultCountryCode: {
    type: String,
    default: () => {
      const instance = getCurrentInstance();
      return alpha3ToAlpha2Map[instance?.appContext.config.globalProperties.$settings?.country];
    },
  },

  horizontal: {
    type: Boolean,
    default: undefined,
  },
  labelWidth: String,

  rules: Array,
  showAllErrors: Boolean,

  requiredText: String,

  large: Boolean,

  helper: String,
  instructions: String,

  actions: Array,
  actionsLeft: Boolean,
});

const emit = defineEmits({
  e164: (_e: any) => true,
  "update:modelValue": (_e: string) => true,

  update: (_e: {
    countryCallingCode: string;
    countryCode: string;
    e164: string;
    formatInternational: string;
    formatNational: string;
    isValid: boolean;
    nationalNumber: string;
    type: string;
    uri: string;
  }) => true,
});
const input = ref<{ focusPhoneNumberInput(): void }>();

const { value, valid, displayErrors, touched } = useInputRules(
  props,
  () => {
    input.value?.focusPhoneNumberInput();
  },
  [
    (e: any) => {
      if (!e) return;
      // const v = isValidPhoneNumber(e);
      return (e && isValidPhoneNumber(e)) || "Invalid phone number";
    },
  ],
  undefined,
  () => props.requiredText || `Enter a telephone number for '${props.label}'`,
);

const horizontalLabel = useHorizontalLabel(props);
const width = useInputWidth(props);

function updateTelephoneNumber(payload: {
  countryCallingCode: string;
  countryCode: string;
  e164: string;
  formatInternational: string;
  formatNational: string;
  isValid: boolean;
  nationalNumber: string;
  type: string;
  uri: string;
}) {
  emit("update", payload);

  if (payload.isValid) {
    emit("e164", payload.e164);
    value.value = payload.e164;
  } else {
    value.value = payload.nationalNumber;
  }

  // value.value = payload;
}

function onInput(e) {
  if (typeof e === "string" || e === null) {
    value.value = e || "";
  }
}

function onBlur() {
  touched.value = true;
}

function onClear() {
  value.value = "";
}
</script>

<style lang="scss">
.m-labeled-telephone {
  .label {
    margin-bottom: 2px;
  }

  .maz-phone-number-input--xs {
    .country-selector {
      flex: 0 0 6.3rem;
      min-width: 6.3rem;
      max-width: 6.3rem;
    }
    // .maz-phone-number {
    //   width: 13rem;
    // }
    input {
      padding-right: 0 !important;
    }

    .maz-input__icon {
      right: 0px;
    }
  }

  max-width: 28rem;
  &.large {
    max-width: 100%;
  }
}

/**.label

maz-base-component maz-input maz-border maz-border-color maz-border-color-hover maz-border-solid maz-border-radius has-value maz-input--xs has-1-right-icon maz-input--primary
maz-base-component maz-input maz-border maz-border-color maz-border-color-hover maz-border-solid maz-border-radius input-phone-number maz-flex-1 has-value has-no-label maz-input--xs has-1-right-icon maz-input--primary

 */
.m-telephone {
  --primary: var(--theme-blue);
  border-color: var(--grey-lightest-non-text);
  border-width: 1px;

  white-space: initial;

  font-family: Arial, sans-serif;

  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

  outline-color: var(--theme-blue);

  .maz-base-component {
    font-family: Arial, sans-serif !important;
    font-size: 14px;
  }

  .country-selector {
    border-color: var(--grey-lightest-non-text);
    border-width: 1px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

    input {
      padding-top: 0 !important;
    }
  }

  .maz-phone-number-input__country-flag {
    // bottom: 1em!important;
    bottom: 12px !important;
  }

  .maz-input--xs {
    height: 35px;
    min-height: 35px;

    &:focus {
      border-color: var(--focus-ring);
    }

    input {
      font-size: 14px !important;
    }
  }

  input {
    font-size: 14px;
    line-height: 150%;
    font-weight: 400;
    color: var(--text-color);

    border-radius: 4px;
  }

  &.disabled {
    input {
      color: var(--grey);
    }
  }

  // &.valid {
  //   border-color: #68d391;
  //   color: #68d391;
  // }

  &.error {
    border-color: var(--status-red);
    color: var(--status-red);
  }
}
</style>
