<script setup lang="ts">
import { computed } from "@vue/reactivity";
import { getCurrentInstance, PropType, ref, watch } from "vue";
import { PropLike, usePermission } from "../../../composables/permission";
import { listen } from "../../../utils/bus";
import { isObject, isPromise, useOnOutsidePress } from "vue-composable";
import { openModal, openSlideover } from "../../../composables/dialog/drawer";
import SimpleMenu from "../inputs/SearchRenderers/SimpleMenu.vue";
import { Key } from "ts-key-enum";
import { useRouter } from "vue-router";
import { generateUUIDv7 } from "../../../utils/helpers";

// This is a component to replace the usage for MDropdownButton which has a memory leak
// caused by wrapping quasar components that the GC cannot remove correctly.

//// PROPS
const props = withDefaults(
  defineProps<{
    label: string;
    ghost?: boolean;
    border?: boolean;
    outline?: boolean;
    click?: Function;
    type?: string;
    color?: string;
    disabled?: boolean;
    menu?: boolean;
    icon?: string;
    iconOnly?: boolean;
    options?: PropType<Array<{ label: string }> | Record<string, { label: string }>>;
  }>(),
  {},
);

//// LOCAL VARS
const isOpened = ref(false);
const focusEl = ref<HTMLElement | null>(null);
const isFocus = ref(false);
const submitting = ref(false);
const { proxy } = getCurrentInstance()!;
const optionAccess = ref([]);
const router = useRouter();
const targetId = generateUUIDv7();

//// COMPUTED VARS
const items = computed(() => {
  const options = props.options;
  if (!options) return [];
  if (Array.isArray(options)) {
    return [
      {
        name: "",
        items: options,
      },
    ];
  }
  return Object.keys(options).map((x) => ({
    name: x,
    items: options[x],
  }));
});

//// WATCHERS & LISTENERS
listen("close-menus", (data: any) => {
  if (data.target === targetId || data === true) isOpened.value = false;
});

watch(isOpened, (v) => {
  if (v && items.value.length > 0) {
    for (let option of items.value) {
      if (Array.isArray(option.items)) {
        for (const o of option.items) {
          const { disabled } = usePermission(o, true, router);
          optionAccess.value[o.label] = { o, disabled };
        }
      }
    }
  }
});

//// FUNCTIONS
function handleKey() {
  isFocus.value = true;
  isOpened.value = !isOpened.value;
}

function handleBlur() {
  isFocus.value = false;
}

async function itemClick(item: PropLike, ev: MouseEvent | KeyboardEvent) {
  if (item.disabled) return;
  if (isDisabled(item)) return;
  if (ev instanceof KeyboardEvent) {
    if (ev.code === Key.Escape) isOpened.value = false;
    if (ev.code !== Key.Enter) return;
  }
  const click = (item.click || item.onClick) as (e: MouseEvent | KeyboardEvent) => void;
  if (click) {
    const res = click(ev);
    if (isPromise(res)) {
      submitting.value = true;
      try {
        await res;
      } finally {
        submitting.value = false;
      }
      return;
    }
    return;
  }

  if (item.toModal) {
    const [url, options] = isObject(item.toModal)
      ? [item.toModal.url, item.toModal]
      : [item.toModal, {}];

    if (options?.stopEventPropagation === true) {
      ev.stopPropagation();
    }

    return openModal(url, options);
  }
  if (item.toSlideover) {
    const [url, options] = isObject(item.toSlideover)
      ? [item.toSlideover.url, item.toSlideover]
      : [item.toSlideover, {}];

    if (options?.stopEventPropagation === true) {
      ev.stopPropagation();
    }

    return openSlideover(url, options);
  }
}

useOnOutsidePress(
  computed(() => proxy.$el),
  () => {
    if (isOpened.value) {
      isOpened.value = false;
      handleBlur();
    }
  },
);

function isDisabled(item: any) {
  return optionAccess.value[item.label]?.disabled;
}
</script>
<template>
  <div
    ref="focusEl"
    class="m-dropdown-button-new"
    :class="[
      { disabled: disabled },
      'medicus-outline',
      color || type,
      { 'menu-style': menu, ghost: ghost || menu, raised: !border && !ghost && !menu, outline },
    ]"
    tabindex="0"
    @click="isOpened = !isOpened"
    @keydown.enter="handleKey"
    @blur="handleBlur"
  >
    <div class="button">
      <span class="button-label">{{ label }}</span>
      <div class="select-caret-icon">
        <i v-if="!isOpened" class="fa fa-caret-down" />
        <i v-else class="fa fa-caret-up" />
      </div>
    </div>
    <template v-if="$slots.label || iconOnly">
      <slot name="label">
        <span class="sr-only">{{ label }}</span>
      </slot>
    </template>

    <SimpleMenu :id="targetId" no-auto-open :model-value="isOpened">
      <div :class="{ 'grouped-list': !Array.isArray(options) }">
        <div v-for="(group, index) in items" :key="`option-${index}`" class="dropdown-group">
          <div v-if="group.name" class="dropdown-item no-hover dropdown-button-list-title">
            {{ group.name }}
          </div>
          <div
            v-for="(option, i) in group.items"
            :key="`option-item-${i}`"
            class="dropdown-item"
            :class="{
              disabled: isDisabled(option),
            }"
            v-bind="option"
            clickable
            tabindex="0"
            role="menuitem"
            @click="(ev) => itemClick(option, ev)"
            @keydown="(ev) => itemClick(option, ev)"
          >
            <div class="dropdown-button-list-label">
              {{ option.label }}
              <div v-if="isDisabled(option)" class="dropdown-button-list-tooltip">
                You don’t have permission to perform this action.
              </div>
            </div>
          </div>
        </div>
      </div>
    </SimpleMenu>
  </div>
</template>

<style lang="scss">
.m-dropdown-button-new {
  // text
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
  height: 35px;
  /* identical to box height */
  text-align: left;
  border-radius: 4px;
  border: 1px solid var(--theme-accent-blue);
  background: var(--theme-blue);
  color: #ffffff;
  cursor: pointer;
  outline-color: var(--theme-blue);
  outline-width: 20px;
  outline: none;
  padding: 2px 12px;
  user-select: none;

  display: flex;
  align-items: center;

  .dropdown-item {
    min-height: 30px !important;
    border-radius: 4px;
    padding: 4px 10px 4px 16px;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;

    &:hover,
    &:focus {
      background-color: var(--grey-light);
    }
    &.no-hover {
      background-color: #ffffff !important;
    }
    &.disabled {
      // need to override so that the tooltip hover covers the behind content
      opacity: 1 !important;

      .dropdown-button-list-label {
        color: var(--text-color-lightest) !important;
      }
    }
  }

  .dropdown-button-list-title {
    color: var(--text-color-light);
    cursor: default;
  }

  .dropdown-button-list-label {
    color: var(--text-color);

    .dropdown-button-list-tooltip {
      visibility: hidden;
      background-color: var(--grey-darkest);
      font-size: 10px;
      color: #fff;
      text-align: center;
      padding: 3px 6px;
      margin-top: 5px;
      border-radius: 6px;

      /* Position the tooltip text - see examples below! */
      position: absolute;
      z-index: 1;
    }

    &:hover .dropdown-button-list-tooltip {
      visibility: visible;
    }
  }

  .button {
    display: flex;
    direction: ltr;
  }

  .button-label {
    text-align: center;
  }
}
</style>
