<script setup lang="ts">
import { NOOP } from "@vue/shared";
import { computed, inject, nextTick, onMounted, provide, ref, toRef, watch } from "vue";
import { usePromiseLazy } from "vue-composable";
import { openModal, openPage } from "../../../../composables/dialog/drawer";
import { showDialog } from "../../../../utils/dialog";
import { usePrescriptionStore } from "../../../../store/transitient/prescription";
import { SearchPredefinedDosagesQueryResult } from "../../../../store/transitient/prescription/api/types";
import {
  BaseEnumTrait,
  NewPrescriptionResponse,
} from "../../../../store/transitient/prescription/types";
import axios from "../../../../utils/axios";
import { generateUUIDv7, randomID } from "../../../../utils/helpers";
import MBanner from "../../../medicus/MBanner";
import MButton from "../../../medicus/MButton";
import MCheckboxGroup from "../../../medicus/MCheckboxGroup";
import MCreateForm from "../../../medicus/MCreateForm";
import MInputGroup from "../../../medicus/MInputGroup";
import MLayoutStack from "../../../medicus/MLayoutStack";
import MRadioGroup from "../../../medicus/MRadioGroup";
import MCheckbox from "../../../medicus/inputs/MCheckbox";
import MDate from "../../../medicus/inputs/MDate";
import MInput from "../../../medicus/inputs/MInput";
import MSimpleSelect from "../../../medicus/inputs/MSimpleSelect";
import MSearchSelect from "../../../medicus/inputs/MSearchSelect";
import MSelectList from "../../../medicus/inputs/MSelectList";
import MDescriptionList from "../../../medicus/MDescriptionList";
import MDescriptionListItem from "../../../medicus/MDescriptionList/MDescriptionListItem";
import MSection from "../../../medicus/MSection";
import DrugOrDeviceSelector from "../DrugOrDeviceSelector";
import DosageInstructionInput from "./DosageInstructionInput";
import {
  EntryType,
  SortOrder,
} from "../../../medicus/consultation/MConsultationTopicHeading/types";
import { formatDate } from "../../../../utils/date";
import { useDrawerStore } from "../../../../store/drawers";
import CurrentAllergies from "../CurrentAllergies";

const reloadData = inject("reloadData", NOOP as () => Promise<void>);
const close = inject("close", NOOP);
const success = inject<(_shouldClose: boolean, data?: {}) => void>("success", NOOP);
const additionalCreatePayloadData = inject<{}>("additionalCreatePayloadData");
const drawerOptions = inject<Record<string, any>>("drawerOptions");

const prescriptionAdditionalCreatePayloadData = ref(additionalCreatePayloadData || {});

provide("additionalCreatePayloadData", prescriptionAdditionalCreatePayloadData);

const props = defineProps<{
  formUrl: string;
  hideAddAnother?: boolean;
  hideIssueNow?: boolean;
  showIssueLaterButton?: boolean;
  issueLaterLabel?: string;
  issueLaterColor?: string;
  additionalIgnoreFields?: string[];
  createdInPatientRequestTask?: boolean;
}>();

const store = usePrescriptionStore();

const prescription = toRef(store, "prescription");

const formKey = ref(randomID());

const isNewProblem = ref(false);
const showAuthorisationInputs = ref(false);

function toggleNewProblem() {
  isNewProblem.value = !isNewProblem.value;
}

provide("formData", store.form);

provide("ignoreFields", [
  "prescription.allowedPrescriptionTypes",
  "prescription.dosageInstruction.maximumPeriodInDays",
  ...(props.additionalIgnoreFields ?? []),
]);

let addAnother = false;

onMounted(() => {
  if (
    additionalCreatePayloadData &&
    "sortOrder" in additionalCreatePayloadData &&
    additionalCreatePayloadData?.sortOrder
  ) {
    store.sortOrder = additionalCreatePayloadData.sortOrder as SortOrder;
  }
});

function beforeIssueLater() {
  store.form.workflowIdentifier = null;
  addAnother = false;
}

function beforeSaveAndAddAnother() {
  // TODO
  addAnother = true;
}

function beforeSubmit() {
  // TODO
}

async function goToTask(taskId) {
  close();

  return await openPage(`/tasks/eps-subsequent-cancellation/overview/${taskId}`);
}

function parseDialogResponse(response, cb) {
  if (response?.data?.showTaskDialog) {
    if (response?.data?.taskId) {
      return showDialog({
        title: "EPS Cancellation Failure",
        text: response.data.message,
        cancelLabel: "Dismiss",
        okLabel: "Go to task",
        okColor: "submit",
      })
        .onOk(async () => {
          await goToTask(response.data.taskId);
          cb?.();
        })
        .onCancel(() => {
          cb?.();
        });
    }

    return showDialog({
      title: "EPS Cancellation Failure",
      text: response.data.message,
      okLabel: "Dismiss",
      okColor: "secondary",
      noCancel: true,
    }).onOk(() => {
      cb?.();
    });
  }
}

function onError(error) {
  if (error.response?.data?.showTaskDialog) {
    close();
    parseDialogResponse(error.response, () => onSubmitResponseCallback(error.response));
  }
}

function onSuccess(response: NewPrescriptionResponse) {
  success?.(false, response?.data || undefined);

  if (response?.data?.showTaskDialog) {
    close();
    parseDialogResponse(response, () => onSubmitResponseCallback(response));

    return;
  }

  onSubmitResponseCallback(response);
}

function onSubmitResponseCallback(response: NewPrescriptionResponse) {
  formKey.value = randomID();
  if (addAnother) {
    try {
      if (response?.data?.newProblemId) {
        if (
          store.state.linkableProblems.findIndex(
            (x) => x.conceptId === store.form.newLinkedProblem.conceptId,
          ) === -1
        ) {
          store.state.linkableProblems.push({
            value: response.data.newProblemId,
            isArchived: false,
            label: store.form.newLinkedProblem.description,
            conceptId: store.form.newLinkedProblem.conceptId,

            endDate: null,
            startDate: null,
            isMarkedInCorrect: false,
          });
        }
      }
      reloadData();
      store.resetPrescriptionStructure();
      store.prescription.prescriberEndorsements = null;
      store.prescription.dispenserInstructions = null;
      store.drugOrDevice = null;
      store.dosage = null;

      selectedDosage.value = null;
      store.state.confidentialFromThirdParties = false;
      store.state.hiddenFromPatientFacingServices = false;
      store.state.confirmationAcknowledgedAndProceed = false;
      store.state.confirmationAcknowledgedAndProceedSafetyChecks = false;
      clearProblems();
      isNewProblem.value = false;

      if (response?.data?.sortOrderHash) {
        const newPrescriptionId = generateUUIDv7();
        store.newPrescriptionId = newPrescriptionId;
        let newSortOrder: SortOrder = [];
        if (store.sortOrder) newSortOrder.push(...store.sortOrder);
        newSortOrder.push({
          id: newPrescriptionId,
          entryType: EntryType.PRESCRIPTION,
        });
        store.sortOrder = newSortOrder;

        const newAdditionalCreatePayloadData = {
          uuid: newPrescriptionId,
          sortOrder: store.sortOrder,
          sortOrderHash: response.data.sortOrderHash,
        };
        store.sortOrderHash = response.data.sortOrderHash;

        prescriptionAdditionalCreatePayloadData.value = newAdditionalCreatePayloadData;
      }
    } finally {
      addAnother = false;
    }
  } else {
    useDrawerStore().clear();
    reloadData();
    if (store.form.workflowIdentifier && true !== store.state.skipIssuing) {
      nextTick(() => {
        openModal(
          `/clinical/prescription/modal/issue-prescription/${store.form.workflowIdentifier}`,
          {
            props: {
              createdInPatientRequestTask: props.createdInPatientRequestTask,
            },
            onSuccess: drawerOptions?.onSuccess,
          },
        );
      });
    }
  }
}

const computedRepeatReauthoriseActionType = computed(() => {
  const sanitise = (input) => {
    return input?.replace(/[^a-zA-Z0-9.-]/g, "");
  };

  if ("indeterminate" !== store.prescriptionFormType) {
    return false;
  } else if (!store.originalDrugOrDevice?.vmp?.conceptId) {
    // If the original prescription's drug or device is not in dm+d, it will not return
    // VMP data, and therefore we must assume that it is a modify action
    return "modify";
  } else if (!store.drugOrDevice?.vmp?.conceptId) {
    return false;
  } else if (
    (store.originalDrugOrDevice.vmp?.conceptId !== store.drugOrDevice.vmp?.conceptId &&
      store.originalDrugOrDevice.vmp?.description !== store.drugOrDevice.vmp?.description) ||
    sanitise(store.originalDosageInstructionText) !==
      sanitise(store.prescription.dosageInstruction.dosageText)
  ) {
    return "modify";
  } else if (
    (store.originalDrugOrDevice.vmp?.conceptId === store.drugOrDevice.vmp?.conceptId ||
      store.originalDrugOrDevice.vmp?.description === store.drugOrDevice.vmp?.description) &&
    sanitise(store.originalDosageInstructionText) ===
      sanitise(store.prescription.dosageInstruction.dosageText)
  ) {
    return "re-authorise";
  }

  return false;
});

const computedRepeatReauthoriseBannerMessage = computed(() => {
  if ("re-authorise" === computedRepeatReauthoriseActionType.value) {
    return 'There have been no changes to the medication or dosage which means that Medicus will create a new prescription and mark the old prescription "re-authorised".';
  } else if ("modify" === computedRepeatReauthoriseActionType.value) {
    return 'Because you have made changes to the medication or dosage, Medicus will create a new prescription and mark the old prescription "ended" with the reason "Change to medication treatment regimen".';
  }

  return null;
});

const computedExpectedDaysSupply = computed(() => {
  if (false === store.prescription?.dosageInstruction?.useManualDosageText) {
    if (
      store.prescription?.dosageInstruction?.dose?.snomedCtCode?.conceptId !==
      store.prescription?.issueQuantity?.snomedCtCode?.conceptId
    ) {
      return 1;
    }

    return Math.ceil(
      (store.prescription.issueQuantity.value *
        ((store.prescription.dosageInstruction.maximumPeriodInDays || 1) /
          (store.prescription.dosageInstruction.frequency || 1))) /
        (store.prescription.dosageInstruction.dose?.value || 1),
    );
  }

  return store.prescription?.expectedDaysSupply;
});

const confirmationRequiredMessage = computed(() => {
  const expectedDaysSupply = computedExpectedDaysSupply.value;

  // Schedule 2, 3, 4
  if (["2", "3", "4"].indexOf(store.prescription.category) !== -1) {
    if (expectedDaysSupply > 30) {
      const baseMessage =
        "Schedule 2, 3 or 4 drugs should be limited to a maximum supply duration of 30 days.";
      if (store.prescription?.dosageInstruction?.useManualDosageText) {
        return `${baseMessage} You have entered ${expectedDaysSupply} days.`;
      }

      return `${baseMessage}\n\nThe issue quantity entered would equate to a ${expectedDaysSupply} day supply based on the dosage instructions.`;
    }

    return null;
  }

  // HRT
  if (store.metaInformation?.isHrt) {
    if (expectedDaysSupply > 365) {
      const baseMessage =
        "HRT medications should be limited to a maximum supply duration of 365 days.";
      if (store.prescription?.dosageInstruction?.useManualDosageText) {
        return `${baseMessage} You have entered ${expectedDaysSupply} days.`;
      }

      return `${baseMessage}\n\nThe issue quantity entered would equate to a ${expectedDaysSupply} day supply based on the dosage instructions.`;
    }

    return null;
  }

  // Oral contraceptive
  if (store.metaInformation?.isOralContraceptive) {
    if (expectedDaysSupply > 365) {
      const baseMessage =
        "Oral contraceptives should be limited to a maximum supply duration of 365 days.";
      if (store.prescription?.dosageInstruction?.useManualDosageText) {
        return `${baseMessage} You have entered ${expectedDaysSupply} days.`;
      }

      return `${baseMessage}\n\nThe issue quantity entered would equate to a ${expectedDaysSupply} day supply based on the dosage instructions.`;
    }

    return null;
  }

  // Everything else
  if (expectedDaysSupply > 90) {
    const baseMessage =
      "It is not recommended to supply medication for more than 90 days at a time.";
    if (store.prescription?.dosageInstruction?.useManualDosageText) {
      return `${baseMessage} You have entered ${expectedDaysSupply} days.`;
    }

    return `${baseMessage}\n\nThe issue quantity entered would equate to a ${expectedDaysSupply} day supply based on the dosage instructions.`;
  }

  return null;
});

const prescribingStaffList = computed(() => {
  return store.state.isInNurseFormulary
    ? store.state.prescribingStaff
    : store.state.prescribingStaffExcludingCommunityNurses;
});

const disableSubmit = computed(() => {
  return (
    !store.drugOrDevice ||
    (confirmationRequiredMessage.value && !store.state.confirmationAcknowledgedAndProceed) ||
    (store.computedCountOfDangerTypeSafetyChecks > 0 &&
      !store.state.confirmationAcknowledgedAndProceedSafetyChecks) ||
    repeatDispensingFailsOnNumberOfIssues.value ||
    repeatDispensingFailsOnDuration.value ||
    repeatFailsOnNumberOfIssues.value
  );
});

const prescriptionTypes = computed(() => {
  return store.metaInformation?.allowedPrescriptionTypes?.length
    ? store.metaInformation?.allowedPrescriptionTypes
    : store.state.defaultPrescriptionTypes;
});

const isExpectedDaysSupplyRequired = computed(
  () =>
    prescription.value.prescriptionType === "repeat" ||
    prescription.value.prescriptionType === "repeat-dispensing",
);

const showNumberOfIssues = computed(
  () =>
    prescription.value.prescriptionType === "repeat" ||
    prescription.value.prescriptionType === "repeat-dispensing",
);

const showReviewDate = computed(
  () =>
    prescription.value.prescriptionType === "variable-repeat" ||
    prescription.value.prescriptionType === "repeat" ||
    prescription.value.prescriptionType === "repeat-dispensing",
);

const isReauthorising = computed(
  () => ["re-authorise", "prescribe-again"].indexOf(store.prescriptionFormType) !== -1,
);

// NOTE BUSINESS RULE FROM NHS!
const MaximumNumberOfIssuesForRepeatDispensing = 99;
const MinimumNumberOfIssuesForRegularRepeatAndRepeatDispensingTypes = 1;

const repeatDispensingFailsOnMaximumNumberOfIssues = computed(() => {
  if (prescription.value.prescriptionType !== "repeat-dispensing") return false;
  return prescription.value.numberOfIssues! > MaximumNumberOfIssuesForRepeatDispensing;
});

const repeatDispensingFailsOnNumberOfIssues = computed(() => {
  if (prescription.value.prescriptionType !== "repeat-dispensing") return false;
  return (
    repeatDispensingFailsOnMaximumNumberOfIssues.value ||
    prescription.value.numberOfIssues! <
      MinimumNumberOfIssuesForRegularRepeatAndRepeatDispensingTypes
  );
});

const repeatFailsOnNumberOfIssues = computed(() => {
  if (prescription.value.prescriptionType !== "repeat") return false;
  return (
    prescription.value.numberOfIssues! <
    MinimumNumberOfIssuesForRegularRepeatAndRepeatDispensingTypes
  );
});

const minimumIssuesTextForRepeatTypes = computed(() => {
  if (prescription.value.prescriptionType == "repeat")
    return "Repeat prescriptions must have a minimum of 1 authorised issue.";
  return "Repeat dispensing prescriptions must have a minimum of 1 authorised issue.";
});

// NOTE BUSINESS RULE FROM NHS!
const MaximumAllowedExpectedDaysForRepeatDispensing = 365;
const repeatDispensingFailsOnDuration = computed(() => {
  if (prescription.value.prescriptionType !== "repeat-dispensing") return false;
  return prescription.value.expectedDaysSupply && prescription.value.numberOfIssues
    ? +prescription.value.expectedDaysSupply * prescription.value.numberOfIssues >
        MaximumAllowedExpectedDaysForRepeatDispensing
    : false;
});

const expectedDaysSupplyRules = [
  (v: string) => {
    if (!v) return true;
    const d = +v;

    if (d < 1) {
      return "Expected days supply should be 1 or more";
    }

    const { dosageInstruction } = prescription.value;
    if (dosageInstruction.period.value && dosageInstruction.period.unit) {
      return (
        d >= +dosageInstruction.maximumPeriodInDays! ||
        "The expected days supply cannot be lower than the frequency of the dosage"
      );
    }
    return true;
  },
];

const selectedDosage = ref<SearchPredefinedDosagesQueryResult | null>(null);

const linkableProblems = computed(() => {
  if (!store.state.linkableProblems) return undefined;
  const sorted = [...store.state.linkableProblems].sort((a, b) => a.label.localeCompare(b.label));

  const archived = sorted.filter((x) => x.isArchived);
  const active = sorted.filter((x) => !x.isArchived);

  const result = [];
  if (active.length) result.push({ title: "Active Problems", items: active });
  if (archived.length) result.push({ title: "Inactive Problems", items: archived });
  return result;
});

const {
  result: prescribingStaff,
  loading: loadingPrescribingStaff,
  exec: refreshPrescribingStaff,
} = usePromiseLazy(
  (date: string, productCode: string) =>
    axios
      .get<{ activeStaff: BaseEnumTrait[] }>("/staff/data/active-prescribing-staff", {
        cancelRequestId: "active-prescribing-staff",
        params: { date, productCode },
      })
      .then((x) => x.data),
  true,
);

const areFieldsDisabled = computed(() => {
  return !store.drugOrDevice;
});

watch(
  () => store.prescription.expectedDaysSupply,
  () => {
    store.state.confirmationAcknowledgedAndProceed = false;
  },
);

watch(
  () => prescription.value.issueQuantity,
  () => {
    if (prescription.value.dosageInstruction?.useManualDosageText) {
      return;
    }

    store.state.confirmationAcknowledgedAndProceed = false;
  },
);

watch(
  () => store.drugOrDevice,
  async (data) => {
    if (!data) {
      store.resetPrescriptionStructure();
    }
  },
);

onMounted(() => {
  showAuthorisationInputs.value = !store.prescription.authorisedBy;
});

watch(
  () => store.prescription.authorisedBy,
  (authorisedBy) => {
    if (null === authorisedBy) {
      showAuthorisationInputs.value = true;
    }
  },
);

watch(
  () => prescription.value.dosageInstruction.useManualDosageText,
  async (useManualDosageText) => {
    if (useManualDosageText === true) {
      prescription.value.issueQuantity.value = null;
      store.state.confirmationAcknowledgedAndProceed = false;
    }
  },
);

watch(
  () => prescription.value.startDate,
  async (date) => {
    const { activeStaff } = (await refreshPrescribingStaff(date, prescription.value.productCode))!;
    if (activeStaff.findIndex((x) => x.value === prescription.value.authorisedBy) === -1) {
      prescription.value.authorisedBy = null;
    }
  },
);

watch(
  () => prescription.value.prescriptionType,
  async (type) => {
    switch (type) {
      case "repeat":
      case "repeat-dispensing":
        prescription.value.numberOfIssues = null;
        break;
      default:
        prescription.value.numberOfIssues = 1;
    }

    store.state.confirmationAcknowledgedAndProceed = false;
  },
);

function clearProblems() {
  store.form.newLinkedProblem = null;
  store.form.linkedProblemIds = [];
}
watch(isNewProblem, clearProblems);
watch(
  computedRepeatReauthoriseActionType,
  (action) => {
    if (action) {
      store.form.action = action;
    }
  },
  { immediate: true },
);
</script>
<template>
  <MCreateForm
    :key="formKey"
    class="create-prescription-form"
    :url="formUrl"
    :disable-submit="disableSubmit"
    hide-cancel
    no-close
    @success="onSuccess"
    @error="onError"
  >
    <template #footer>
      <m-layout-stack v-if="store.drugOrDevice" horizontal end full>
        <MButton
          v-if="!hideAddAnother"
          label="Create another"
          color="secondary"
          :disable="disableSubmit"
          :permission="{
            url: '/clinical/prescription/create/create-prescription/authorise',
            method: 'POST',
          }"
          type="submit"
          data-testid="add-another-button"
          @click="beforeSaveAndAddAnother"
        />
        <MButton
          v-if="showIssueLaterButton"
          :label="issueLaterLabel || 'Issue later'"
          :color="~[null, undefined].indexOf(issueLaterColor) ? 'secondary' : issueLaterColor"
          :disable="disableSubmit"
          :permission="{
            url: '/clinical/prescription/create/create-prescription/authorise',
            method: 'POST',
          }"
          type="submit"
          data-testid="issue-later-button"
          @click="beforeIssueLater"
        />
        <MButton
          v-if="!hideIssueNow"
          label="Authorise & continue"
          :disable="disableSubmit"
          :permission="{
            url: '/clinical/prescription/create/create-prescription/authorise',
            method: 'POST',
          }"
          type="submit"
          data-testid="issue-now-button"
          @click="beforeSubmit"
        />
      </m-layout-stack>
      <div v-else />
    </template>
    <CurrentAllergies data-testid="current-allergies" />
    <DrugOrDeviceSelector />
    <template v-if="!store.form.contextId && store.drugOrDevice">
      <m-section title="Indication">
        <m-search-select
          v-if="isNewProblem"
          v-model="store.form.newLinkedProblem"
          label="New problem code"
          search-url="clinical/gb/snomed/search/description/constrained?constrainingParentConcepts=404684003&excludeConstrainingConcepts=307824009"
          placeholder="Search problem codes"
          data-testid="create-new-problem"
          actions-left
          :disabled="areFieldsDisabled"
          :actions="[
            {
              label: 'Link to existing problem',
              click: toggleNewProblem,
              disabled: areFieldsDisabled,
              'data-testid': 'use-existing-problem-button',
            },
          ]"
        />
        <m-select-list
          v-else
          v-model="store.form.linkedProblemIds"
          outlined
          :options="linkableProblems"
          label="Linked problem"
          data-testid="link-to-problem"
          :render-text="(_, item) => item.label"
          :disabled="areFieldsDisabled"
          actions-left
          :actions="[
            {
              label: 'Add new',
              click: toggleNewProblem,
              disabled: areFieldsDisabled,
              'data-testid': 'create-new-problem-button',
            },
          ]"
        />
      </m-section>
    </template>
    <DosageInstructionInput
      v-if="store.drugOrDevice && true !== isReauthorising"
      name="prescription.dosageInstruction.dosageText"
      label="Free text dosage instructions"
      :manual-dosage="prescription.dosageInstruction.useManualDosageText"
      required
      autogrow
      dense
      maxlength="700"
      counter
      :disable="areFieldsDisabled"
    />
    <m-section v-else-if="store.drugOrDevice && true === isReauthorising" title="Dosage">
      <m-labeled-text :text="prescription.dosageInstruction.dosageText" />
    </m-section>

    <m-section v-if="store.drugOrDevice" title="Supply">
      <m-radio-group
        name="prescription.prescriptionType"
        label="Prescription type"
        required
        :items="prescriptionTypes"
        :disabled="areFieldsDisabled"
      />

      <m-input
        type="number"
        name="prescription.expectedDaysSupply"
        label="Expected days supply"
        suffix="days"
        :rules="expectedDaysSupplyRules"
        min="1"
        :required="isExpectedDaysSupplyRequired"
        debounce="500"
        :loading="store.issueQuantity.loading"
        data-testid="expectedDaysSupply"
        :disabled="areFieldsDisabled"
      />
      <m-input
        v-if="store.state.preventManualIssueQuantityEntry"
        name="prescription.issueQuantity.value"
        required
        data-testid="issue-quantity-fixed-input"
        type="number"
        label="Issue quantity"
        :suffix="prescription.issueQuantity.snomedCtCode?.description"
        :disabled="areFieldsDisabled"
      />
      <m-input-group
        v-else
        horizontal
        label="Issue quantity"
        left
        required
        data-testid="issue-quantity-input-group"
      >
        <m-input
          name="prescription.issueQuantity.value"
          required
          small
          type="number"
          label="Value"
          :disabled="areFieldsDisabled"
        />
        <m-simple-select
          name="prescription.issueQuantity.snomedCtCode"
          :options="store.unitOfMeasures"
          clearable
          required
          label="Unit"
          :disabled="areFieldsDisabled"
        />
      </m-input-group>

      <m-input
        v-if="showNumberOfIssues"
        name="prescription.numberOfIssues"
        label="Number of issues"
        required
        type="number"
        min="1"
        :min-text="minimumIssuesTextForRepeatTypes"
        :required-text="minimumIssuesTextForRepeatTypes"
        small
        :disabled="areFieldsDisabled"
      />
      <m-banner v-if="repeatDispensingFailsOnMaximumNumberOfIssues" type="warning">
        <p>
          A repeat dispensing prescription can have a maximum of
          {{ MaximumNumberOfIssuesForRepeatDispensing }} authorised issues. Please reduce the number
          of authorised issues or change the prescription type to repeat.
        </p>
      </m-banner>
      <m-banner
        v-if="repeatDispensingFailsOnDuration"
        type="warning"
        data-testid="repeat-dispensing-duration-banner"
      >
        <p>
          The total duration for repeat dispensing prescriptions can not exceed 12 months. Please
          reduce the number of authorised issues or change the prescription type to repeat.
        </p>
      </m-banner>

      <m-checkbox-group
        label="Prescriber endorsement"
        name="prescription.prescriberEndorsements"
        :items="store.state.allowablePrescriberEndorsements"
        :disable="areFieldsDisabled"
      />

      <m-input
        label="Additional instructions for dispenser"
        name="prescription.dispenserInstructions"
        :disabled="areFieldsDisabled"
      />
    </m-section>

    <m-section v-if="store.drugOrDevice" title="Authorisation">
      <m-description-list v-if="showAuthorisationInputs === false">
        <m-description-list-item
          label="Start date"
          :value="formatDate(prescription.startDate)"
          horizontal
        />
        <m-description-list-item
          v-if="showReviewDate"
          label="Review date"
          :value="formatDate(prescription.reviewDate)"
          horizontal
        />
        <m-description-list-item
          label="Authorising practitioner"
          :value="
            store.state.prescribingStaff?.find((staff) => staff.value === prescription.authorisedBy)
              ?.label
          "
          horizontal
        />
      </m-description-list>
      <m-banner
        v-if="store.state.authoriseOrganisationIsLocal == false"
        :disabled="areFieldsDisabled ? true : undefined"
        type="info"
      >
        <span>A prescriber from the local organisation must authorise the prescription.</span>
      </m-banner>
      <m-button
        v-if="showAuthorisationInputs === false"
        ghost
        :click="
          () => {
            showAuthorisationInputs = true;
          }
        "
        >Change</m-button
      >

      <template v-if="showAuthorisationInputs === true">
        <m-date
          label="Start date"
          required
          name="prescription.startDate"
          :disabled="areFieldsDisabled"
        />

        <m-date
          v-if="showReviewDate"
          name="prescription.reviewDate"
          label="Review date"
          :min-date="store.state.minimumReviewDate"
          :max-date="store.state.maximumReviewDate"
          :disabled="areFieldsDisabled"
        />
        <m-simple-select
          name="prescription.authorisedBy"
          label="Authorised by practitioner"
          required
          :options="prescribingStaff?.activeStaff ?? prescribingStaffList"
          :loading="loadingPrescribingStaff"
          :disabled="areFieldsDisabled"
        />

        <m-banner
          v-if="
            false === store.state.isInNurseFormulary &&
            false === store.state.actorCanPrescribeNonNurseFormularyProducts &&
            !prescription.authorisedBy
          "
          data-testid="nurse-formulary-restriction-banner"
        >
          You have been setup as a Community Nurse prescriber. The medication being prescribed is
          outside of the national nurse formulary and therefore cannot be authorised by you. Please
          contact your Medicus administrator if you think this is incorrect.
        </m-banner>
      </template>
    </m-section>

    <m-section v-if="store.drugOrDevice && !store.form.contextType" title="Confidentiality">
      <m-checkbox-group>
        <m-checkbox
          data-testid="confirm-confidential-from-third-parties"
          label="Confidential from third parties"
          name="confidentialFromThirdParties"
          :disable="areFieldsDisabled"
        />
        <m-checkbox
          data-testid="confirm-hidden-from-patient-facing-services"
          label="Redacted from patient online access"
          name="hiddenFromPatientFacingServices"
          :disable="areFieldsDisabled"
        />
      </m-checkbox-group>
    </m-section>

    <m-banner
      v-if="store.drugOrDevice && store.computedCountOfDangerTypeSafetyChecks"
      type="warning"
      data-testid="safety-alert-confirmation"
    >
      <p class="q-mb-md whitespace-pre-wrap">
        There are sensitivity alerts on the right hand side of this form indicating that this is a
        potentially risky prescription. Please review and acknowledge before proceeding.
      </p>

      <m-checkbox
        v-model="store.state.confirmationAcknowledgedAndProceedSafetyChecks"
        label="Acknowledge and proceed"
      />
    </m-banner>

    <m-banner v-if="store.drugOrDevice && confirmationRequiredMessage" type="warning">
      <p class="q-mb-md whitespace-pre-wrap">
        {{ confirmationRequiredMessage }}
      </p>

      <m-checkbox
        v-model="store.state.confirmationAcknowledgedAndProceed"
        label="Acknowledge and proceed"
      />
    </m-banner>

    <m-banner v-if="computedRepeatReauthoriseBannerMessage" type="info">
      {{ computedRepeatReauthoriseBannerMessage }}
    </m-banner>
  </MCreateForm>
</template>
<style lang="scss">
.create-prescription-form {
  .q-field__bottom--animated {
    right: inherit !important;
    padding-left: 0;
  }

  .q-field__counter {
    padding-left: 0;
  }
}
</style>
