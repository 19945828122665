<script setup lang="ts">
import { QIcon } from "quasar";

import { ref, toRefs } from "vue";
import { MedicusAttachment, useFileUploader } from "../../../../composables/fileUploader";
import { useInputRules } from "../../../../composables/horizontalLabel";
import { validAttribute } from "../../../../utils/misc";
import MValidationComponent from "./../MValidationComponent";

import MButton from "../../MButton";
import MLabeledText from "../../MLabeledText";
import MLayoutStack from "../../MLayoutStack";
import FileDisplay from "./FileDisplay.vue";

const props = defineProps({
  label: String,
  maxSize: {
    type: String,
    default: "10MB",
  },
  accept: {
    type: String,
    default:
      "application/msword,application/vnd.ms-excel,application/vnd.ms-powerpoint,text/plain, application/pdf, image/*",
  },
  multiple: Boolean,
  maxFiles: Number,
  helpText: {
    type: String,
    default: "Choose files or drag & drop here",
  },
  modelValue: {
    type: [Array, Object, null] as unknown as () =>
      | MedicusAttachment
      | Array<MedicusAttachment>
      | null,
    required: true,
    // default: (props: any) => {
    //   return props.multiple ? [] : undefined;
    // },
  },
  downloadUrl: String,
  uploadUrl: String,
  beforeUpload: Function,
  actions: [Array, Object],
  actionsLeft: Boolean,
  deleteItem: Function,
  helper: String,
  instructions: String,
  rules: Array,
  requiredText: String,
  showAllErrors: Boolean,
  name: String,
  invalidExtensionText: {
    required: false,
    type: String,
    default: "Unsupported file type",
  },
});

const emit = defineEmits({
  input: (_e: any) => true,
  "update:modelValue": (_e: any) => true,
});

// setup(props, ctx) {
const { value, displayErrors } = useInputRules(
  props,
  () => {},
  [
    (e: MedicusAttachment | Array<MedicusAttachment>) => {
      const isValid = (e: MedicusAttachment & { __uploading: any }) => {
        if (!e?.__uploading?.error) return true;
        const error: Record<string, string> = e?.__uploading?.error;

        if (error.size) {
          return `Maximum file size ${props.maxSize}`;
        }

        if (error.extension) {
          return props.invalidExtensionText;
        }

        return "Error uploading the file";
      };

      return Array.isArray(e)
        ? e.map((e: any) => isValid(e)).find((x) => x !== true) || true
        : isValid(e as any);
    },
  ],
  undefined,
  () => props.requiredText || `Choose a file to upload for '${props.label}'`,
);
const { beforeUpload, deleteItem, multiple, uploadUrl, maxSize, accept } = toRefs(props);
const { input, dragging, dragover, dropped, onInputFile, onDeleteItem } = useFileUploader(
  value,
  maxSize,
  beforeUpload!,
  deleteItem!,
  multiple,
  uploadUrl!,
  { emit },
  accept,
);
function onFileInput(e: InputEvent) {
  // ignore if the items was just dropped
  if (dropped.value) return;
  // @ts-expect-error
  return onInputFile(e.target.files);
}

//     return {
//       value,
//       valid,
//       displayErrors,
//       input,
//       dragging,
//       dragover,

//       onFileInput,
//       onDeleteItem,

//       validAttribute,
//     };
//   },
// });
const fileInputRef = ref<HTMLInputElement>();

defineExpose({
  fileInputRef,
});
</script>
<template>
  <m-validation-component
    :errors="displayErrors"
    :class="$attrs.class"
    :actions="actions"
    :actions-left="actionsLeft"
  >
    <m-labeled-text
      :class="{
        'file-uploader': true,
        disabled: validAttribute($attrs.disabled || $attrs.disable),
      }"
      :label="label"
      :helper="helper"
      :instructions="instructions"
      :required="$attrs.required"
    >
      <template v-if="$slots.helper" #helper>
        <slot name="helper" />
      </template>

      <m-layout-stack full>
        <template v-if="multiple">
          <transition-group
            v-if="value && Array.isArray(value) && value.length > 0"
            tag="span"
            class="file-list flex-col gap-1"
          >
            <template v-for="(item, index) in value" :key="item.__key || item.id">
              <file-display
                :class="{
                  'file-item--border': index,
                }"
                :item="item"
                :max-size="props.maxSize"
                :invalid-extension-text="props.invalidExtensionText"
                @delete="onDeleteItem(item)"
              />
            </template>
          </transition-group>
        </template>

        <file-display
          v-else-if="value && !Array.isArray(value)"
          :item="value"
          :max-size="props.maxSize"
          :invalid-extension-text="props.invalidExtensionText"
          @delete="onDeleteItem(value)"
        />

        <div
          v-if="(!multiple && !value) || multiple"
          ref="input"
          class="input-button"
          :class="{
            dragging,
            dragover,
          }"
          draggable="false"
        >
          <m-layout-stack v-if="dragging" class="q-mx-md dragging-container" gap="1">
            <m-layout-stack class="justify-center" horizontal gap="1">
              <q-icon :name="multiple ? 'fa-solid fa-files' : 'fa-solid fa-file-arrow-up'" />
              Drop file{{ multiple ? "s" : "" }} here
            </m-layout-stack>
          </m-layout-stack>
          <m-layout-stack v-else class="q-mx-md" gap="1">
            <m-layout-stack class="justify-center" horizontal gap="1">
              <q-icon :name="multiple ? 'fa-solid fa-files' : 'fa-solid fa-file-arrow-up'" />
              Drag & drop file{{ multiple ? "s" : "" }} here
            </m-layout-stack>
            <m-layout-stack horizontal gap="1">
              <div>or</div>
              <div class="browse-button-container">
                <m-button ghost label="Browse from your computer" />
                <input
                  v-if="!validAttribute($attrs.disabled || $attrs.disable)"
                  ref="fileInputRef"
                  type="file"
                  :accept="accept"
                  class="file-input"
                  :multiple="multiple"
                  @input="onFileInput"
                />
              </div>
            </m-layout-stack>
          </m-layout-stack>
        </div>
      </m-layout-stack>
    </m-labeled-text>
  </m-validation-component>
</template>

<style lang="scss">
.file-uploader {
  .file-list {
    display: flex;

    .file-list-item {
      padding-bottom: 0.5rem;
      border-bottom: 1px solid var(--grey-light);
    }
  }
  .input-button {
    position: relative;
    display: flex;
    align-items: center;
    // padding: 30px 0;
    width: 100%;

    height: 86px;

    color: var(--grey-darkest);

    border: 2px dashed var(--grey-lightest-non-text);
    box-sizing: border-box;
    border-radius: 4px;

    justify-content: center;

    .q-icon {
      font-size: 1.4rem !important;
      // margin-right: 1rem;
    }

    // &:hover {
    //   border-color: var(--grey-darker);
    //   cursor: pointer;
    // }

    // &.dragover::before {
    //   content: " ";
    //   position: absolute;
    //   left: 0;
    //   top: 0;
    //   width: 100%;
    //   height: 100%;
    //   box-shadow: inset 0 0 10px -1px #007acc;
    // }

    &.dragging {
      background: var(--grey-lightest);
    }

    .browse-button-container {
      position: relative;
    }

    .file-input {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 10;
      cursor: pointer;
    }
  }

  .file-item--border {
    border-top: 1px solid var(--border-colour);
  }

  .fa-trash-can {
    color: var(--status-red) !important;
  }
}
</style>
