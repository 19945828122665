<script setup lang="ts">
import { computed, nextTick, onBeforeMount, onMounted, onUnmounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useApp } from "../store";
import { useAuth } from "../store/auth";
import MLayoutStack from "../components/medicus/MLayoutStack";
import MButton from "../components/medicus/MButton";
import { updateUserCurrentLocation } from "../api/iam";
import MSimpleSelect from "../components/medicus/inputs/MSimpleSelect/MSimpleSelect.vue";

const store = useAuth();

useApp().updateTitle("Choose Workspace");

const organisationsSelectRef = ref<typeof MSimpleSelect | null>(null);
const locationsSelectRef = ref<typeof MSimpleSelect | null>(null);

const router = useRouter();
const loading = ref(false);
const organisation = ref<string>("");
const currentLocation = ref<any | null>(store.currentLocationValue);
const locationType = ref<string | null>(null);

const removeCbs = [
  router.afterEach(() => {
    loading.value = false;
  }),

  router.beforeEach(() => {
    loading.value = true;
  }),
];

onBeforeMount(async () => {
  await store.refreshUserInfo();
  setInitialOrganisation();
});

onMounted(() => {
  nextTick(() => {
    organisationsSelectRef.value?.focus();
  });
});

onUnmounted(() => {
  removeCbs.map((x) => x());
});

const formattedOrganisationOptions = computed(() => {
  const tenants = store.tenants;
  if (!tenants) return [];

  return Object.keys(tenants).map((key) => ({
    label: tenants[key].name,
    value: key,
  }));
});

const hasLocations = computed(() => {
  const user = store.user;
  if (!user) return false;

  const { sites, rooms, adHocLocations } = user;
  return [sites, rooms, adHocLocations].some((loc) => Array.isArray(loc) && loc.length > 0);
});

const locationOptions = computed(() => {
  let data = [];

  if (store.user) {
    if (store.user.sites?.length) {
      data.push({
        title: "Sites",
        items: store.user.sites,
      });
    }

    if (store.user.rooms?.length) {
      data.push({
        title: "Rooms",
        items: store.user.rooms,
      });
    }

    if (store.user.adHocLocations?.length) {
      data.push({
        title: "Ad Hoc Locations",
        items: store.user.adHocLocations,
      });
    }
  }
  return data;
});

watch(currentLocation, (newValue) => {
  // Determine the location type based on the selected option
  const selectedOption = locationOptions.value.find(
    (x) => !!x.items.find((option) => option.value === newValue),
  );
  if (selectedOption) {
    if (selectedOption.title === "Sites") {
      locationType.value = "site";
    } else if (selectedOption.title === "Rooms") {
      locationType.value = "room";
    } else if (selectedOption.title === "Ad Hoc Locations") {
      locationType.value = "ad-hoc-location";
    }
  } else {
    locationType.value = null;
  }
});

function setInitialOrganisation() {
  // use organsation from data store if set
  if (store.currentTenant?.name) {
    const currentTenantOption = formattedOrganisationOptions.value.find(
      (option) => option.label === store.currentTenant?.name,
    );

    if (currentTenantOption) {
      organisation.value = currentTenantOption.value;
      return;
    }
  }

  // otherwise select first option in list
  organisation.value = formattedOrganisationOptions.value[0].value;
}

const saveAndRedirectToWorkspace = async () => {
  try {
    // POST selected location
    await updateUserCurrentLocation({
      locationId: currentLocation.value ?? null,
      locationType: locationType.value ?? null,
    });

    // Refresh user info to get latest location data
    await store.refreshUserInfo();

    // Redirect to tenant workspace
    router.push({
      name: "tenant",
      params: {
        tenant: organisation.value,
      },
    });
  } catch (error) {
    console.error("Error:", error);
  }
};
</script>
<template>
  <m-layout-stack>
    <m-simple-select
      ref="organisationsSelectRef"
      v-model="organisation"
      label="Organisation"
      :options="formattedOrganisationOptions"
      required
      @keydown.capture.enter="
        (e) => {
          if (!organisationsSelectRef?.isOpen) {
            e.stopPropagation();
            nextTick(() => saveAndRedirectToWorkspace());
          }
        }
      "
    />
    <m-simple-select
      v-if="hasLocations"
      ref="locationsSelectRef"
      v-model="currentLocation"
      label="Current location"
      :options="locationOptions"
      clearable
      @keydown.capture.enter="() => nextTick(() => saveAndRedirectToWorkspace())"
    />
    <m-button label="Continue" @click="saveAndRedirectToWorkspace" />
  </m-layout-stack>
</template>

<style lang="scss" scoped>
.q-item {
  min-height: 35px;
  padding: 8px 8px;
  margin: 5px auto;
  border-radius: 4px;
  width: 100%;
}

.workspace-list {
  padding-top: 10px;
}

ul {
  padding-inline-start: 0;
  list-style-type: none;
}

.workspace-item {
  color: var(--text-color) !important;
  padding: 6px !important;
  min-height: 25px !important;
  margin: 9px auto !important;
  max-width: 320px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: -5px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #e5e7eb;
  }

  &:last-child {
    &:after {
      content: "";
      position: absolute;
      bottom: -5px;
      left: 0;
      right: 0;
      height: 1px;
      background-color: #e5e7eb;
    }
  }

  .q-item__section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .workspace-avatar {
      flex: 1;
      margin-right: 10px;
      max-width: 27px;
      height: 27px;
      display: flex;
      align-items: center;
      justify-content: center;

      .q-avatar-content {
        line-height: 100% !important;
      }
    }

    .workspace-name {
      flex-grow: 1;
      max-width: 210px;
      line-height: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    i {
      margin-left: auto;
      color: #939598;
      // font-size: 22px !important;

      &.active {
        color: var(--grey-darker);
        font-size: 20px !important;
      }
    }
  }

  &.workspace-item-selected {
    font-family: Arial, sans-serif;
    font-style: normal;
    font-size: 14px;
    /* identical to box height, or 143% */

    /* Primary / Blue Dark / 100 */

    color: var(--text-color);
  }
}
</style>
