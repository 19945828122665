<template>
  <m-card
    :action="{
      label: 'View report',
      click: openInvestigation,
    }"
    :class="{
      'marked-incorrect': investigationReport.isMarkedIncorrect,
    }"
  >
    <m-layout-stack gap="1">
      <m-layout-stack flex horizontal>
        <div class="flex-auto">
          <m-layout-stack horizontal>
            <div class="flex-auto">
              <b>Investigation results</b> • {{ investigationReport.requestedBy }}
            </div>
            <i v-if="investigationReport.isRetrospectivelyAmended" style="white-space: nowrap">{{
              formatRetrospectivelyAmendedLabel
            }}</i>
          </m-layout-stack>
        </div>
      </m-layout-stack>
      <m-layout-stack v-if="showBadgesRow" horizontal>
        <m-badge
          v-if="investigationReport.filingStatus === 'Pending'"
          class="amber status-badge"
          text="Awaiting Filing"
          data-testid="investigation-presenter-badge"
        />
        <m-badge
          v-if="investigationReport.hiddenFromPatientFacingServices"
          text="Redacted from patient online access"
          gray
          data-testid="topic-hidden-from-patient-facing-services-badge"
        />
        <m-badge
          v-if="investigationReport.confidentialFromThirdParties"
          text="Confidential from Third Parties"
          gray
          data-testid="topic-confidential-from-third-parties-badge"
        />
        <m-badge
          v-for="(linkedProblem, index) in investigationReport.linkedProblems"
          :key="`investigation-report-linked-problem-${index}`"
          :text="linkedProblem.problemCodeDescription"
          gray
          :data-testid="`investigation-report-linked-problem-badge-${index}`"
        />
      </m-layout-stack>
    </m-layout-stack>
    <m-separator class="q-mt-sm" />

    <!--      GROUPS-->
    <m-layout-stack gap="0">
      <div
        v-for="group in investigationReport.investigationGroups"
        :key="`group-result-card-${group.id}`"
        class="q-mt-sm q-ml-sm"
      >
        <p>
          {{ group.label }} •
          {{ group.groupCollectionDateDisplayText }}
        </p>

        <!--      TABLE-->
        <m-simple-table v-if="group.results.length" no-border>
          <thead>
            <tr>
              <th class="investigation-result-cell-1">Investigation</th>
              <th class="investigation-result-cell-2">Result</th>
              <th class="investigation-result-cell-3">Reference Range</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(result, i) in getRowOriginalData(group.results)" :key="i" class="data-row">
              <td class="investigation-result-cell-1">
                <m-link :to-slideover="result.investigation.url">{{
                  result.investigation.text
                }}</m-link>
              </td>
              <td class="investigation-result-cell-2">
                <span v-if="typeof result.result === 'string'" class="text-cursor">{{
                  result.result
                }}</span>
                <m-badge v-else-if="result.result?.requiresUrgentReview" amber>
                  <i
                    v-if="outOfRangeResultIcon(result.result)"
                    :class="outOfRangeResultIcon(result.result)"
                  />
                  {{ formatResultLabel(result.result) }}
                </m-badge>
                <span v-else class="text-cursor">{{ formatResultLabel(result.result) }}</span>
              </td>
              <td class="investigation-result-cell-3">
                <m-layout-stack v-if="result?.referenceRanges?.referenceRanges?.length" no-gap>
                  <div
                    v-for="(referenceRange, index) in result.referenceRanges.referenceRanges"
                    :key="index"
                    class="reference-range"
                  >
                    <span class="text-cursor">
                      {{ formatReferenceRange(referenceRange) ?? " - " }}
                    </span>
                  </div>
                </m-layout-stack>
                <div v-else>-</div>
              </td>
            </tr>
          </tbody>
        </m-simple-table>

        <m-layout-stack
          v-if="group.multilineResults.length"
          data-testid="text-result-test"
          :class="{ 'marked-incorrect text-grey': investigationReport.isMarkedIncorrect }"
          class="q-mt-sm"
        >
          <m-layout-stack
            v-for="result in group.multilineResults"
            :key="`multiline-result-${result.id}`"
          >
            <m-link :text="result.investigationResultLabel" :to-slideover="result.url" />
          </m-layout-stack>
        </m-layout-stack>
      </div>
    </m-layout-stack>

    <!--   UNGROUPED RESULTS-->
    <div
      v-for="result in investigationReport.ungroupedResults"
      :key="`ungrouped-result-card-${result.id}`"
      class="q-mt-sm q-ml-sm"
    >
      <!--      TABLE-->
      <div
        v-if="
          result.resultType !== 'text-result' ||
          (result.resultText.length < 100 && !result.isMultilineResult)
        "
      >
        <p>
          {{ result.investigationResultLabel }} •
          {{ result.collectionDateDisplayText }}
        </p>
        <m-simple-table no-border>
          <thead>
            <tr>
              <th class="investigation-result-cell-1">Investigation</th>
              <th class="investigation-result-cell-2">Result</th>
              <th class="investigation-result-cell-3">Reference Range</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(result, i) in getRowOriginalData([result])" :key="i" class="data-row">
              <td class="investigation-result-cell-1">
                <m-link :to-slideover="result.investigation.url">{{
                  result.investigation.text
                }}</m-link>
              </td>
              <td class="investigation-result-cell-2">
                <span v-if="typeof result.result === 'string'" class="text-cursor">{{
                  result.result
                }}</span>
                <m-badge v-else-if="result.result?.requiresUrgentReview" amber>
                  <i
                    v-if="outOfRangeResultIcon(result.result)"
                    :class="outOfRangeResultIcon(result.result)"
                  />
                  {{ formatResultLabel(result.result) }}
                </m-badge>
                <span v-else class="text-cursor">{{ formatResultLabel(result.result) }}</span>
              </td>
              <td class="investigation-result-cell-3">
                <m-layout-stack v-if="result?.referenceRanges?.referenceRanges?.length" no-gap>
                  <div
                    v-for="(referenceRange, index) in result.referenceRanges.referenceRanges"
                    :key="index"
                    class="reference-range"
                  >
                    <span class="text-cursor">
                      {{ formatReferenceRange(referenceRange) ?? " - " }}
                    </span>
                  </div>
                </m-layout-stack>
                <div v-else>-</div>
              </td>
            </tr>
          </tbody>
        </m-simple-table>
      </div>
      <!-- MULTILINE RESULTS -->
      <m-layout-stack v-else gap="0">
        <p>
          {{ result.investigationResultLabel }} •
          {{ result.collectionDateDisplayText }}
        </p>
        <m-button v-if="result.url" :to-slideover="result.url" ghost>{{
          result.investigationResultLabel
        }}</m-button>
      </m-layout-stack>
    </div>

    <!--    FILING COMMENTS -->
    <div v-if="investigationReport.filingComments?.length" class="q-mx-sm q-my-none">
      <p>Filing Notes</p>
      <ul>
        <li
          v-for="(filingComment, index) in investigationReport.filingComments"
          :key="`investigation-filing-comment-${index}`"
        >
          {{
            filingComment.resultDescription
              ? `${filingComment.resultDescription} - ${filingComment.comment}`
              : filingComment.comment
          }}
          <i>({{ formattedFilingCommentAuthor(filingComment) }})</i>
        </li>
      </ul>
    </div>
  </m-card>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { openModal } from "../../../../composables/dialog/drawer";
import MCard from "../../MCard";
import MSeparator from "../../MSeparator";
import MLayoutStack from "../../MLayoutStack";
import MSimpleTable from "../../MSimpleTable/MSimpleTable.vue";
import MLink from "../../MLink";

export type InvestigationDetailsItem = {
  description: string;
};

export type FilingComment = {
  id: string;
  isRetrospectivelyAmended: boolean;
  isMarkedIncorrect: boolean;
  responsiblePractitioner: string;
  responsibleOrganisation: string;
  recordAuthorIsLocal: boolean;
  resultDescription: string;
  comment: string;
};

export type InvestigationJournalItem = {
  hiddenFromPatientFacingServices: boolean;
  confidentialFromThirdParties: boolean;
  linkedProblems: { problemCodeDescription: string }[];
  id: string;
  isMarkedIncorrect: boolean;
  requestedBy: string;
  atLeastOneResultOutsideOfReferenceRange: boolean;
  filingComments: FilingComment[];
  ungroupedResults: [];
  filingStatus: string;
  isRetrospectivelyAmended: boolean;
  investigationDetails: InvestigationDetailsItem[];
};

const props = defineProps<{
  investigationReport: InvestigationJournalItem;
  readOnly: boolean;
  isPatientRecordView?: boolean;
}>();

defineEmits(["investigationClick"]);

const formatRetrospectivelyAmendedLabel = computed(() => {
  let label = [];

  if (props.investigationReport.isRetrospectivelyAmended) {
    label.push("Edited");
  }

  return label.join(" • ");
});

const showBadgesRow = computed(() => {
  return (
    props.investigationReport.filingStatus === "Pending" ||
    props.investigationReport.hiddenFromPatientFacingServices ||
    props.investigationReport.confidentialFromThirdParties ||
    props.investigationReport.linkedProblems?.length
  );
});

function getRowOriginalData(results) {
  let data = [];

  if (results && results instanceof Array && results.length > 0) {
    // Add results rows
    data = results.map((result) => {
      const row = {
        investigation: {
          text: result.investigationResultLabel,
          url: result.url,
        },
        result,
        referenceRanges: {
          referenceRanges: result.referenceRanges,
          resultUnit: result.resultUnit,
        },
      };

      return row;
    });
  }
  return data;
}

function formattedFilingCommentAuthor(filingComment: FilingComment) {
  let formattedAuthorLabel = [];
  let responsiblePractitioner = filingComment.responsiblePractitioner ?? "Unknown practitioner";

  formattedAuthorLabel.push(responsiblePractitioner);

  if (filingComment.isRetrospectivelyAmended) {
    formattedAuthorLabel.push("Edited");
  }

  return formattedAuthorLabel.join(" • ");
}

function openInvestigation() {
  openModal(`/clinical/investigation/investigation-overview/${props.investigationReport.id}`, {
    close: true,
  });
}

function outOfRangeResultIcon(item) {
  let icon;
  if (item.resultLowerThanReferenceRange) {
    icon = "fa-solid fa-arrow-down";
  } else if (item.resultHigherThanReferenceRange) {
    icon = "fa-solid fa-arrow-up";
  }
  return icon;
}

function formatResultLabel(result) {
  let resultLabel = "";
  if (result.resultValue) {
    if (result.approximateResult) {
      resultLabel += "~";
    }
    if (result.resultComparator) {
      resultLabel += result.resultComparator + " ";
    }
    resultLabel += result.resultValue;
    if (result.resultUnit === "%" || result.resultUnit === "°C") {
      resultLabel += result.resultUnit;
    } else if (result.resultUnit) {
      resultLabel += " " + result.resultUnit;
    }
  } else if (result.resultCodedDescription) {
    resultLabel += (resultLabel !== "" ? "\n" : "") + result.resultCodedDescription;
  } else if (result.resultText && !result.isMultilineResult) {
    resultLabel += (resultLabel !== "" ? "\n" : "") + result.resultText;
  } else {
    resultLabel = "-";
  }
  return resultLabel;
}

function formatReferenceRange(referenceRange) {
  let referenceRangeLabel = "";
  if (referenceRange.lowerReferenceLimit && referenceRange.upperReferenceLimit) {
    referenceRangeLabel += `${referenceRange.lowerReferenceLimit} – ${referenceRange.upperReferenceLimit}`;
  } else if (referenceRange.lowerReferenceLimit) {
    referenceRangeLabel += `≥ ${referenceRange.lowerReferenceLimit}`;
  } else if (referenceRange.upperReferenceLimit) {
    referenceRangeLabel += `≤ ${referenceRange.upperReferenceLimit}`;
  }
  if (referenceRange.description) {
    referenceRangeLabel += `: ${referenceRange.description}`;
  }
  if (referenceRangeLabel === "") {
    return false;
  }
  return referenceRangeLabel;
}
</script>
<style lang="scss">
.status-badge {
  align-self: start;
}
.high-priority {
  font-size: 1.2rem !important;

  flex: none;
  align-self: center;
  color: var(--yellow-dark-non-text);

  margin: 0 8px;
}
.grey-darkest {
  color: var(--grey-darkest);
}
.card-description {
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #68778d;
}

.investigation-details {
  margin-left: var(--card-padding);
  right: var(--card-padding);
}

.investigation-warning {
  margin-bottom: 10px;
}
.investigation-result-cell-1 {
  min-width: 100px;
  max-width: 100px;
  padding: 0px !important;
}
.investigation-result-cell-2 {
  min-width: 100px;
  max-width: 100px;
  padding: 0px !important;
}
.investigation-result-cell-3 {
  min-width: 100px;
  max-width: 100px;
  padding: 0px !important;
}
.data-row {
  height: 28px !important;
  min-height: 28px !important;
}
</style>
