import { NO_OP, isObject, isPromise, isString, usePromiseLazy } from "vue-composable";
import { DrawerUrlOptions } from "../store/drawers";
import { RouteLocationRaw, Router } from "vue-router";
import { hasPermission } from "../utils/permission";
import { computed, getCurrentInstance, ref, watch } from "vue";
import { openModal, openSlideover } from "./dialog/drawer";

export type PropLike = {
  // TODO add type name
  to?:
    | string
    | { url: string; name?: string; method?: string }
    | { name: string }
    | RouteLocationRaw;
  permission?: string | true | "true" | { url: string; method?: string };

  toModal?: string | DrawerUrlOptions | object | false;
  toSlideover?: string | DrawerUrlOptions | object | false;

  disable?: boolean;
  disabled?: boolean;

  loading?: boolean | undefined;

  click?: ((e: MouseEvent) => void) | unknown;
  onClick?: ((e: MouseEvent) => void) | unknown;
};

/**
 * Handle Permission for toModal/toSlideover/to
 * @param props component props
 * @param handleTo if should handle `to` prop, not used if we use `a link` or render <router-link>
 * @param submitting if we should disable permission, most commonly used in a button on submit
 * @param router
 * @returns {hasLink, disabled, onClick} , onClick does not need to be used directly on `@click`
 */
export function usePermission(
  props: PropLike,
  handleTo: boolean,
  router: Router,
  submitting = ref(false),
) {
  const emit = getCurrentInstance()?.emit || NO_OP;

  const link = computed(() => props.permission || props.to || props.toModal || props.toSlideover);
  const hasLink = computed(() => (loading.value || !link.value ? false : hasAccess.value));

  const disabled = computed(() => {
    return props.disabled ||
      props.disable ||
      props.loading ||
      submitting.value ||
      (!hasLink.value && link.value)
      ? true
      : undefined;
  });

  const {
    result: hasAccess,
    exec,
    loading,
  } = usePromiseLazy(async (to: any) => {
    let method = "GET";
    if (!to) {
      return true;
    }
    let toUrl = "";
    if (isString(to)) {
      toUrl = to;
    } else if (isObject(to)) {
      toUrl = to.url || router.resolve(to)?.href;
      method = to.method;
    } else {
      return true;
    }
    return await hasPermission(toUrl, method);
  });

  watch(
    link,
    (p, o) => {
      if (!p) {
        return exec(p);
      }
      if (
        typeof p !== typeof o ||
        (isString(p) && isString(o) && p !== o) ||
        (isObject(p) &&
          isObject(o) &&
          (p?.url !== o?.url ||
            ("method" in p || "method" in o ? (p as any)?.method !== (o as any)?.method : false)))
      ) {
        exec(p);
      }
    },
    { immediate: true },
  );

  async function onClick(ev: MouseEvent | KeyboardEvent) {
    if (disabled.value) return;
    const click = (props.click || props.onClick) as (e: MouseEvent | KeyboardEvent) => void;
    if (click) {
      const res = click(ev);
      if (isPromise(res)) {
        submitting.value = true;
        try {
          await res;
        } finally {
          submitting.value = false;
        }
        return;
      }
      return;
    }

    if (props.toModal) {
      const [url, options] = isObject(props.toModal)
        ? [props.toModal.url, props.toModal]
        : [props.toModal, {}];

      if (options?.stopEventPropagation === true) {
        ev.stopPropagation();
      }

      return openModal(url, options);
    }
    if (props.toSlideover) {
      const [url, options] = isObject(props.toSlideover)
        ? [props.toSlideover.url, props.toSlideover]
        : [props.toSlideover, {}];

      if (options?.stopEventPropagation === true) {
        ev.stopPropagation();
      }

      return openSlideover(url, options);
    }

    if (!handleTo) {
      emit("click", ev);
      return;
    }

    if (props.to) {
      return router.push(props.to);
    } else {
      emit("click", ev);
    }
  }

  return {
    hasAccess,

    hasLink,
    disabled,

    onClick,
  };
}
