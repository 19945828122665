<template>
  <div
    :class="[
      {
        clickable: !readOnly,
        'marked-incorrect': item.isMarkedIncorrect,
      },
    ]"
  >
    <m-layout-stack no-gap>
      <m-layout-stack horizontal justify-between>
        <div>
          <span>{{ communicationTitle }}</span>
          <span>{{ expandedCommunicationTitle }}</span>
          <span v-if="showMessage" style="white-space: pre; text-wrap: wrap">
            • {{ item.communication.messageBody }}
          </span>
        </div>
        <i v-if="item.isRetrospectivelyAmended && !isInConsultation" style="white-space: nowrap">
          Edited
        </i>
        <m-badge
          v-for="badge in formattedBadges"
          :key="badge"
          :text="badge.text"
          :amber="badge.color === 'amber' ? true : undefined"
          :data-testid="badge['data-testid']"
        />
      </m-layout-stack>
    </m-layout-stack>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import MLayoutStack from "../../MLayoutStack";
import MBadge from "../../MBadge";

export type CommunicationJournalItem = {
  confidentialFromThirdParties: boolean;
  isExplicitlyIncludedInSCR: boolean;
  linkedProblems: { problemCodeDescription: string }[];
  hiddenFromPatientFacingServices: boolean;
  isMarkedIncorrect: boolean;
  communication: any;
  snomedCTCode: any;
  isRetrospectivelyAmended: boolean;
  communicationThreadSiblingsCount: number;
  contextId: string;
  contextType: string;
};

const props = defineProps<{
  item: CommunicationJournalItem;
  readOnly: boolean;
  contextHiddenFromPatientFacingServices?: boolean;
  contextConfidentialFromThirdPartyServices?: boolean;
}>();

const isInConsultation = computed(() => {
  return (
    props.item.contextType?.isConsultationTopic ||
    props.item.contextType?.isConsultationTopicHeading
  );
});

const showMessage = computed(() => {
  if (props.item.communication.direction.isOutbound && props.item.showMessageOnJournal) {
    return true;
  }

  return (
    props.item.communication.direction.isInbound &&
    props.item.inboundCareRecordCommunicationContext.isInboundCommunication
  );
});

const communicationTitle = computed(() => {
  let entryName;

  if (props.item.communication.direction.isOutbound) {
    entryName = "Communication";
  } else {
    entryName = props.item.outboundCareRecordCommunicationTitle ?? "Inbound communication";
  }

  return `${entryName}${props.item.snomedCTCode ? `:` : ""}`;
});

const expandedCommunicationTitle = computed(() => {
  let parts = [];

  if (props.item.snomedCTCode) {
    parts.push(` ${props.item.snomedCTCode}`);
  }

  if (!isInConsultation.value && props.item.communication.direction.isOutbound) {
    parts.push(` • Sent by ${props.item.sentBy ?? "Unknown"}`);
  }

  return parts.join(" ");
});

const formattedBadges = computed(() => {
  const badges = [];

  if (props.item.communication.displayStatus?.isDeliveryFailed) {
    badges.push({
      text: "Delivery Failed",
      color: "amber",
    });
  }
  return badges;
});
</script>
