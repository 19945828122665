<template>
  <m-card
    :class="{ 'cursor-pointer': readOnly }"
    sr-label="View note"
    @click="
      props.readOnly
        ? openSlideover(`/clinical/note/overview/${props.entry.id}`, {
            close: true,
          })
        : undefined
    "
  >
    <m-layout-stack
      :class="[
        {
          clickable: !readOnly,
          'marked-incorrect': entry.data.isMarkedIncorrect,
        },
      ]"
      horizontal
    >
      <div>
        <span v-if="entry.data.clinicalCodeDescription">
          <b>Note:</b> {{ entry.data.clinicalCodeDescription }}
        </span>
        <span v-else><b>Note</b></span>
        <span v-if="getRecordedBy"> • {{ getRecordedBy }}</span>
        <i v-if="entry.data.isRetrospectivelyAmended" style="white-space: nowrap">{{
          formatRetrospectivelyAmendedLabel
        }}</i>
      </div>
    </m-layout-stack>
    <m-layout-stack
      v-if="
        entry.data.hiddenFromPatientFacingServices ||
        entry.data.confidentialFromThirdParties ||
        entry.data.linkedProblems?.length ||
        entry.data.isExplicitlyIncludedInSCR
      "
      horizontal
      wrap
    >
      <span class="secondary-text">
        {{ getConfidentialityTextAndLinkedProblems() }}
      </span>
    </m-layout-stack>
    <m-separator v-if="entry.data.note" class="q-my-xs" />
    <div v-if="entry.data.note">
      <p class="pre-wrap">{{ entry.data.note }}</p>
    </div>
  </m-card>
</template>

<script setup lang="ts">
import { openSlideover } from "../../../../composables/dialog/drawer";
import { PatientJournalRecord } from "../types";
import { computed } from "vue";
import MSeparator from "../../MSeparator";

const props = defineProps<{
  entry: PatientJournalRecord;
  readOnly?: boolean;
  clickable?: boolean;
}>();

const getRecordedBy = computed(() => {
  const textAsArray = [props.entry.data.recordedBy, props.entry.data.recordedByOrganisation];
  const text = textAsArray.filter((x) => x).join(" • ");
  return text === "" ? false : text;
});

const formatRetrospectivelyAmendedLabel = computed(() => {
  let label = [];
  if (props.entry.data.isRetrospectivelyAmended) {
    label.push("Edited");
  }

  return label.join(" • ");
});

function getConfidentialityTextAndLinkedProblems() {
  const textArray = [];

  if (props.entry.data.hiddenFromPatientFacingServices) {
    textArray.push("Redacted from Patient Online Access");
  }
  if (props.entry.data.confidentialFromThirdParties) {
    textArray.push("Confidential from Third Parties");
  }
  if (props.entry.data.isExplicitlyIncludedInSCR) {
    textArray.push("Explicitly included in SCR");
  }

  const text = textArray.join(" • ");

  const problemsArray = props.entry.data.linkedProblems || [];
  const problemDescriptions = problemsArray
    .filter((x) => x.problemCodeDescription)
    .map((x) => x.problemCodeDescription);

  const problemText = problemDescriptions.length
    ? `Linked Problems: ${problemDescriptions.join(", ")}`
    : "";

  const finalTextArray = [text, problemText].filter(Boolean);

  return finalTextArray.length ? finalTextArray.join(" • ") : false;
}
</script>
