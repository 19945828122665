<template>
  <div
    :class="{
      'm-simple-table': true,
      'simple-table-border': !noBorder,
    }"
  >
    <table ref="el">
      <thead v-if="title">
        <tr>
          <th :colspan="cols" class="table-title">
            <p>{{ title }}</p>
          </th>
        </tr>
      </thead>
      <slot />
    </table>
  </div>
</template>

<script lang="ts">
import { ref, watch } from "vue";
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const el = ref<HTMLTableElement>();

    const cols = ref<number>(1);

    const observer = new MutationObserver(() => {
      const ths = el.value?.getElementsByTagName("th") || [];
      cols.value = ths.length || 0;
    });

    watch(el, (n) => {
      observer.observe(n as any, { childList: true });
      const ths = el.value?.getElementsByTagName("th") || [];
      cols.value = ths.length - 1 || 0;
    });

    return {
      el,
      cols,
    };
  },
};
</script>

<style lang="scss">
.m-simple-table {
  width: 100%;
  table-layout: fixed;

  background: #ffffff;
  border-radius: 4px;

  table {
    width: 100%;

    // make sure the table borders take the full container size
    margin-right: -1px;
    margin-left: -1px;
    border-spacing: 0 1em;
    border-collapse: collapse;
  }

  th,
  td {
    border-bottom: 1px solid var(--grey-light);
  }

  tr:last-of-type > td {
    border-bottom: none;
  }

  tr:last-of-type td {
    border-bottom: none;
  }

  td,
  th {
    padding: 5px 5px 5px 24px;
  }

  th {
    color: var(--text-color);
    font-weight: bold;
    font-size: 14px;
    line-height: 120%;
    margin-top: 0;
    vertical-align: baseline;
    text-align: left;
  }

  td {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: var(--text-color);
  }

  tr[clickable]:not([clickable="false"]),
  tr.clicable,
  tr.clickable {
    cursor: pointer;

    &:hover {
      background-color: #ebedef;
    }
  }

  .table-title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
    color: var(--text-color);

    // display: flex;
    align-items: center;
    text-transform: capitalize;
    padding: 10px 23px;

    p {
      margin: 0;
    }
  }
}
.simple-table-border {
  box-shadow: var(--box-shadow);
}
</style>
