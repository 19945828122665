<script setup lang="ts">
import { computed } from "vue";
import { RouteLocationRaw, useRouter } from "vue-router";
import { DrawerUrlOptions } from "../../../store/drawers";
import { usePermission } from "../../../composables/permission";

const props = defineProps<{
  to?: null | RouteLocationRaw;
  toModal?: string | DrawerUrlOptions | object | false;
  toSlideover?: string | DrawerUrlOptions | object | false;

  text?: string | null;
  name?: string | null;

  disabled?: boolean;

  target?: string | null;
  href?: string | null;

  normal?: boolean;

  onClick?: () => void;
  inline?: boolean;
}>();

const { hasLink, disabled, onClick } = usePermission(props, false, useRouter());

const target = computed(() => {
  if (props.target) return props.target;
  if (!props.href) return undefined;

  const url = new URL(props.href || "", location.href);
  if (url.host !== location.host) {
    return "_blank";
  }
  return undefined;
});
</script>
<template>
  <component
    v-bind="$attrs"
    :is="
      disabled || !(hasLink || href || onClick)
        ? 'span'
        : to
        ? 'router-link'
        : $props.onClick
        ? 'button'
        : 'a'
    "
    :class="['m-link medicus-outline', { disabled, normal, inline }]"
    :to="to"
    :href="href"
    :target="target"
    :type="!disabled && $props.onClick ? 'button' : undefined"
    tabindex="0"
    :aria-label="name || text"
    @click="onClick"
  >
    <slot>
      {{ name || text }}
    </slot>
  </component>
</template>

<style lang="scss" scoped>
.m-link {
  font-style: normal;
  // font-weight: bold;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 143% */

  text-decoration-line: underline;
  text-underline-offset: 3px;

  background-color: transparent;
  border: none;

  cursor: pointer;
  color: var(--theme-blue);

  padding: 0;

  &:not(.inline) {
    display: flex;
  }

  &:focus-visible {
    outline: none;
  }

  &:hover {
    color: var(--theme-accent-blue);

    text-decoration-thickness: 3px;
  }

  &.normal {
    text-decoration: none;
    text-underline-offset: 1px;

    color: var(--text-color);

    &:hover {
      color: black;

      text-decoration-line: underline;
      text-decoration-thickness: 1px;
    }
  }
}

.disabled {
  color: #90a0b7;
}
</style>
