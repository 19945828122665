<script setup lang="ts">
import { QItem, QTooltip } from "quasar";
import { defineOptions } from "vue";
import { DrawerUrlOptions } from "../../../store/drawers";
import { usePermission } from "../../../composables/permission";
import { useRouter } from "vue-router";

defineOptions({
  inheritAttrs: false,
});

const props = withDefaults(
  defineProps<{
    // TODO add type name
    to?: string | { url: string; name?: string; method?: string } | { name: string };
    permission?: string | true | "true" | { url: string; method?: string };

    toModal?: string | DrawerUrlOptions | object | false;
    toSlideover?: string | DrawerUrlOptions | object | false;

    disable?: boolean;
    disabled?: boolean;

    click?: (...args: any[]) => PromiseLike<any> | void;
    onClick?: (...args: any[]) => PromiseLike<any> | void;

    noClickable?: boolean;

    disabledTooltip?: string;
  }>(),
  {
    disabledTooltip: undefined,
  },
);

const { hasAccess, disabled, onClick } = usePermission(props, false, useRouter());
</script>
<template>
  <q-item
    class="m-item"
    v-bind="$attrs"
    :to="to"
    :disable="disabled"
    :clickable="!noClickable && !disabled"
    @click="onClick"
  >
    <q-tooltip v-if="$props.disable || $props.disabled">{{ disabledTooltip }}</q-tooltip>
    <q-tooltip v-else-if="!hasAccess">
      You don’t have permission to perform this action.
    </q-tooltip>
    <slot />
  </q-item>
</template>

<style></style>
