<script setup lang="ts">
import { computed, ref } from "vue";
import { DrawerUrlOptions } from "../../../store/drawers";
import { isObject } from "vue-composable";
import { openModal, openSlideover } from "../../../composables/dialog/drawer";
import { router } from "../../../router";
import MTooltip from "../MTooltip";

const props = defineProps<{
  text?: string;
  colour?: "amber" | "green" | "red" | "blue";

  onClick?: (e: MouseEvent) => void;
  toModal?: string | DrawerUrlOptions | object | false;
  toSlideover?: string | DrawerUrlOptions | object | false;
  to?: string;

  amber?: boolean;
  green?: boolean;
  red?: boolean;
  blue?: boolean;

  tooltip?: string;
}>();

const badgeEl = ref<HTMLButtonElement | HTMLSpanElement | null>(null);

const element = computed(() => {
  return props.onClick || props.toModal || props.toSlideover || props.to ? "button" : "span";
});

const click = computed(() => {
  if (props.onClick) {
    return props.onClick;
  }
  if (props.toModal) {
    const [url, options] = isObject(props.toModal)
      ? [props.toModal.url, props.toModal]
      : [props.toModal, {}];
    return (_e: MouseEvent) => openModal(url, options);
  }
  if (props.toSlideover) {
    const [url, options] = isObject(props.toSlideover)
      ? [props.toSlideover.url, props.toSlideover]
      : [props.toSlideover, {}];
    return (_e: MouseEvent) => openSlideover(url, options);
  }

  if (props.to) {
    return (_e: MouseEvent) => {
      router.push(props.to);
    };
  }
  return undefined;
});
</script>

<template>
  <component
    :is="element"
    v-bind="$attrs"
    ref="badgeEl"
    class="badge"
    :class="[
      {
        amber,
        green,
        red,
        blue,
      },
      colour && colour.toLowerCase(),
    ]"
    @click.capture="
      ($event) => {
        if (props.onClick || props.toModal || props.toSlideover || props.to) {
          $event.stopPropagation();
          click($event);
        }
      }
    "
  >
    <slot>
      {{ text }}
    </slot>
    <m-tooltip v-if="tooltip" :text="tooltip" :scroll-target="badgeEl" />
  </component>
</template>

<style lang="scss">
.badge {
  display: inline-block;
  border-radius: 4px;
  padding: 4px 8px;

  background-color: var(--status-grey);
  color: var(--grey-darkest);
  border: 1px solid var(--grey-darkest);

  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;

  word-wrap: break-word;
  word-break: break-word;
  width: fit-content;
  height: fit-content;
  max-width: 100%;

  flex: none;

  &.amber {
    background-color: var(--status-yellow);
    color: var(--status-yellow-text);
    border: 1px solid var(--status-yellow-text);
  }

  &.green {
    background-color: var(--status-green);
    color: #fff;
    border-color: var(--status-green);
  }

  &.red {
    background-color: var(--status-red);
    color: #fff;
    border-color: var(--status-red);
  }

  &.blue {
    background-color: var(--status-light-blue);
    color: var(--status-blue);
    border: 1px solid var(--status-blue);
  }
}

button.badge {
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  &:focus {
    outline: 2px solid var(--focus-ring);
    outline-offset: 2px;
  }
}
</style>
