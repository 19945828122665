<script setup lang="ts">
import { computed, getCurrentInstance, provide, ref, watch } from "vue";
import { useEvent } from "vue-composable";
import { traverseParentProvided } from "../../../utils/helpers";
import { findFirstScrollableChild, findFirstScrollableParent } from "../../../utils/scroll";
import MAction from "../MAction";
import MResponsiveContent from "../MResponsiveContent";
import MPatientBanner from "../PatientBanner";

//// PROPS
const props = withDefaults(
  defineProps<{
    title?: string;
    description?: string;
    buttons?: Array<any>;
    useQuasarButtons?: boolean;
    noPadding?: boolean;
    noOverflow?: boolean;
    bgWhite?: boolean;
    fit?: boolean;
    smallHeader?: boolean;

    noGutter?: boolean;
    centerContent?: boolean;

    patientId?: string;
    subtitle?: string;

    hideHeader?: boolean;
  }>(),
  {
    useQuasarButtons: true,
  },
);

//// Local variables
const contentEl = ref<HTMLDivElement | null>(null);
const proxy = getCurrentInstance().proxy!;
const contentStyling = ref({});
provide("setPageContentStyle", (s: any) => {
  contentStyling.value = s;
});
const isScrolling = ref(false);
const removePadding = ref(false);
const removeOverflow = ref(false);

//// Computed
const isSmallHeader = computed(() => {
  return props.noOverflow ? props.smallHeader : isScrolling.value;
});

//// Watchers & Listeners
provide("prevent-overflow", (v: boolean) => {
  removeOverflow.value = v;
});
provide("remove-padding", (v: boolean) => {
  removePadding.value = v;
});

watch(
  () => props.noPadding,
  (v) => {
    removePadding.value = !!v;
  },
  { immediate: true },
);

watch(
  () => props.noOverflow,
  (v) => {
    removeOverflow.value = !!v;
  },
  { immediate: true },
);

watch(
  removeOverflow,
  (v) => {
    const fn = v
      ? (offset: any) => {
          const height = offset ? `calc(100vh - ${offset}px)` : "100vh";
          return { minHeight: height, maxHeight: height, overflow: "hidden" };
        }
      : undefined;

    traverseParentProvided(proxy, (x) => x.styleFn)?.styleFn(fn);
  },
  {
    immediate: true,
  },
);

useEvent(document, "wheel", (e) => {
  if (props.noGutter || !contentEl.value) return;

  const scroll = findFirstScrollableChild(contentEl.value, 90);
  if (!scroll) return;

  // check if the scroll is not inside the page
  if (!(proxy.$el as HTMLElement).contains(e.target as HTMLElement)) return;

  const scrollParent = findFirstScrollableParent(e.target as HTMLElement, 90);

  // check if the target is scrollable, if it is, we shouldn't scroll the parent
  // check if the user is not scrolling the component
  if (!!scrollParent || scroll.contains(e.target as HTMLElement) || scroll === e.target) return;

  scroll.scrollBy({
    top: e.deltaY,
    left: e.deltaX,
    behavior: "smooth",
  });
});
</script>
<template>
  <m-responsive-content
    class="page flex flex-col flex-auto flex-col flex-nowrap"
    :class="{
      'no-overflow ': removeOverflow,
      'page-gutted': !noGutter,
      'bg-white': bgWhite,
    }"
  >
    <template #default>
      <slot v-if="!hideHeader" name="header" :small="isSmallHeader">
        <div class="page-header">
          <div
            v-if="title || $slots.title || buttons || $slots.buttons"
            key="page-header"
            class="header flex flex-row"
          >
            <div class="page-header-content flex-auto w-full">
              <div class="header-title" :class="{ 'gap-5': !$slots.title }">
                <slot name="title" :small="isSmallHeader">
                  <h1 class="title">
                    {{ title }}
                  </h1>
                </slot>
                <p v-if="description" class="description text-grey">{{ description }}</p>
              </div>
            </div>
            <transition name="fade" mode="out-in">
              <div
                v-if="!isSmallHeader && (buttons || $slots.buttons)"
                :class="{ 'header-actions-patient': !!patientId, buttons: true }"
              >
                <slot name="buttons">
                  <m-action
                    :model-value="buttons"
                    :ghost="false"
                    :use-quasar="useQuasarButtons !== false"
                  />
                </slot>
              </div>
            </transition>
          </div>
          <m-patient-banner
            v-if="patientId"
            :patient-id="patientId"
            blue-accent
            no-border
            class="below-patient-banner"
          />
        </div>
      </slot>
      <h2 v-if="subtitle" :class="{ gutter: props.centerContent }">{{ subtitle }}</h2>

      <div
        ref="contentEl"
        class="page-content q-page flex flex-auto full-width"
        :class="{
          'white-background': bgWhite,
          'scroll-area overflow-auto': !removeOverflow,
          'no-overflow': removeOverflow,
          'center-content': centerContent,
        }"
        :style="contentStyling"
      >
        <div
          class="page-padding flex-auto"
          :class="{
            'no-padding': removePadding,
            'full-width': noOverflow,
          }"
        >
          <slot />
        </div>
      </div>
    </template>
  </m-responsive-content>
</template>

<style lang="scss">
.page {
  background: var(--medicus-background);

  &.bg-white {
    background: white;
  }

  &.page-backdrop {
    &::after {
      content: " ";
      pointer-events: all;
      outline: 0;
      background: rgba(0, 0, 0, 0.5);

      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }

  &.page-gutted {
    background: var(--medicus-background);
    // border-right: 1px solid var(--border-colour);
    // border-left: 1px solid var(--border-colour);

    > * {
      max-width: 1100px;
      width: 100%;

      // margin-left: auto;
      // margin-right: auto;
      align-self: center;
    }

    > .page-header {
      max-width: unset;

      .header {
        max-width: 1100px;
        width: 100%;
        margin: auto;
      }
    }
  }

  > h2 {
    margin-top: var(--gap-3);
    padding-left: var(--gap-3);
    padding-right: var(--gap-3);

    &.gutter {
      max-width: var(--page-content-max-width);
      margin-left: auto;
      margin-right: auto;
      width: inherit;
      margin-top: var(--gap-3);
    }
  }

  .page-padding {
    padding: 16px;
    width: 100%;

    > .journal-timeline > .journal-filter {
      padding: 0 19px 19px 19px;
    }

    > .m-tabs > .q-tabs > .q-tabs__content .q-tab,
    > * > .m-tabs > .q-tabs > .q-tabs__content {
      min-height: 35px;
    }

    > :first-child.m-tabs {
      padding-top: var(--gap-3);
    }
  }

  .center-content {
    .page-padding {
      max-width: var(--page-content-max-width);
      margin-left: auto;
      margin-right: auto;
    }
  }

  .page-content {
    background: var(--medicus-background);
    flex-wrap: nowrap;
  }

  .white-background {
    background: white !important;
  }

  > .q-breadcrumbs {
    padding: 5px 12px 0 20px;

    &.showing-breadcrumbs {
      padding-top: 0px;
    }

    .q-link {
      color: var(--grey-darker);
    }
  }

  > .page-header {
    border-bottom: 1px solid var(--border-colour);
    background: white;

    > .header {
      min-height: var(--page-header-height);
      flex-wrap: nowrap;

      flex: 0 0 auto;

      padding: 0;
      justify-content: space-between;

      .header-title {
        display: flex;
        align-items: center;
        flex: 1 1 auto;
        margin-bottom: 1px;
        margin-left: 16px;

        > span {
          padding: 0;
          margin: 0;
        }

        .q-icon {
          align-self: center;
        }
      }

      .buttons {
        flex: 0 0 auto;
        padding: 6px;
      }

      .page-header-content {
        display: flex;
        flex-wrap: wrap;
        padding-right: 10px;

        &:has(.patient-banner) {
          align-self: flex-start;
        }
      }
    }

    .header-actions-patient {
      align-self: flex-start;
    }
  }

  .encounter-patient-info {
    padding: 0;
  }

  .description {
    font-family: Arial, sans-serif;
  }

  .page-content {
    &.no-padding {
      padding: 0;
    }
  }

  .no-overflow {
    overflow: hidden;
  }

  .patient-banner {
    margin-top: 9px;
    padding-left: 18px;
  }

  .below-patient-banner {
    padding-top: 5px;
    padding-left: 18px;
    padding-right: 10px;
  }
}
</style>
