<template>
  <div
    :class="[
      {
        clickable: !readOnly,
        'marked-incorrect': item.isMarkedIncorrect,
      },
    ]"
  >
    <m-layout-stack horizontal>
      <div class="flex-auto">
        <span>Observation: </span>
        <span>{{ item.type }} {{ item.value }}</span>
        <span
          v-if="item.observationDateIsNotEncounterDate || item.observationDateIsNotDocumentDate"
        >
          ({{ item.observationDate }})</span
        >
        <span>{{ item.approximateValue ? " • Approximate" : "" }}</span>
        <span v-if="item.interpretation"> • {{ item.interpretation }}</span>
        <span v-if="item.observedByOrganisation"> • {{ item.observedByOrganisation }}</span>
        <span v-if="item.isExplicitlyIncludedInSCR"> • Explicitly included in SCR</span>
        <span v-if="item.additionalInformation"> • {{ item.additionalInformation }}</span>
      </div>
      <i v-if="item.isRetrospectivelyAmended" style="white-space: nowrap">{{
        formatRetrospectivelyAmendedLabel
      }}</i>
    </m-layout-stack>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

export type ObservationJournalItem = {
  entryType: string;
  id: string;
  type: string;
  value: string;
  approximateValue: boolean;
  additionalInformation?: string;
  recordedBy: string;
  recordedByOrganisation: string;
  isMarkedIncorrect: boolean;
  hiddenFromPatientFacingServices: boolean;
  confidentialFromThirdParties: boolean;
  isExplicitlyIncludedInSCR: boolean;
  isRetrospectivelyAmended: boolean;
  linkedProblems: any[];
  createdDateTime: any;
};

const props = defineProps<{
  item: ObservationJournalItem;
  readOnly: boolean;
  contextHiddenFromPatientFacingServices?: boolean;
  contextConfidentialFromThirdPartyServices?: boolean;
}>();

const formatRetrospectivelyAmendedLabel = computed(() => {
  let label = [];
  if (props.item.isRetrospectivelyAmended) {
    label.push("Edited");
  }

  return label.join(" • ");
});
</script>
