export default {
  template: `
    <div class="ag-paging-panel">
      <span class="ag-paging-description">
        <span>{{ countDisplayName }}&nbsp;:</span>
        <span class="ag-paging-number">&nbsp;{{ count }}</span>
      </span>
    </div>
  `,
  data: function () {
    return {
      count: null,
      countDisplayName: "Count",
    };
  },
  beforeMount() {
    this.params.api.addEventListener("modelUpdated", () => {
      if (this.params.api.getRenderedNodes()[0] && this.params.api.getRenderedNodes()[0].data) {
        this.count = this.params.api.getDisplayedRowCount();
      } else {
        this.count = "?";
      }
    });

    if (this.params.countDisplayName) {
      this.countDisplayName = this.params.countDisplayName;
    }
  },
};
