<script lang="ts" setup>
import MDropdownButton from "../MDropdownButton";
import MButton from "../MButton";
import { Action, DropdownAction } from "./_types";
import { computed } from "vue";
import MDropdownAction from "../MDropdownAction";

const props = defineProps<{
  modelValue: Action | DropdownAction | Record<string, any>;
  ghost?: boolean;
  small?: boolean;
  iconOnly?: boolean;
  disabled?: boolean;
  useQuasar?: boolean;
}>();

const isGroup = computed(
  () => !!(props.modelValue as DropdownAction)?.actions?.find((x) => !!x.actions),
);

const actions = computed(() =>
  isGroup.value
    ? (props.modelValue as DropdownAction).actions?.reduce(
        (prev, cur) => {
          prev[cur.label] = cur.actions || [cur];
          return prev;
        },
        {} as Record<string, any>,
      )
    : props.modelValue.actions,
);
</script>
<template>
  <m-dropdown-button
    v-if="actions && useQuasar"
    :small="small"
    :ghost="ghost"
    v-bind="modelValue"
    :options="actions"
    :icon-only="iconOnly"
  />
  <MDropdownAction
    v-else-if="actions && !useQuasar"
    :small="small"
    :ghost="ghost"
    v-bind="modelValue"
    :options="actions"
    :icon-only="iconOnly"
  />
  <m-button
    v-else
    :ghost="ghost"
    :small="small"
    :icon-only="iconOnly"
    v-bind="modelValue"
    :disabled="disabled || modelValue?.disabled"
  />
</template>
