<script lang="ts" setup>
import { computed } from "vue";
import { usePrescriptionStore } from "../../../../store/transitient/prescription";
import MBanner from "../../../medicus/MBanner";
import MSection from "../../../medicus/MSection";
import MSearchSelect from "../../../medicus/inputs/MSearchSelect/MSearchSelect.vue";

const store = usePrescriptionStore();

function onDrugSelectorInputValue(val: string) {
  if (val) return;
  store.drugOrDevice = null;
}

function switchToGenericItem() {
  // @ts-expect-error MSelect will fix the correct type
  store.drugOrDevice = store.metaInformation?.alternativeItems?.[0];
}

const isReauthorising = computed(() => {
  return ["re-authorise", "prescribe-again"].indexOf(store.prescriptionFormType) !== -1;
});

const searchUrl = computed(() => {
  let baseUrl = `/clinical/data/gb/nhs/dmd/search/prescription/product?patientId=${store.patientId}`;

  if (store.form.originalPrescriptionId && true === isReauthorising.value) {
    baseUrl += `&originalPrescriptionId=${store.form.originalPrescriptionId}`;
  }

  return baseUrl;
});

const drugAndDeviceActions = computed(() =>
  store.loadingChecks
    ? []
    : [
        store.metaInformation?.alternativeItems?.length &&
          store.metaInformation?.alternativeItems[0]?.value?.itemCode !==
            store.prescription.productCode && {
            label: "Change to generic",
            click: switchToGenericItem,
            "data-testid": "change-generic-item-btn",
          },
      ].filter(Boolean),
);
</script>
<template>
  <m-section title="Medication">
    <m-search-select
      v-model="store.drugOrDevice"
      label="Product"
      placeholder="Search products"
      required
      clearable
      :search-url="searchUrl"
      actions-left
      :actions="drugAndDeviceActions"
      data-testid="drug-device-search"
      :min-search-value-length="3"
      @input-value="onDrugSelectorInputValue"
    />
    <m-banner
      v-if="store.state.productWarning && !store.drugOrDevice"
      type="warning"
      data-testid="product-warning-banner"
    >
      {{ store.state.productWarning }}
    </m-banner>
    <m-banner
      v-if="store.metaInformation?.brandNameProductPrescribingAdvised"
      type="warning"
      data-testid="brand-name-product-prescribing-banner"
    >
      <p>Brand name product prescribing is advised for this product.</p>
    </m-banner>
  </m-section>
</template>
