<template>
  <div
    :class="[
      {
        clickable: !readOnly,
        'marked-incorrect': item.isMarkedIncorrect,
      },
    ]"
  >
    <m-layout-stack horizontal>
      <div class="flex-auto">
        <span>Investigation request: {{ item.investigationRequestItems.join(" • ") }}</span>
        <span v-if="getConfidentialityText"> • {{ getConfidentialityText }} </span>
        <span v-if="item.requestedDateIsNotEncounterDate || item.requestedDateIsNotDocumentDate">
          • Requested {{ item.requestedDate }}</span
        >
      </div>
      <i v-if="item.isRetrospectivelyAmended" style="white-space: nowrap">{{
        formatRetrospectivelyAmendedLabel(item)
      }}</i>
      <m-badge v-if="item.isAwaitingResults" text="Awaiting Results" amber />
    </m-layout-stack>
  </div>
</template>

<script setup lang="ts">
import MBadge from "../../MBadge";
import { computed } from "vue";

export type InvestigationRequestJournalItem = {
  isRetrospectivelyAmended: boolean;
  investigationRequestItems: [];
  isMarkedIncorrect: boolean;
  confidentialFromThirdParties: boolean;
  hiddenFromPatientFacingServices: boolean;
  linkedProblems: { problemCodeDescription: string }[];
};

const props = defineProps<{
  item: InvestigationRequestJournalItem;
  readOnly: boolean;
  contextHiddenFromPatientFacingServices?: boolean;
  contextConfidentialFromThirdPartyServices?: boolean;
}>();

const getConfidentialityText = computed(() => {
  const textArray = [];

  if (props.item.hiddenFromPatientFacingServices && !props.contextHiddenFromPatientFacingServices) {
    textArray.push("Redacted from Patient Online Access");
  }
  if (props.item.confidentialFromThirdParties && !props.contextConfidentialFromThirdPartyServices) {
    textArray.push("Confidential from Third Parties");
  }
  return textArray.length ? textArray.join(" • ") : "";
});

function formatRetrospectivelyAmendedLabel(item: InvestigationRequestJournalItem) {
  let label = [];
  if (item.isRetrospectivelyAmended) {
    label.push("Edited");
  }

  return label.join(" • ");
}
</script>
