<template>
  <m-card
    :class="{ 'cursor-pointer': props.readOnly }"
    sr-label="View allergy"
    @click="allergyOverview()"
  >
    <div
      :class="[
        {
          clickable: !readOnly,
          'marked-incorrect': entry.data.isMarkedIncorrect,
        },
      ]"
    >
      <m-layout-stack horizontal>
        <div class="flex-auto">
          <span><b>Allergy: </b></span>
          <span v-if="entry.data.allergyCodeDescription && entry.data.substanceDescription">
            {{ entry.data.substanceDescription }} ({{ entry.data.allergyCodeDescription }})
          </span>
          <span v-else>
            {{ entry.data.substanceDescription ?? entry.data.allergyCodeDescription ?? "" }}
          </span>
          <span v-if="getSeverityAndCertainty()"> • {{ getSeverityAndCertainty() }} </span>
          <span v-if="entry.data.reactions"> • {{ entry.data.reactions }} </span>
          <span v-if="entry.data.isEnded"> • Ended </span>
        </div>
        <i v-if="entry.data.isRetrospectivelyAmended" style="white-space: nowrap">{{
          formatRetrospectivelyAmendedLabel(entry.data)
        }}</i>
      </m-layout-stack>
      <div
        v-if="
          entry.data.hiddenFromPatientFacingServices ||
          entry.data.confidentialFromThirdParties ||
          entry.data.linkedProblems.length
        "
      >
        <span class="secondary-text">
          {{ getConfidentialityTextAndLinkedProblems() }}
        </span>
      </div>
      <m-separator v-if="entry.data.additionalInformation" class="q-my-xs" />
      <span v-if="entry.data.additionalInformation">
        {{ entry.data.additionalInformation }}
      </span>
    </div>
  </m-card>
</template>

<script setup lang="ts">
import { defineProps } from "vue";
import { PatientJournalRecord } from "../types";
import { openSlideover } from "../../../../composables/dialog/drawer";
// import AllergyJournalElement from "../elements/AllergyJournalElement.vue";
import MSeparator from "../../MSeparator";

const props = defineProps<{
  entry: PatientJournalRecord;
  readOnly?: boolean;
  clickable?: boolean;
}>();

function allergyOverview() {
  return props.readOnly
    ? openSlideover("/clinical/allergy/overview-allergy/" + props.entry.id, {
        close: true,
      })
    : undefined;
}

function getSeverityAndCertainty() {
  const textAsArray = [props.entry.data.severity, props.entry.data.certainty];

  const text = textAsArray.filter((x) => x).join(" • ");

  return text === "" ? false : text;
}

function getConfidentialityTextAndLinkedProblems() {
  const textArray = [];

  if (props.entry.data.hiddenFromPatientFacingServices) {
    textArray.push("Redacted from Patient Online Access");
  }
  if (props.entry.data.confidentialFromThirdParties) {
    textArray.push("Confidential from Third Parties");
  }

  const text = textArray.join(" • ");

  const problemsArray = props.entry.data.linkedProblems || [];
  const problemDescriptions = problemsArray
    .filter((x) => x.problemCodeDescription)
    .map((x) => x.problemCodeDescription);

  const problemText = problemDescriptions.length
    ? `Linked Problems: ${problemDescriptions.join(", ")}`
    : "";

  const finalTextArray = [text, problemText].filter(Boolean);

  return finalTextArray.length ? finalTextArray.join(" • ") : false;
}

function formatRetrospectivelyAmendedLabel(item) {
  let label = [];
  if (item.isRetrospectivelyAmended) {
    label.push("Edited");
  }

  return label.join(" • ");
}
</script>
