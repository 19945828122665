<template>
  <div
    :class="[
      {
        clickable: !readOnly,
        'marked-incorrect': item.isMarkedIncorrect,
      },
    ]"
  >
    <m-layout-stack horizontal>
      <div class="flex-auto">
        <span v-if="item.administrationStatus === 'given'">
          Immunisation: {{ item.code ?? "" }}
        </span>
        <span v-if="item.administrationStatus === 'not-given'">
          Immunisation not given: {{ getCodeAndReasonNotGiven ?? "" }}
        </span>
        <span v-if="item.administeringOrganisation"> • {{ item.administeringOrganisation }} </span>
        <span
          v-if="
            item.administrationDateIsNotEncounterDate || item.administrationDateIsNotDocumentDate
          "
        >
          • Administered {{ item.administrationDate }}
        </span>
        <span v-if="getConfidentialityText"> • {{ getConfidentialityText }} </span>
        <span v-if="item.additionalInformation"> • {{ item.additionalInformation }} </span>
      </div>
      <i v-if="item.isRetrospectivelyAmended" style="white-space: nowrap">
        {{ formatRetrospectivelyAmendedLabel }}
      </i>
    </m-layout-stack>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

export type ImmunisationJournalItem = {
  isExplicitlyIncludedInSCR: boolean;
  linkedProblems: { problemCodeDescription: string }[];
  confidentialFromThirdParties: boolean;
  hiddenFromPatientFacingServices: boolean;
  administrationStatus: string;
  administeringOrganisation: string;
  isRetrospectivelyAmended: boolean;
  isMarkedIncorrect: boolean;
  code: string;
  reasonNotGiven: boolean;
  additionalInformation: string;
};

const props = defineProps<{
  item: ImmunisationJournalItem;
  readOnly: boolean;
  contextHiddenFromPatientFacingServices?: boolean;
  contextConfidentialFromThirdPartyServices?: boolean;
}>();

const getConfidentialityText = computed(() => {
  const textArray = [];

  if (props.item.hiddenFromPatientFacingServices && !props.contextHiddenFromPatientFacingServices) {
    textArray.push("Redacted from Patient Online Access");
  }
  if (props.item.confidentialFromThirdParties && !props.contextConfidentialFromThirdPartyServices) {
    textArray.push("Confidential from Third Parties");
  }
  if (props.item.isExplicitlyIncludedInSCR) {
    textArray.push("Explicitly included in SCR");
  }
  return textArray.length ? textArray.join(" • ") : "";
});

const formatRetrospectivelyAmendedLabel = computed(() => {
  let label = [];
  if (props.item.isRetrospectivelyAmended) {
    label.push("Edited");
  }

  return label.join(" • ");
});

const getCodeAndReasonNotGiven = computed(() => {
  const textAsArray = [props.item.code, props.item.reasonNotGiven];

  const text = textAsArray.filter((x) => x).join(" • ");

  return text === "" ? false : text;
});
</script>
