<script lang="ts" setup>
import { SnomedClinicalCode } from "../MInlineTextEntryInput/types";
import { ref, defineEmits } from "vue";
import { Key } from "ts-key-enum";

const props = defineProps<{
  id: string;
  code?: SnomedClinicalCode | string;
  text?: string;
  markedIncorrect?: boolean;
  hideBeforeAfterInputs?: boolean;
  onClick?: (_e: MouseEvent) => void;
  disabled?: boolean;
  disabledReason?: string;
}>();

const emit = defineEmits<{
  (e: "create-before", val: string): void;
  (e: "create-after", val: string): void;
}>();

defineExpose({
  focus,
});

const buttonRef = ref<HTMLButtonElement | null>(null);
const inputBeforeRef = ref<HTMLInputElement | null>(null);
const inputAfterRef = ref<HTMLInputElement | null>(null);

function focus() {
  buttonRef.value?.focus();
}

function handleBeforeInputKeydown(e: KeyboardEvent) {
  switch (e.key) {
    case Key.ArrowRight:
      if (props.onClick === undefined) {
        inputAfterRef.value?.focus();
      } else {
        buttonRef.value?.focus();
      }
      break;
    case Key.Enter:
      e.preventDefault();
      emit("create-before", "");
      break;
    default:
      if (isTextCharacter(e.key)) {
        e.preventDefault();
        e.stopPropagation();
        emit("create-before", e.key);
      }
      break;
  }
}

function handleAfterInputKeydown(e: KeyboardEvent) {
  switch (e.key) {
    case Key.ArrowLeft:
      if (props.onClick === undefined) {
        inputBeforeRef.value?.focus();
      } else {
        buttonRef.value?.focus();
      }
      break;
    case Key.Enter:
      e.preventDefault();
      emit("create-after", "");
      break;
    default:
      if (isTextCharacter(e.key)) {
        e.preventDefault();
        emit("create-after", e.key);
      }
      break;
  }
}

function handleButtonKeydown(e: KeyboardEvent) {
  switch (e.key) {
    case Key.ArrowLeft:
      inputBeforeRef.value?.focus();
      break;
    case Key.ArrowRight:
      inputAfterRef.value?.focus();
      break;
  }
}

function isTextCharacter(key: string) {
  // Check if the key is a single character (letters, numbers, punctuation, symbols)
  return key.length === 1 && key.match(/[\w\d\s\p{P}\p{S}]/u);
}
</script>

<template>
  <div class="list-item">
    <input
      v-if="!hideBeforeAfterInputs"
      ref="inputBeforeRef"
      type="text"
      class="create-before"
      @keydown.capture="handleBeforeInputKeydown"
    />
    <button
      ref="buttonRef"
      :class="{
        'marked-incorrect': props.markedIncorrect,
      }"
      :disabled="props.disabled"
      @click="
        () => {
          if (!props.disabled) props.onClick();
        }
      "
      @keydown="handleButtonKeydown"
    >
      {{ text }}
    </button>
    <input
      v-if="!hideBeforeAfterInputs"
      ref="inputAfterRef"
      type="text"
      class="create-after"
      @keydown.capture="handleAfterInputKeydown"
    />
    <m-tooltip
      v-if="props.disabledReason"
      :text="props.disabledReason"
      :scroll-target="buttonRef"
    />
  </div>
</template>

<style lang="scss" scoped>
.list-item {
  display: flex;
  margin-left: 4px;
  min-height: 28px;
  white-space: pre;

  button {
    display: flex;
    align-items: center;
    gap: 6px;
    background: var(--grey-lightest);
    padding: 2px 6px;
    border-radius: 4px;
    white-space: pre-line;

    &:not([disabled]):hover {
      cursor: pointer;
      background: var(--grey-light);
    }

    &:focus {
      background: var(--grey-light);
    }
  }

  input {
    border: 0;
    background: none;
    width: 5px;
    border-radius: 3px;

    &:hover {
      cursor: pointer;
      background: var(--grey-light);
    }

    &:focus {
      position: static;
      outline: 0;
      width: 5px;
    }
  }
}
</style>
